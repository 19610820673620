﻿import { SearchService } from "../search/SearchService";
import { SearchLocationTag } from "../search/SearchLocationTag";
import { SearchLocationTagContainer } from "../search/SearchLocationTagContainer";
import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
import { SearchMagicStrings } from "../search/SearchMagicStrings";
import ObjectHelper = require("../tools/ObjectHelper");
import ObjectHelper1 = ObjectHelper.ObjectHelper;
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

export class SearchBarComponentViewModel {

    SearchBar: HTMLInputElement;
    SearchAutocomplete: HTMLInputElement;
    HomePageSearchButton: HTMLAnchorElement;
    TagsAndInput: HTMLElement;
    ActiveSearchType: any;
    Timer: any;
    Form: HTMLFormElement;
    HomeSearchButton: HTMLElement;
    AutoCompleteHTML: string = ``;
    Validation: HTMLElement;

    Url: string;

    readonly autocompleteDelay: number = 800;
    readonly backspaceKeyCode: number = 8;
    readonly deleteKeyCode: number = 46;
    /*
     * strips all of the chars below at the end of the string
     * -/\^$*+?.()|[]{} -- characters that we strip out
     * \s-- any space characters that we strip out
     * *$ -- specifies one or more matches at the end of string
     */
    readonly regexRemoveEndingSymbolsAndSpaces: RegExp = new RegExp(/[-/\\^$*+?.()|[\]{}\s]*$/g);
    
    constructor() {
        this.SearchBar = <HTMLInputElement>document.getElementById("search-bar-input") || new HTMLInputElement();
        this.SearchBar.setAttribute('size', this.SearchBar.getAttribute('placeholder').length.toString());
        this.SearchAutocomplete = <HTMLInputElement>document.getElementById("search-bar-autocomplete");
        this.TagsAndInput = document.getElementById("search-bar-tags-and-input");

        if (this.TagsAndInput) {
            this.TagsAndInput.addEventListener("click", () => { this.SearchBar.focus(); });
        }

        this.SearchBar.addEventListener("focus", (event: FocusEvent) => this.handleSearchBarFocus(event));
        this.SearchBar.addEventListener("keyup", (event: KeyboardEvent) => this.handleSearchBarKeypress(event));
        this.HomePageSearchButton = <HTMLAnchorElement>document.getElementById("home-search-button");
        this.Url = document.URL;
        this.Form = <HTMLFormElement>document.getElementById("search-bar-form");
        this.Form.addEventListener("submit", (event: Event) => this.handleFormSubmit(event));
        this.Validation = document.getElementById("search-bar-validation");

        document.addEventListener("click", (event: MouseEvent) => this.searchLocationTagContainerEvents(event), true);
        document.addEventListener("scroll", (event: MouseEvent) => this.searchLocationTagContainerEvents(event), true);
        document.addEventListener("DOMContentLoaded", (event: Event) => this.searchLocationTagContainerEvents(event), true);

        if (this.HomePageSearchButton) {
            this.HomePageSearchButton.addEventListener("click",
                (e: Event) => {
                    this.handleFormSubmit(e);
                });
        }
        document.addEventListener(SearchPageBroadcast.autocompleteLinkClickedEventName,
            () => this.handleAutoCompleteClicked());
    }

    handleAutoCompleteClicked(): void {
        this.SearchBar.dataset["prevValue"] = "";
    }

    getElementPath(element: Element, path: Element[]): Element[] {
        path.push(element);
        if (element.parentElement) {
            if (element.parentElement !== document.body) {
                path = this.getElementPath(element.parentElement, path);
            }
        }
        return path;
    }

    handleSearchBarKeypress(event: KeyboardEvent) {
        if (this.SearchBar.value) {
            let searchValue = this.SearchBar.value.replace(this.regexRemoveEndingSymbolsAndSpaces, '');
            if (searchValue.length >= 2) {
                this.setTimer(this.sendSearchRequest);
            } else {
                this.setTimer(this.sendSavedSearchRequest);
            }
        } else if ((event.keyCode === this.deleteKeyCode || event.keyCode === this.backspaceKeyCode) &&
            (this.SearchBar.dataset["prevValue"] === "" || this.SearchBar.dataset["prevValue"] === undefined)) {
            SearchLocationTagContainer.RemoveLast();
        }
        this.SearchBar.dataset["prevValue"] = this.SearchBar.value;
    }

    handleSearchBarFocus(event: FocusEvent) {
        if (this.SearchBar.value.replace(this.regexRemoveEndingSymbolsAndSpaces, '') === `` &&
            this.SearchAutocomplete.innerHTML === `` &&
            this.AutoCompleteHTML === ``) {
            this.sendSavedSearchRequest(this);
        } else if (this.AutoCompleteHTML !== ``) {
            this.SearchAutocomplete.innerHTML = this.AutoCompleteHTML;
            this.SearchAutocomplete.style.display = "block";
            this.setEventHandlerForAutocompleteLinks();
            this.setEventHandlerForDesmondLinks();
        }
       
    }

    handleFormSubmit(event: Event) {
        event.preventDefault();
        //check to see if the search bar has a city, county, or zipcode
        if (this.SearchBar.value && this.SearchBar.value !== "" && this.SearchBar.value.length > 1) {
            if (document.getElementById("autocomplete-matching-results") || (this.SearchBar.classList.contains("Desmond") && this.SearchBar.name !== ""))
                this.runSearch(this.SearchBar.name, this.SearchBar.value, event);
            else
                this.runSearch("kw", this.SearchBar.value, event);
        }        
        else if (this.SearchBar.classList.contains("Desmond")) 
            //do a wide open blank keyword search
            this.runSearch("kw", this.SearchBar.value, event);
        else
            this.Validation.style.display = "block";
    }

    searchLocationTagContainerEvents(event: Event) {
        let path: Element[] = this.getElementPath(event.srcElement, []);
        // disables searchtag closability when you click outside the searchbar
        if (path.indexOf(document.getElementById("search-search-bar")) >= 0) {
            SearchLocationTagContainer.EnableTagClick();
        } else {
            if (!document.getElementById("location-tag-more")) {
                SearchLocationTagContainer.DisableTagClick();
            }
        }
        // enables auto-complete when the search bar is clicked
        if (path.indexOf(this.Form) === -1) {
            this.SearchAutocomplete.innerHTML = "";
            this.SearchAutocomplete.style.display = "none";
        }
    }

    sendSavedSearchRequest(searchBarComponent : any) {
        let url = `/Ajax/Search/SavedSearch/`;
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.open("GET", url, true);
        xhttp.addEventListener("load", () => {
            searchBarComponent.handleSearchRequestLoad(xhttp);
        });
        xhttp.send();
    }

    sendSearchRequest(searchBarComponent: any) {
        let regexRemoveEndingSymbolsAndSpaces = new RegExp(/[-/\\^$*+?.()|[\]{}\s]*$/g);
        let searchBarInputWithStrippedEnding = (<HTMLInputElement>document.getElementById("search-bar-input")).value.replace(regexRemoveEndingSymbolsAndSpaces, '');
        let url = `/ajax/search/autocompletedropdown/acl_kw/${searchBarInputWithStrippedEnding}/?IncludeSearches=1`;
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.open("GET", url, true);
        xhttp.addEventListener("load", () => {
            searchBarComponent.handleSearchRequestLoad(xhttp); 
        });
        xhttp.send();
    }

    handleSearchRequestLoad(request: XMLHttpRequest) {
        if(request.status === 200) {
            if(request.response !== ``){
                this.SearchAutocomplete.innerHTML = request.response;
                this.AutoCompleteHTML = request.response;
                this.SearchAutocomplete.style.display = "block";
                this.setEventHandlerForAutocompleteLinks();
                this.setEventHandlerForDesmondLinks();
            }
        }
    }

    setTimer(delayFunction : Function) {
        clearTimeout(this.Timer);
        this.Timer = setTimeout(() => { delayFunction(this); }, this.autocompleteDelay);
    }

    setEventHandlerForAutocompleteLinks() {
        let links : HTMLCollectionOf<HTMLElement> = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("autocomplete-link");
        let linksLength : number = links.length;
        for(let ind = 0; ind < linksLength; ind++) {
            //links.item(ind).removeAttribute("href");
            links.item(ind).onclick = (event : MouseEvent) => {
                event.preventDefault();
                SearchPageBroadcast.ClearPolygonMap();
                GoogleAnalyticsEventLogger.log('Search Homes', 'search_option_selected');
                this.runSearch(links.item(ind).dataset["inputname"], links.item(ind).dataset["value"], event);
            };
        }
    }

    setEventHandlerForDesmondLinks() {
        let dlinks: HTMLCollectionOf<HTMLElement> = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("desmond-link");
        let linksLength: number = dlinks.length;
        if (dlinks.length > 0) {
            for (let ind = 0; ind < linksLength; ind++) {
                dlinks.item(ind).onclick = (event: MouseEvent) => {
                    // we need to add it to the text box manually
                    this.SearchBar.value = (dlinks.item(ind).dataset["value"]);
                    this.SearchBar.name = dlinks.item(ind).dataset["inputname"];
                    this.SearchBar.setAttribute("theme", "desmond");
                    this.SearchAutocomplete.style.display = "none";
                };
            }
        }
    }

    runSearch(inputname: string, value: string, event: Event) {
        this.SearchBar.name = inputname;
        this.SearchBar.value = value;
        this.SearchAutocomplete.style.display = "none";

        let links: HTMLCollectionOf<HTMLElement> = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("autocomplete-link");

        if (this.Url.indexOf("/search") >= 0) {
            let searchLocationTag: SearchLocationTag = null;
            if (inputname === "" && typeof links !== "undefined" && links !== null && links.length > 0) {
                var topLinkName = links[0].getAttribute("data-inputname");
                var topLinkValue = links[0].getAttribute("data-value");
                searchLocationTag = new SearchLocationTag(topLinkName, topLinkValue);
            } else {
                searchLocationTag = new SearchLocationTag(this.SearchBar.name, this.SearchBar.value);
            }

            SearchPageBroadcast.AutoCompleteLinkClicked();
            SearchLocationTagContainer.Add(searchLocationTag);
            SearchLocationTagContainer.RemoveCustomMapAreaTag();
            SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            this.SearchBar.name = "";
            this.SearchBar.value = "";
        } else { // home page handling
            var link: string = null;
           
            if (this.SearchBar.classList.contains("Desmond")) {
                var serializedSearchForm = SearchService.StrCrit("search-bar-form");
                var cleanSearchFilters = ObjectHelper1.RemoveEmptyProperties(serializedSearchForm);
                serializedSearchForm = $.param(cleanSearchFilters);

                if (serializedSearchForm.length > 0) {
                    serializedSearchForm = "/search?" + serializedSearchForm;
                    window.location.href = serializedSearchForm;
                    return;
                }
            }

            //// if the source event is an anchor, let the anchor do what it does best
            //// regular search
            if (event && event.srcElement.tagName.toLowerCase() === "a" && event.srcElement.getAttribute("href") !== "" && event.srcElement.getAttribute("href") !== "#") {
                window.location.href = event.srcElement.getAttribute("href");
                return;
            }

            // try to generate link from top auto-complete result and automatically select the first link
            // regular search
            if (typeof links !== "undefined" && links !== null && links.length > 0) {
                link = links[0].getAttribute("href");
                if (link) {
                    window.location.href = link;
                    return;
                }
            }

            if (this.SearchBar.value) {
                window.location.href = "/search?" + SearchMagicStrings.KeywordSearchKey + "=" + this.SearchBar.value;
                return;
            }

            // all else fails, lets go to the search
            link = "/search";
            window.location.href = link;
        }
    }
}

if(document.getElementById("search-bar-form")) {
    let seachBarComponentVM = new SearchBarComponentViewModel();
}
