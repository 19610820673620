﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
import { BrowserDetector } from '../tools/BrowserDetector';

export class SearchListingsContainer {
    searchListings: HTMLElement = document.getElementById("searchListingsContainer");
    desktopQuickDetails: HTMLElement = document.getElementById("search-map-selected-prop");

    constructor() {
        if (BrowserDetector.isMobileBrowser() && BrowserDetector.isMobileWidth()) {
            this.desktopQuickDetails.style.display = "none";
        }

        document.addEventListener(SearchPageBroadcast.closeFiltersEventName, () => {
            if (this.searchListings) {
                this.searchListings.style.display = "block";
            } 
        });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => {
            if (this.searchListings) {
                this.searchListings.style.display = "block";
            }
        });
        document.addEventListener(SearchPageBroadcast.displayFiltersEventName, () => {
            if (this.searchListings) {
               this.searchListings.style.display = "none";
            }
            SearchPageBroadcast.HideMobileMap();
        });
        document.addEventListener(SearchPageBroadcast.displayMobileMapEventName, () => {
            if (this.searchListings) {
                this.searchListings.style.display = "none";
            }
        });
        document.addEventListener(SearchPageBroadcast.displayMobileListingsEventName, () => {
            if (this.searchListings) {
                this.searchListings.style.display = "block";
            }
        });

        window.addEventListener("resize", () => {
            if (!BrowserDetector.isMobileBrowser()) {
                if (!BrowserDetector.isMobileWidth()) {
                    this.desktopQuickDetails.style.display = "block";
                    if (this.searchListings && this.searchListings.style.display === "none") {
                        this.searchListings.style.display = "block";
                    }
                }
                else {
                    this.desktopQuickDetails.style.display = "none";
                }
            }
        });

        // Fix quick details on top on scroll
        let quickDetails = document.getElementById("search-map-selected-prop");
        if (quickDetails && quickDetails.style.display !== "none") {
            window.addEventListener("scroll", () => {
                //let html = document.getElementsByTagName("html").item(0);
                let y = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
                if (y > 0) {
                    quickDetails.classList.add('quickdetails-fixed');
                }
                else {
                    quickDetails.classList.remove('quickdetails-fixed');
                }
            });
        }
    }
}

if (document.getElementById("searchListingsContainer")) {
    let searchListingsContainer = new SearchListingsContainer();
}