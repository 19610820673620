import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class RegistrationSplashEvent implements IGoogleEvent {
    name: string = "RegistrationSplashEvent";
    category: string = "registration-splash";
    label: string = "Registration";
    type: EventType = EventType.Submit;
}

@DomainEventRegistry.DomainEventRegistry.register
class RegistrationSplashEventHandler extends DomainEventHandler {

    domainEventName: string = "RegistrationSplashEvent";

    handleDomainEvent<RegistrationSplashEvent>(ev: CustomEvent, args: RegistrationSplashEvent): void {

    }
}