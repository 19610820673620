﻿import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
import { SearchService } from "../search/SearchService";

export class SearchPaginationComponent {
    constructor() {
        this.initPageLinks();
        document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, (ev: CustomEvent) => { this.handleSearchResponse(ev.detail["response"]); });
    }

    initPageLinks() {
        let pageLinks = document.getElementsByClassName("pagination-item");
        for (let pageInd = 0; pageInd < pageLinks.length; pageInd++) {
            let pageLink: HTMLAnchorElement = <HTMLAnchorElement>pageLinks.item(pageInd);
            pageLink.removeAttribute("href");
            pageLink.addEventListener("click", (ev: Event) => {
                this.handlePageSelection(ev);
            });
        }
    }

    handleSearchResponse(response: any) {
        if (response) {
            let searchPaginationContainer = document.getElementById("searchPagination");
            searchPaginationContainer.outerHTML = response.components.searchPaginationComponent;

            let pageLinks = document.getElementsByClassName("pagination-item");
            for (let pageInd = 0; pageInd < pageLinks.length; pageInd++) {
                let pageLink: HTMLAnchorElement = <HTMLAnchorElement>pageLinks.item(pageInd);
                pageLink.removeAttribute('href');
                let anchorClone = <HTMLAnchorElement>pageLink.cloneNode();
                anchorClone.addEventListener("click", (ev: Event) => this.handlePageSelection(ev));
                anchorClone.innerHTML = pageLink.innerHTML;
                if (pageLink.parentNode)
                    pageLink.parentNode.replaceChild(anchorClone, pageLink);
            }
        }
    }

    handlePageSelection(e: Event) {
        let origin = <HTMLElement>e.target;
        if (origin.tagName.toLowerCase() === "i") {
            origin = origin.parentElement;
        }
        let page = origin.dataset["page"];
        if (page) {
            let pageInput = <HTMLInputElement>document.getElementById("page");
            pageInput.value = page;
            SearchService.SearchRequest(SearchService.StrCrit(), false);
            let pageHtml = document.getElementsByTagName("html").item(0);
            pageHtml.scrollTop = 0;
        }
    }
}

if (document.getElementById("searchPagination")) {
    var searchPaginationComponent = new SearchPaginationComponent();
}