$(window).on('load resize', function() {
    headerDetailsMobileHeight();
    headerSearchHeight();
});

// Calculate height of header to make it so the search page won't scroll
function headerSearchHeight() {
    var windowWidth = $(window).innerWidth();
    var headerHeight = $('.header').outerHeight();
    var searchHeight = headerHeight + "px";
    var siteContainer = $('.site-container');
    var isSearchPage = $('body').hasClass('search-page');

    if ( isSearchPage && windowWidth > 768 ) {
        siteContainer.css('padding-top', searchHeight);
    } 
    else if ( isSearchPage && windowWidth <= 768 ) {
        siteContainer.css('padding-top', 0);
    }
}

// Calculate height of prop details mobile nav and arrange price and interactions accordingly

function headerDetailsMobileHeight() {
    var header = $('.details-nav-header');
    var windowWidth = $(window).innerWidth();
    var headerHeight = header.outerHeight();
    var lastHeight = header.css('height');
    var interactionHeight = (headerHeight + 230) + "px";
    var priceHeight = (headerHeight + 180) + "px";
    if ( $('body').hasClass('details-page') && windowWidth <= 768) {
        $('.details-int').css('top', interactionHeight);
        $('.details-price').css('top', priceHeight);
    } 

}