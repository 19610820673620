﻿export class AdminToolbarViewModel {
    buildLockButton : HTMLElement;
    buildUnlockButton : HTMLElement;

    constructor() {
        this.buildLockButton = document.getElementById("build-lock-btn");
        this.buildUnlockButton = document.getElementById("build-unlock-btn");

        if (this.buildLockButton)
            this.buildLockButton.addEventListener("click", (e) => this.handleBuildButtonSubmit(e));
        
        if (this.buildUnlockButton)
            this.buildUnlockButton.addEventListener("click", (e) => this.handleBuildButtonSubmit(e));
    }

    handleBuildButtonSubmit(event: Event) {
        event.preventDefault();
        let eventElement = <HTMLElement>event.currentTarget;
        let jobName = eventElement.getAttribute('data-build-job-name');
        let jobAction = eventElement.getAttribute('data-build-job-action');

        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url: string = '/api/builds/' + jobAction + '?jobName=' + jobName;
        xhttp.addEventListener("load", () => this.handleApiResponse(xhttp));
        xhttp.open("GET", url, true);
        xhttp.send();
    }

    handleApiResponse(request: XMLHttpRequest) {
        if (request.status === 200) {
            this.toggleVisibility(this.buildUnlockButton);
            this.toggleVisibility(this.buildLockButton);
        } else {
            console.log(request.response);
        }
    }

    toggleVisibility(element: HTMLElement) {
        element.style.display = element.style.display === 'none' ? '' : 'none';
    }
}

if(document.getElementById("admin-toolbar")) {
    let adminToolbarVM : AdminToolbarViewModel = new AdminToolbarViewModel();
}