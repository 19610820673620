﻿export class CookieManager {

    public static getCookie(cookieName: string): any {
        let cookieLength = cookieName.length;
        let returnValue = document.cookie.split(';').map(c => c.trim()).filter(cookie => {
            return cookie.substring(0, cookieLength) === `${cookieName}`
        });
        return returnValue[0];
    }

    public static deleteCookie(name: string) {
        this.createCookie(name, "", -1);
    }

    public static createCookie(name: string, value: string, daysTillExpiration?: number) {
        let expires: string = "";
        if (daysTillExpiration) {
            let today : Date = new Date();
            let expirationDate : Date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysTillExpiration);
            expires = `expires=${expirationDate.toUTCString()}; `;
        }
        let domain: string = "." + location.hostname.split('.').reverse()[1] + "." + location.hostname.split('.').reverse()[0];
        document.cookie = `${name}=${value}; ${expires}path=/; domain=${domain}`;
    }

    public static updateCookie(name: string, newValue: string, daysTillExpiration?: number) {
        this.deleteCookie(name);
        this.createCookie(name, newValue, daysTillExpiration);
    }

    public static getValueFromCookie(cookieName : string) : string {
        let viewedProperties = CookieManager.getCookie(cookieName);
        if(viewedProperties) {
            viewedProperties = viewedProperties.substr(cookieName.length + 1);
        }
        return viewedProperties;
    }

    public static updateViewedPropertiesCookie(id : string) {
        let cookieName : string = "viewedproperties";
        let propertiesValue : any = CookieManager.getValueFromCookie(cookieName);

        if(propertiesValue) {
            //check cookie size
            if(propertiesValue && propertiesValue.length > 3900)
            {
                let propArray : Array<string> = <Array<string>>propertiesValue.split(',');
                propArray.shift();
                propertiesValue = "";
                propArray.forEach((prop) => {
                    propertiesValue += `${prop},`
                });
                propertiesValue = propertiesValue.substr(0, propertiesValue.length - 1);
            }

            if(!propertiesValue.toLowerCase().includes(id.toLowerCase())){
                propertiesValue += `,${id.toLowerCase()}`;
            }
        } else {
            propertiesValue = id.toLowerCase();
        }
        CookieManager.updateCookie(cookieName, propertiesValue, 100);
    }
}