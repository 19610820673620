﻿import * as $ from "jquery";
import { ObjectHelper } from "../tools/ObjectHelper";
import { SearchPageBroadcast } from "./SearchPageBroadcast";

export class SearchService {
    public static data : any;
    static openSearchRequest: XMLHttpRequest;
    public static preventSearchEvents: boolean = false;
    constructor() { }

    public static SerializeSearchForm(form : HTMLFormElement) : any {
        if(!form || form.length === 0) {
            return {};
        }
        let formString: string = "";
        let selectedListingTypes: string = "";
        let keywords: string = "";
        for (let i : number = 0; i < form.elements.length; i++)
        {
            let inputType: string = form.elements.item(i).nodeName;
            if(inputType === "INPUT")
            {
                let element: HTMLInputElement = <HTMLInputElement>form.elements.item(i);
                let name = element.name;
                if (element.type === "checkbox" && element.name === "listingtype" && element.checked) {
                    selectedListingTypes += element.value + ",";
                }
                if (element.name === "kw") {
                    keywords += element.value + ",";
                }
                if (element.type === "checkbox" && element.name === "virtualtourlink" && element.checked) {
                    formString += '"virtualtourlink" : "1", ';
                }
                else if(form[name]) {
                    let value = form[element.name].value;
                    if (value !== "") {
                        formString += `"${name}" : "${value}", `;
                    }
                }
            }
            else if(inputType === "SELECT") {
                let select : HTMLSelectElement = <HTMLSelectElement>form.elements.item(i);
                let name = select.name;
                if(form[name] && select.selectedOptions.length > 0) {
                    let valueString = "";
                    for(let optInd = 0; optInd < select.selectedOptions.length; optInd++) {
                        let selected : HTMLOptionElement = select.selectedOptions.item(optInd);
                        valueString += `${selected.value},`;
                    }
                    valueString = valueString.substr(0, valueString.length - 1);
                    formString += `"${name}" : "${valueString}", `;
                }
            }
        }

        if (selectedListingTypes !== "") {
            formString += `"listingtype" : "${selectedListingTypes.slice(0, -1)}", `;
        }
        if (keywords !== "") {
            formString += `"kw" : "${keywords.slice(0, -1)}", `;
        }

        formString = `{${formString.trim().substr(0, formString.length - 2)}}`;
        let formObj = JSON.parse(formString);

        if (formObj["include-tags"] && formObj["include-tags"] !== "") {
            var includeTags = formObj["include-tags"].split(",");
            includeTags.forEach(function (tag : any) { formObj[tag] = "1"; });
            delete formObj["include-tags"];
        }
        
        if (formObj["exclude-tags"] && formObj["exclude-tags"] !== "") {
            var excludeTags = formObj["exclude-tags"].split(",");
            excludeTags.forEach(function (tag : any) { formObj[tag] = "0"; });
            delete formObj["exclude-tags"];
        }
        return formObj;
    }

    public static StrCrit(searchFormID?: string): any {
        if (!searchFormID || searchFormID.length === 0) {
            //this is the default form name we were using for all StrCrit calls before 6/22/2020
            return this.SerializeSearchForm(<HTMLFormElement>document.getElementsByClassName("js-filters-form").item(0));
        }
        return this.SerializeSearchForm(<HTMLFormElement>document.getElementById(searchFormID));
    }

    public static EditDid(): string {
        var editDid = (document.getElementById("editdid") as HTMLInputElement);
        if (editDid) {
            return editDid.value;
        }
        return "";
    }

    public static SearchRequest(data: any, resetPage: boolean, resetMap? : boolean) {
        // if we have a search already in progress, abort it to avoid race conditions / multiple UI reloads
        if (SearchService.openSearchRequest) {
            SearchService.openSearchRequest.abort();
        }

        SearchPageBroadcast.SearchRequestInitiated();
         var viewListingRegion = document.getElementById("viewListingsRegion");
         var listingsListingRegion = document.getElementById("listingsLoadingRegion");
         if (typeof viewListingRegion !== "undefined" && viewListingRegion && viewListingRegion.hidden) {
            document.getElementById("viewListingsLoader").style["maxWidth"] =
                document.getElementById("viewListings").offsetWidth.toString() + "px";
            document.getElementById("viewListingsLoader").style["maxHeight"] =
                document.getElementById("viewListings").offsetHeight.toString() + "px";
        }

        if (resetPage) {
            data["page"] = 1;
        }
        if (typeof viewListingRegion !== "undefined" && viewListingRegion) {
            viewListingRegion.hidden = true;
        }
        if (typeof listingsListingRegion !== "undefined" && listingsListingRegion){
            listingsListingRegion.hidden = false;
        }
       
        
        // check if the searchFilters button exist (not every search page has one)
        var searchFiltersOpenButton = document.getElementById("searchFilters");
        if (searchFiltersOpenButton){
            searchFiltersOpenButton.classList.add("disabled");
        }
        let clearMapEvent: CustomEvent = new CustomEvent("remove-map-markers", {});
        document.dispatchEvent(clearMapEvent);

        data = this.clearEmptyProperties(data);
        let xhttp = new XMLHttpRequest();
        let formData = SearchService.CreateFormData(data);
        SearchService.openSearchRequest = xhttp;
        xhttp.open("POST", "/ajax/search/update", true);
        xhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhttp.addEventListener("load", (ev) => {
            SearchService.openSearchRequest = null;
            SearchPageBroadcast.SearchRequestLoaded();

            if(xhttp.status === 200) {
                let response = JSON.parse(xhttp.response);
                SearchPageBroadcast.SearchResultsUpdate(response, resetMap);
            }
        });
        this.setQueryString(data);
        xhttp.send(formData);
    }

    public static CreateFormData(data : any) : FormData {
        let formData = new FormData();
        if(data) {
            for(let key in data) {
                formData.append(key, data[key]);
            }
        }
        return formData;
    }

    private static clearEmptyProperties(data : any) : any {
        let dataReturn : any = {};
        for(let key in data) {
            if(data[key] !== "") {
                dataReturn[key] = data[key];
            }
        }
        return dataReturn;
    }

    private static setQueryString(searchFilters : any) {
        var cleanSearchFilters = ObjectHelper.RemoveEmptyProperties(searchFilters);
        delete cleanSearchFilters["resetfilters"];
        window.history.pushState(null, null, window.location.pathname + "?" + $.param(cleanSearchFilters));
    }
}