﻿export class UpdateGeneralSettingsRequest {
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public CellPhone: string;
    public Password: string;
    public UnsubscribeEmail?: boolean;
    public UnsubscribeText?: boolean;
    public On_PropertyUpdates?: boolean;
    public On_PropertyAlerts?: boolean;
    public PhotoLocation: string;
}
