﻿import * as $ from "jquery";

declare var CINC: any;
declare var Modals: any;

export class SearchBarLenderComponentViewModel {
    SearchBar: HTMLInputElement;
    SearchAutocomplete: HTMLInputElement;
    Form: HTMLFormElement;
    SearchSubmit: HTMLElement;
    SearchWrap: HTMLElement;
    SearchKeywordIdentifier: HTMLElement;

    constructor() {
        this.SearchBar = <HTMLInputElement>document.getElementById("search-bar-input");
        this.SearchAutocomplete = <HTMLInputElement>document.getElementById("search-bar-autocomplete");
        this.SearchBar.addEventListener("keyup", (event: KeyboardEvent) => this.handleSearchBarKeypress(event));
        this.Form = <HTMLFormElement>document.getElementById("search-bar-lender-form");
        this.SearchSubmit = <HTMLElement>document.getElementById('js-lender-search-submit');
        this.SearchWrap = <HTMLElement>document.getElementById('search-bar-autocomplete');
        this.SearchKeywordIdentifier = <HTMLElement>document.getElementById('search-bar-input');
        this.Form.addEventListener("submit", (event: Event) => this.searchSubmitClick())
        document.addEventListener("click", (event: MouseEvent) => {
            this.searchSubmitClick();
        });

        this.SearchBar.value = '';

        if (Modals.AnyOpen) {
            Modals.CloseAll();
        }
    }

    searchCallback(kw: string, val: string) {
        let comp = this;
        var isRetry = false;
        kw = kw ? kw : 'keyword';
        val = val ? val : <string>$(this).val();
        var ajx = new CINC.AjaxApi('/api/lenderplatform/globalsearch');
        var data:any = {
            ddid: CINC.Site.DDID,
            searchType: "buying"
        };

        data[kw] = val;

        var doSearch = function (searchData: any) {
            return ajx.post(searchData).success(function (resp: any) {
                var loadingModal = Modals.Loading("Loading Search Results").Open();
                window.location.assign(resp);
            });
        };

        var doSearchFailure = function () {
            alert('No results found! ');
            //this.SearchSubmit.classList.remove('submit-loading');
        };

        doSearch(data);

        var searchFailed = function (this: any) {
            if (!isRetry) {
                isRetry = true;
                let acl : any = {};

                let keywords: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>comp.SearchWrap.getElementsByClassName('js-keyword');
                let keywordsLength: number = keywords.length;
                for (let ind = 0; ind < keywordsLength; ind++) {
                    acl[keywords.item(ind).dataset.inputname] = keywords.item(ind).dataset.value;
                }

                var aclProps = Object.getOwnPropertyNames(acl);

                // if there's only one item in the ACL list, search on that
                if (aclProps.length == 1) {
                    delete data.kw;
                    var aclProp = aclProps[0];
                    data[aclProp.replace('acl_', '')] = acl[aclProp];
                    doSearch(data);
                    return;
                }
            }

            this.doSearchFailure();
        };
    }

    clearAutocomplete() {
        this.SearchWrap.classList.remove('open');
        this.SearchWrap.classList.remove('autocomplete');
        let dropdown: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>this.SearchWrap.getElementsByClassName('dropdown-menu');
        let dropdownLength: number = dropdown.length;
        for (let ind = 0; ind < dropdownLength; ind++) {
            dropdown.item(ind).remove();
        }

        let grids: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>this.SearchWrap.getElementsByClassName('grid__flex--gutter');
        let gridsLength: number = grids.length;
        for (let ind = 0; ind < gridsLength; ind++) {
            grids.item(ind).remove();
        }
    }

    openAutocomplete(html: string) {
        this.clearAutocomplete();
        this.SearchWrap.classList.add('open');
        this.SearchWrap.classList.add('autocomplete');
        this.SearchWrap.insertAdjacentHTML('beforeend', html);
        // event binding
        let keywords = this.SearchWrap.getElementsByClassName('js-keyword');
        for (let ind = 0; ind < keywords.length; ind++) {
            keywords.item(ind).addEventListener("click", (event: MouseEvent) => {
                var searchkw = $(event.currentTarget).data('inputname');
                var searchval = $(event.currentTarget).data('value');
                searchkw = searchkw.replace('acl_', '');
                this.searchCallback(searchkw, searchval);
            });
        }
    }

    searchSubmitClick() {
        var grandParent = $(this).parent().parent();
        var searchKeywordElement = grandParent.find(this.SearchKeywordIdentifier);
        if (searchKeywordElement.length !== 0) { // if the object is not empty
            this.searchCallback.call(searchKeywordElement);
        }
    }
    
    handleSearchBarKeypress(event: KeyboardEvent) {
        let comp = this;
        if (event.keyCode === 13) {
            this.searchCallback.call(this);
        } else {
            var kw = this.SearchBar.value.trim();
            if (kw.length > 2) {
                CINC.Api.Search.AutocompleteHtml('acl_lighter_kw', kw, true).get({
                    IncludeSearches: ('0')
                }).success(function (response:any) {
                    comp.openAutocomplete(response);
                });
            } else {
                this.clearAutocomplete();
            }
        }
    }
}

if (document.getElementById("search-bar-lender-form")) {
    let seachBarComponentVM = new SearchBarLenderComponentViewModel();
}