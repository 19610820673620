﻿export class PolylineManipulation {
    public static SimplifyLine(polyline: any, tolerance: number): any {
        let result: any = [];
        if (polyline.getPath() && polyline.getPath().getLength()) {
            var points = polyline.getPath().getArray();
            result = this.douglasPeucker(points, tolerance);
            result.push(points[points.length - 1]);
        }
        return result;
    }

    static douglasPeucker(points: any, tolerance: any): any {
        if (points.length <= 2) {
            return [points[0]];
        }
        let returnPoints: any = [];
        // make line from start to end 
        let line = new Line(points[0], points[points.length - 1]);
        // find the largest distance from intermediate points to this line
        let maxDistance = 0;
        let maxDistanceIndex = 0;
        let p;
        for (var i = 1; i <= points.length - 2; i++) {
            var distance = line.distanceToPoint(points[i]);
            if (distance > maxDistance) {
                maxDistance = distance;
                maxDistanceIndex = i;
            }
        }
        // check if the max distance is greater than our tollerance allows 
        if (maxDistance >= tolerance) {
            p = points[maxDistanceIndex];
            line.distanceToPoint(p);
            // include this point in the output 
            returnPoints = returnPoints.concat(this.douglasPeucker(points.slice(0, maxDistanceIndex + 1), tolerance));
            // returnPoints.push( points[maxDistanceIndex] );
            returnPoints = returnPoints.concat(this.douglasPeucker(points.slice(maxDistanceIndex, points.length), tolerance));
        } else {
            // ditching this point
            p = points[maxDistanceIndex];
            line.distanceToPoint(p);
            returnPoints = [points[0]];
        }
        return returnPoints;
    };
}

export class Line {
    point1: any;
    point2: any;

    constructor(p1: any, p2: any) {
        this.point1 = p1;
        this.point2 = p2;
    }

    distanceToPoint(point: any): any {
        let slope = (this.point2.lat() - this.point1.lat()) / (this.point2.lng() - this.point1.lng());
        let b = this.point1.lat() - (slope * this.point1.lng());
        let d = [];

        // distance to the linear equation
        d.push(Math.abs(point.lat() - (slope * point.lng()) - b) / Math.sqrt(Math.pow(slope, 2) + 1));
        // distance to p1
        d.push(Math.sqrt(Math.pow((point.lng() - this.point1.lng()), 2) + Math.pow((point.lat() - this.point1.lat()), 2)));
        // distance to p2
        d.push(Math.sqrt(Math.pow((point.lng() - this.point2.lng()), 2) + Math.pow((point.lat() - this.point2.lat()), 2)));

        return d.sort((a, b) => {
            return (a - b);
        })[0];
    }
}