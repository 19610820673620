function isEmail(email) {
    /*There is no perfect solution for email address veification but this should cover 99% of them
     * We can update this as more variations come to light that we want to cover to be a good validation
     * this should cover most normal layouts and allows for a plus "+" to be included.
     * https://www.regular-expressions.info/email.html 
     */
    var valid = (/[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(email.toLowerCase()));
    return valid;
};

function SetLoadAction(actionName, payload) {
    CINC.Core.CookiesVanilla.Set("CINC-LoadAction", actionName);
    CINC.Core.CookiesVanilla.Set("CINC-LoadActionPayload", JSON.stringify(payload));
};