﻿export class SearchMagicStrings {
    public static KeywordSearchKey = "kw";

    public static AclCity = 'acl_city';
    public static AclCounty = 'acl_county';
    public static AclElementarySchool = 'acl_elementaryschool';
    public static AclHighSchool = 'acl_highschool';
    public static AclJuniorHighSchool = 'acl_juniorhighschool';
    public static AclMainArea = 'acl_mainarea';
    public static AclMiddleSchool = 'acl_middleschool';
    public static AclPrimarySchool = 'acl_primaryschool';
    public static AclSchoolDistrict = 'acl_schooldistrict';
    public static AclSubArea = 'acl_subarea';
    public static AclZip5 = 'acl_zip5';
    public static City = 'city';
    public static County = 'county';
    public static ElementarySchool = 'elementaryschool';
    public static ElementarySchoolDistrict = 'elementaryschooldistrict';
    public static HighSchool = 'highschool';
    public static HighSchoolDistrict = 'highschooldistrict';
    public static IntermediateSchool = 'intermediateschool';
    public static JuniorHighSchool = 'juniorhighschool';
    public static Keyword = 'kw';
    public static BuildingName = 'Loc_Bldg_Name';
    public static Hamlet = 'loc_hamlet';
    public static Postoffice = 'loc_postoffice';
    public static Township = 'loc_township';
    public static Village = 'loc_village';
    public static MainArea = 'mainarea';
    public static MiddleSchool = 'middleschool';
    public static PrimarySchool = 'primaryschool';
    public static School = 'school';
    public static SchoolDistrict = 'schooldistrict';
    public static SecondarySchool = 'secondaryschool';
    public static SubArea = 'subarea';
    public static Subdivison = 'subdivision';
    public static Zip = 'zip';
}