$.fn.serializeObject = function (includeEmpty) {
    includeEmpty = (typeof includeEmpty !== 'undefined' ? includeEmpty : false);
    var o = {};
    var a = this.serializeArray();
    var val;
    $.each(a, function () {
        if (o[this.name]) {
            if (this.name !== 'beds' && this.name !== 'maxprice' && this.name !== 'minprice' && this.name !== 'baths') {
                o[this.name] += ',' + (this.value || '');
            }
        } else if (this.value !== '') {
            o[this.name] = this.value || '';
        } else if (includeEmpty) {
            o[this.name] = this.value || '';
        }
    });
    return o;
};