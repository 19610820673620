(function () {

    if (window.innerWidth <= 768) {
        $('.image-gallery .small-imgs').each(function () {
            $('.image-gallery .slides').append('<li class="small-imgs"><div class="img">' + $(this).find('.img:nth-child(2)').html() + '</div></li>');
            $(this).find('.img:nth-child(2)').detach();
        });
    }
}());

function detailsAccordion() {
    if ($(window).innerWidth() >= 768) {
        $('.accordion-details > input[type=checkbox]').attr("disabled", true);
    }
}

$('.btn--thumbnailinfo').on('click', function(e) {
    $('html, body').animate({ scrollTop: 0 });
});