﻿import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

export class ComponentTracker {

    constructor() {
        this.initClickEvent();
    }

    initClickEvent() {
        var elements = document.querySelectorAll('[data-castle-component]');
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', (event) => {
                if (event.target) {
                    var eventElement = <HTMLElement>event.currentTarget;
                    var dataComponentValue = eventElement.getAttribute('data-castle-component');
                    if (dataComponentValue) {
                       
                    }
                }
            });
        }
    }
}

let componentTracker = new ComponentTracker();
