﻿import { DomainEventHandler } from './DomainEventHandler';
import { IGoogleEvent, EventType } from './DomainEvent';
import * as DomainEventRegistry from './DomainEventRegistry';

export class PropertyInfoRequestSubmitEvent implements IGoogleEvent {
    category: string = "details-interactions";
    label: string = "Request Info";
    name: string = "PropertyInfoRequestSubmitEvent";
    type: EventType = EventType.Submit;
}

@DomainEventRegistry.DomainEventRegistry.register
class PropertyInfoRequestSubmitEventHandler extends DomainEventHandler {
    domainEventName: string = "PropertyInfoRequestSubmitEvent";
    handleDomainEvent<PropertyInfoRequestSubmitEvent>(ev: CustomEvent, args: PropertyInfoRequestSubmitEvent) : void {

    }
}