
export class URLReader{
    // parses the URL into components split on '/' 
    public static ParsePath(): string[]
    {
        // get full URL
        var queryString = window.location.href;

        if (queryString) {
            // split our query string into its component parts
            var arr = queryString.split('/');
        }

        return arr;
    }
}