﻿export interface IDomainEvent {
    name: string;   // this is used because there is no way to obtain the name of the class at runtime.
    type: EventType;
}

export enum EventType {
    Click,
    Submit,
    Focus,
    PageView,
    Ajax,
    Domain,
    Search
}

export interface IGoogleEvent extends IDomainEvent {
    category: string;
    label: string;
}

export function isGoogleEvent(object: any): object is IGoogleEvent {
    return 'category' in object && 'label' in object;
}

export interface IMixPanelEvent extends IDomainEvent {
    label: string;
}

export function isMixPanelEvent(object: any): object is IMixPanelEvent {
    return 'label' in object;
    //typeof object === 'string' || object instanceof String;
}
