// Dropdowns for selects and header dropdowns
$(document).ready(function () {
        var searchDropDownUI = $('.search.dropdown');
    if (searchDropDownUI) {
        if ($.fn.dropdown) {
            searchDropDownUI.dropdown();
        }

        }
		var dropDownUI = $('.ui.dropdown');
    if (dropDownUI) {
        if ($.fn.dropdown) {
            dropDownUI.dropdown();
        }
    }
});

window.REMODAL_GLOBALS = {
    NAMESPACE: 'modal',
    DEFAULTS: {
        hashTracking: false
    }
};
try {
    var datepicker = $('.datepicker');
    if (datepicker) {
        if ($.fn.pickadate) {
            datepicker.pickadate({

                min: 1,
                format: 'yyyy/mm/dd',
                close: 'OK',
                today: false,
                clear: "clear date",
                closeOnSelect: false,
                closeOnClear: false,
                onOpen: function () {
                    var date = new Date();
                    date.setDate(date.getDate() + 1);
                    this.set('select', date);
                },
                onClose: function () {
                    var scheduleShowing = $('.js-scheduleshowing');
                    scheduleShowing.focus();
                }
            });
        }
    }
}
catch (err) {
    console.log(err);
}
    

