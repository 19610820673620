﻿import { InputValidation } from '../tools/InputValidation';
import { OrganizerShare } from '../models/OrganizerShare';
import { BrowserDetector } from "../tools/BrowserDetector";
import { GoogleAnalyticsEventLogger } from "../tools/GoogleAnalyticsEventLogger";
import { CDN } from '../models/CDN';

declare var toastr: any;
declare var CINC: any; 

export class ListingDetailsBasicInfoComponentViewModel{
    map: any;
    propertyLatLng: any;

    favoriteIconLink: HTMLElement;
    favoriteIcon: HTMLElement;
    viewMapIcon: HTMLElement;
    viewPhotosIcon: HTMLElement;
    detailMap: HTMLElement;
    imageGallery: HTMLElement;
    emailShareInput : HTMLInputElement;
    emailShareLink : HTMLElement;
    faceBookShareLink : HTMLElement;
    twitterShareLink : HTMLElement;
    pinterestShareLink : HTMLElement;
    emailShareValidation : HTMLElement;
    emailShareMessage: HTMLElement;
    propertyId: HTMLInputElement;
    IsLoggedIn = (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.userDID !== null);

    readonly _homeIcon: string = `${CDN.StaticUrl}/img/consumer/nearby-home-pin.png`;
   
    constructor() {
        this.favoriteIconLink = <HTMLElement>document.getElementById("favorite-icon-link");
        this.favoriteIcon = <HTMLElement>document.getElementById("favorite-icon");

        this.viewMapIcon = <HTMLElement>document.getElementById("detailsViewMap");
        this.viewPhotosIcon = <HTMLElement>document.getElementById("detailsViewPhoto");
        this.detailMap = <HTMLElement>document.getElementById("details-map");
        this.imageGallery = <HTMLElement>document.getElementById("image-gallery");
        this.emailShareInput = <HTMLInputElement>document.getElementById("email-share");
        this.emailShareLink = <HTMLElement>document.getElementById("email-share-link");
        this.faceBookShareLink = <HTMLElement>document.getElementById("facebook-share-link");
        this.twitterShareLink = <HTMLElement>document.getElementById("twitter-share-link");
        this.pinterestShareLink = <HTMLElement>document.getElementById("pinterest-share-link");
        this.emailShareValidation = <HTMLElement>document.getElementById("email-share-validation");
        this.emailShareMessage = <HTMLElement>document.getElementById("email-share-message");
        this.propertyId = <HTMLInputElement>document.getElementById("quick-details-pdid");

        if(this.viewMapIcon)    
            this.viewMapIcon.addEventListener("click", (e) => this.handleViewMapIconClick(e));
            
        this.emailShareLink.addEventListener("click", (e) => this.handleEmailShareLinkClick(e));
        this.faceBookShareLink.addEventListener("click", (e) => this.handleFaceBookShareLinkClick(e));
        this.twitterShareLink.addEventListener("click", (e) => this.handleTwitterShareLinkClick(e));
        this.pinterestShareLink.addEventListener("click", (e) => this.handlePinterestShareLinkClick(e));

        if (this.favoriteIconLink)
            this.favoriteIconLink.addEventListener("click", (e) => this.handleFavoriteClick(e));

        if(this.viewPhotosIcon) {
            this.viewPhotosIcon.addEventListener("click", (e) => this.handleViewPhotosClick(e));
        }
        toastr.options = {
            "positionClass": "toast-top-full-width"
        };
    }

    

    handleViewPhotosClick(ev : Event) {
        ev.preventDefault();
        if(this.imageGallery.style.display === "none") {
            this.setVisibleMainDisplay(this.imageGallery);
        }
    }

    handleEmailShareLinkClick(e: Event) : void {
        e.preventDefault();
        let organizerShare : OrganizerShare = new OrganizerShare();
        organizerShare.Email = this.emailShareInput.value;
        organizerShare.PDID = this.propertyId.value;

        if(InputValidation.ValidateEmail(organizerShare.Email))
        {
            this.sendShareRequest(organizerShare);
            this.emailShareValidation.innerText = "";
            GoogleAnalyticsEventLogger.log('Old Property Details', 'property_share_email');
        }
        else {
            this.emailShareValidation.innerText = "Please enter a valid email address";
        }
    }

    handleFaceBookShareLinkClick(e: Event) : void {
        GoogleAnalyticsEventLogger.log('Old Property Details', 'property_share_facebook');
    }

    handleTwitterShareLinkClick(e: Event) : void {
        GoogleAnalyticsEventLogger.log('Old Property Details', 'property_share_twitter');
    }

    handlePinterestShareLinkClick(e: Event) : void {
        GoogleAnalyticsEventLogger.log('Old Property Details', 'property_share_pintrest');
    }

    sendShareRequest(organizerShare : OrganizerShare) : void {
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.open("POST", "/api/organizer/share", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.addEventListener("load", () => this.handleShareRequestResponse(xhttp))
        xhttp.send(JSON.stringify(organizerShare));
    }

    handleShareRequestResponse(xhttp: XMLHttpRequest) {
        if(xhttp.status === 200){
            this.emailShareMessage.style.display = "inline-block";
        }
    }

    handleViewMapIconClick(e: Event) : void {
        e.preventDefault();
        if (!(BrowserDetector.isBot() || this.preventThirdPartyLoading())) {
            this.displayMapContainer();
        }
    }

    displayMapContainer() {
        if(this.detailMap.style.display === "none")
        {
            this.setVisibleMainDisplay(this.detailMap);
            this.loadGoogleMap();
        }
    }

    loadGoogleMap() {
        if (this.detailMap) {
            this.setLatLng();

            this.map = new google.maps.Map(this.detailMap, {
                center: this.propertyLatLng,
                zoom: 14,
                scrollwheel: true,
                draggable: true,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                }
            });

            let homeMarker = new google.maps.Marker({
                position: this.propertyLatLng,
                map: this.map,
                icon: this._homeIcon
            });
        }
    }

    setVisibleMainDisplay(displayElement : HTMLElement) {
        this.detailMap.style.display = "none";
        if(this.imageGallery)
            this.imageGallery.style.display = "none";

        displayElement.style.display = "block";
    }
    
    handleFavoriteClick(e: Event):void {
        e.preventDefault();
        this.sendFavoriteClickRequest(this.favoriteIcon.classList.contains("icon-favorite_clicked"));
    }

    sendFavoriteClickRequest(isCurrentlyFavorite: boolean): void {
        if (!this.IsLoggedIn) {
            CINC.Core.PromptForSignup('', true);
            return;
        }
        let pdid = this.propertyId.value;
        let url = `/api/organizer/favorite/${pdid}`;
        let requestMethod = isCurrentlyFavorite ? "DELETE" : "POST";

        // predict what the state of the icon will be and set it to that
        this.setFavoriteClasses(!isCurrentlyFavorite);

        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.open(requestMethod, url, true);
        xhttp.addEventListener("load", () => this.handleFavoriteLoad(xhttp, isCurrentlyFavorite));
        xhttp.addEventListener("error", () => this.saveFailure());
        xhttp.send();
        
        if(!isCurrentlyFavorite)
            GoogleAnalyticsEventLogger.log('Old Property Details', 'property_favorited'); 
        else
            GoogleAnalyticsEventLogger.log('Old Property Details', 'property_unfavorited'); 
    }

    handleFavoriteLoad(request: XMLHttpRequest, isCurrentlyFavorite : boolean) {
        if (request.status === 200) {
            this.setFavoriteClasses(!isCurrentlyFavorite);
            this.saveSuccess(!isCurrentlyFavorite);
            let favoriteState = isCurrentlyFavorite ? "listing-unfavorited-property-details" : "listing-favorited-property-details";
        } else {
            this.setFavoriteClasses(isCurrentlyFavorite);
            this.saveFailure();
        }
    }

    setFavoriteClasses(isFavorite: boolean): void {
        this.favoriteIcon.classList.remove("icon-favorite_clicked");
        this.favoriteIcon.classList.remove("text-red");
        this.favoriteIcon.classList.remove("icon-favorite");

        if (isFavorite) {
            this.favoriteIcon.classList.add("icon-favorite_clicked");
            this.favoriteIcon.classList.add("text-red");
        } else {
            this.favoriteIcon.classList.add("icon-favorite");
        }
    }

    setLatLng() {
        if (!this.propertyLatLng && document.getElementById('property-lat') != null && document.getElementById('property-lng') != null)
        {
            let propLat: number = Number((<HTMLInputElement>document.getElementById('property-lat')).value);
            let propLng: number = Number((<HTMLInputElement>document.getElementById('property-lng')).value);
    
            this.propertyLatLng = {
                lat: propLat, lng: propLng
            };
        }
    }

    public virtualTourOnKeyUp(event: any, mediaURL: string) {
        var code = event.keyCode || event.which;
        switch (code) {
        case 13://Enter
        case 32://Space Bar
            {
            event.stopPropagation();
            window.open(mediaURL);
            break;
        }
        }
        return true;
    }

    public saveSuccess(isFavorite: boolean): void {
        if (isFavorite)
            toastr.success("Your favorite listing was saved successfully!");
        else
            toastr.success("Listing was removed from your favorites.");
    }


    public saveFailure(): void {
        toastr.error("Sorry, we were unable to save your favorite listing.");
    }

    private preventThirdPartyLoading(): boolean {
        if (typeof CINC == "undefined" || CINC == null || typeof CINC.IsNonDismissableModal == "undefined" || CINC.IsNonDismissableModal == null)
            return false;

        return CINC.IsNonDismissableModal;
    }

}

if (document.getElementById("detailsBasicInfo")) {
    let QuickDetailComponentVM = new ListingDetailsBasicInfoComponentViewModel();
}