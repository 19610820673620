﻿import { UpdateGeneralSettingsRequest } from "../Models/UpdateGeneralSettingsRequest";


export class LeadAvatarSettingsComponent {
    saveAvatar: HTMLButtonElement;
    saveUserPhoto: HTMLButtonElement;
    form: HTMLFormElement;
    uploadPhoto: HTMLButtonElement;
    chooseAvatar: HTMLButtonElement;
    photoForm: HTMLFormElement;
    selectedFile: File;
    file: HTMLInputElement;

    constructor(componentName: string) {
        this.form = <HTMLFormElement>document.getElementById(componentName);
        this.saveAvatar = <HTMLButtonElement>document.getElementById("save-avatar");
        this.saveUserPhoto = <HTMLButtonElement>document.getElementById("save-user-photo");
        this.uploadPhoto = <HTMLButtonElement>document.getElementById("accountsettings-uploadphoto");
        this.chooseAvatar = <HTMLButtonElement>document.getElementById("choose-avatar");
        this.photoForm = <HTMLFormElement>document.getElementById("lead-uploadphotoform");
        this.file = <HTMLInputElement>document.getElementById("accountphoto");

        this.file.addEventListener('change', (e) => this.onFileSelected(e));
        this.saveAvatar.addEventListener('click', (e) => this.getAvatar());
        this.saveUserPhoto.addEventListener('click', (e) => this.onUpload(e));
    } 

    onFileSelected(event: any) {
        event.preventDefault();
        this.selectedFile = event.target.files[0];
    }

    onUpload(event: any) {
        event.preventDefault();
        
        console.log(this.selectedFile)
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url = `/api/account/photo`;
        const formData = new FormData();
        formData.append('UploadImage', this.selectedFile);
        
        xhttp.addEventListener('load', () => this.handleRequestLoad(xhttp));
        xhttp.open("POST", url);
        xhttp.send(formData);
        
    }

    handleRequestLoad(request: XMLHttpRequest) {
        if(request.status === 500) {
            console.log("500 error")
        }
        if(request.status === 400) {
            this.handleRequestError(request);
        }
        if(request.status === 200) {
            this.handleSuccess(request);
        }
    }

    handleSuccess(request: XMLHttpRequest) {
        
        let photo = '/uploads/' + this.selectedFile.name;
        console.log("Success")
        this.updatePhoto(photo);
    }

    handleRequestError(request: XMLHttpRequest) {
        let response = JSON.parse(request.response);
        console.log("400 Error")
    }

    updatePhoto(photo: string): void {
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url = `/api/account/update`;
        let photoUpdateRequest = new UpdateGeneralSettingsRequest();
        photoUpdateRequest.PhotoLocation = photo;
        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(photoUpdateRequest));
        window.location.reload();
    }

    getAvatar(): void {
        let avatars: any = document.getElementsByClassName("avatar");
        let val: string;
        for (var i = 0; i < avatars.length; i++) {
            if (avatars[i].checked) {
                val = avatars[i].value;
                break;
            }
        }
        if (val === undefined) {
            return;
        }
        let avatar = "/avatars/" + val + ".png";

        this.updatePhoto(avatar);
    }
}

if (document.getElementById("lead-avatar-settings-update")) {
    let leadAvatarSettingsUpdate = new LeadAvatarSettingsComponent("avatar-settings-update-form");
}
