﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class ListCastDetailsPageViewEvent implements IGoogleEvent {
    
    name: string = "FavoriteDetailsPageViewEvent";
    
    category: string = "details-interactions";
    label: string = "ListCast Details Page View";
    type: EventType = EventType.PageView;
}

@DomainEventRegistry.DomainEventRegistry.register
class ListCastDetailsPageViewEventHandler extends DomainEventHandler {
    
    domainEventName: string = "ListCastDetailsPageViewEvent";

    handleDomainEvent<ListCastDetailsPageViewEvent>(ev: CustomEvent, args: ListCastDetailsPageViewEvent) : void {

    }
}