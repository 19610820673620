﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class SplashPageViewEvent implements IGoogleEvent {
    
    name: string = "SplashPageViewEvent";
    
    category: string = "splash-page";
    label: string = "Spash Page View";
    type: EventType = EventType.PageView;
}

@DomainEventRegistry.DomainEventRegistry.register
class SplashPageViewEventHandler extends DomainEventHandler {
    
    domainEventName: string = "SplashPageViewEvent";

    handleDomainEvent<SplashPageViewEvent>(ev: CustomEvent, args: SplashPageViewEvent) : void {

    }
}