import { SavedSearchApiCommunicator } from "../saved-search/SavedSearchApiCommunicator";
import { SavedSearchApiRequest } from "../saved-search/SavedSearchApiRequest";
import { SearchService } from "../search/SearchService";
import * as $ from "jquery";
import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
declare var toastr: any;    // TODO: fix this!
declare var CINC: any;
declare var StrCrit: any;

export class SavedSearchElement {
    readonly _element : HTMLElement;
    readonly _apiCommunicator: SavedSearchApiCommunicator;
    disabled: boolean = false;

    constructor(element: HTMLElement) {
        if (!element || $.isEmptyObject(element)) {
            return null;
        }

        // TODO: check to see if search filters form exists

        this._element = element;
        this._apiCommunicator = new SavedSearchApiCommunicator();
        this.initializeEventListeners();

        toastr.options = {
            "positionClass": "toast-top-full-width"
          };
    }

    initializeEventListeners(): void {
        this._element.addEventListener("click", () => {
            if(!this._element.classList.contains("saved"))
                this.saveSearch();
            SearchPageBroadcast.CloseQuickDetailsView();
        });
    }

    saveSearch(): void {
        if(!CINC.User){
            CINC.Core.PromptForSignup();
            return;
        }

        let searchCriteria = SearchService.StrCrit();
        if (!searchCriteria) {
            return;
        }
        let editDid = SearchService.EditDid();
        let apiRequest = new SavedSearchApiRequest();
        if (typeof editDid != 'undefined' && editDid) {
            apiRequest.EditDID = editDid;
        }
        apiRequest.StrCrit = $.param(searchCriteria);

        if (apiRequest.EditDID) {
            this._apiCommunicator.update(apiRequest, this.saveSuccess, this.saveFailure);
        } else {
            this._apiCommunicator.create(apiRequest, this.saveSuccess, this.saveFailure);
        }
    }

    public saveSuccess(): void {
        toastr.success("Your search was saved successfully!");
        SearchPageBroadcast.SaveSearchCompleted();
    }

    
    public saveFailure(): void {
        toastr.error("Sorry! An error occurred when saving your search. Please try again.");
    }
}

if (document.getElementsByClassName("saved-search-element")) {
    let savedSearchElements = document.getElementsByClassName("saved-search-element");
    for (let i = 0; i < savedSearchElements.length; i++) {
        const element = <HTMLElement>savedSearchElements[i];
        let savedSearchElement = new SavedSearchElement(element);
    }
}