﻿import { DomainEventBus } from '../events/DomainEventBus';
import { LenderDefaultBannerClickEvent } from '../events/LenderDefaultBannerClickEvent';

export class LenderDefaultBannerComponentViewModel {
    readonly LINK_ID = 'default-banner-link';
    readonly BANNER_ENDPOINT = '/api/lenderplatform/bannerclick';

    promoBannerLink: HTMLAnchorElement;

    constructor() {
        this.promoBannerLink = <HTMLAnchorElement>document.getElementById(this.LINK_ID);

        if (typeof this.promoBannerLink !== 'undefined' && this.promoBannerLink != null) {
            this.promoBannerLink.addEventListener('click', (e: MouseEvent) => this.handleBannerClick(e));
        }
    }

    handleBannerClick(e: MouseEvent): void {
        e.preventDefault();
        DomainEventBus.raise(new LenderDefaultBannerClickEvent())

        var http = new XMLHttpRequest();
        http.open('POST', this.BANNER_ENDPOINT);
        http.send();

        var link = <HTMLAnchorElement>e.currentTarget;
        var win = window.open(link.href, '_blank');
        win.focus();
    }
}

if (document.getElementById('houses-search-promo-banner')) {
    let lenderDefaultBannerComponentVM = new LenderDefaultBannerComponentViewModel();
}
