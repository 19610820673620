﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class LenderSignupBannerClickEvent implements IGoogleEvent {
    name: string = "LenderDefaultBannerClickEvent";
    category: string = "banner";
    label: string = "Signup Banner Click";
    type: EventType = EventType.Click;
}

@DomainEventRegistry.DomainEventRegistry.register
class LenderSignupBannerClickEventHandler extends DomainEventHandler {

    domainEventName: string = "LenderSignupBannerClickEvent";

    handleDomainEvent<SignupBannerClickEvent>(ev: CustomEvent, args: SignupBannerClickEvent): void {

    }
}