﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
import { SearchService } from '../search/SearchService';
import { MainFiltersComponent } from './MainFiltersComponent';
import { DomainEventBus } from '../events/DomainEventBus';
import * as $ from "jquery";
import { PropertySearchSortByClickEvent } from '../search/PropertySearchSortByClickEvent';
import { BrokenPhotoRequest } from '../models/BrokenPhotoRequest';
declare var bodymovin: any;
declare var loadingSpinner: any;
declare var CINC: any;

export class ListingResultsComponent {

    readonly _mobileWidth: number = 870;
    readonly _searchPropClass = `grid__2col`
    isListingsExpanded: boolean = true;

    constructor() {
        this.initSearchBar();
        this.initSortBy();
        this.initFilterToggle();
        this.initCustomEvents();
        this.setUrlStack();

        document.addEventListener(SearchPageBroadcast.searchRequestInitiatedEventName, () => { this.searchRequestInitiated(); });
        document.addEventListener(SearchPageBroadcast.searchRequestLoadedEventName, () => { this.searchRequestLoaded(); });

        document.addEventListener(SearchPageBroadcast.expandMapEventName, () => { this.expandMap(); });
        document.addEventListener(SearchPageBroadcast.contractMapEventName, () => { this.contractMap(); });
    }

    expandMap(): void {
        this.isListingsExpanded = false;
        let searchProps = document.getElementById("searchProperties");
        searchProps.classList.remove(this._searchPropClass);
    }

    contractMap(): void {
        this.isListingsExpanded = true;
        let searchProps = document.getElementById("searchProperties");
        searchProps.classList.add(this._searchPropClass);
    }

    initCustomEvents() {

        document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, (ev: CustomEvent) => {
            this.handleSearchResponse(ev.detail["response"]);
        });

        //when marker on map is clicked and quick details is displayed
        document.addEventListener(SearchPageBroadcast.mapMarkerClickedEventName, () => {
            let searchBar = document.getElementById("searchBar");
            if (searchBar.classList.contains("open")) {
                this.toggleFilters();
                let searchListings = document.getElementById("searchListingsContainer");
                if (searchListings) {
                    searchListings.style.display = "block";
                }
            }
        });

        let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("broken-image");
        for (let ind = 0; ind < elements.length; ind++) {
            let tag: HTMLElement = <HTMLElement>elements[ind];
            this.sendBrokenPhotoRequest(tag);
        }
    }

    sendBrokenPhotoRequest(HTMLElement: any): void {
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url = `Api/photo/broken`;

        let broPhoRequest = new BrokenPhotoRequest();

        broPhoRequest.PDID = HTMLElement.dataset.pdid;
        broPhoRequest.MLSid = HTMLElement.dataset.mlsid;
        broPhoRequest.URL = HTMLElement.dataset.photolink;

        xhttp.addEventListener("load", () => console.log('Received ' + xhttp.status + ' from broken photo api.'));
        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(broPhoRequest));
    }

    initFilterToggle() {
        document.addEventListener(SearchPageBroadcast.displayFiltersEventName, () => { this.toggleFilters() });
        document.addEventListener(SearchPageBroadcast.closeFiltersEventName, () => { this.toggleFilters() });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => { this.toggleFilters() });
        document.getElementById("viewListings").addEventListener("click", () => SearchPageBroadcast.CloseFilters());
        document.getElementById("resetFiltersBottom").addEventListener("click", () => SearchPageBroadcast.ResetFilters());
        if (typeof bodymovin !== "undefined") {
            bodymovin.loadAnimation({
                container: document.getElementById('viewListingsLoader'), // Required
                animationData: loadingSpinner, // Required
                renderer: 'svg', // Required
                loop: true, // Optional
                autoplay: true, // Optional
            });
        }
    }

    initSortBy() {
        let sortby = document.getElementById("sortby");
        let sorts = sortby.getElementsByClassName("item");

        for (let sortInd = 0; sortInd < sorts.length; sortInd++) {
            sorts.item(sortInd).addEventListener("click", (ev: Event) => {
                let sort = <HTMLElement>ev.target;

                DomainEventBus.raise(new PropertySearchSortByClickEvent());
                MainFiltersComponent.UpdateSortbyInput(sort.dataset["value"]);
                SearchService.SearchRequest(SearchService.StrCrit(), false, false);
            });
        }
    }

    initSearchBar() {
        // Fix searchbar on top on scroll
        let filtersPanel = document.getElementById("filters-panel");
        let searchListings = document.getElementById("searchListingsContainer");
        let quickDetailsMargin = 16; // this accounts for the negative margin on quick detail
        window.addEventListener("scroll",
            () => {
                let searchBar = document.getElementById("searchBar");
                if (searchBar) {
                    let quickDetails = document.getElementById("search-map-selected-prop");
                    var searchBarOffsetHeight = searchBar.offsetHeight;
                    let verticalScroll = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
                    if (verticalScroll > 0) {
                        searchBar.classList.add('searchbar-fixed');
                        if (window.innerWidth > this._mobileWidth) {
                            filtersPanel.style.marginTop = `${searchBarOffsetHeight}px`;
                            searchListings.style.marginTop = `${searchBarOffsetHeight}px`;
                        }
                        else {
                            let header = document.getElementById("header");
                            if (typeof (header) !== "undefined" && header !== null) {
                                header.classList.add('fixed');
                                let headerOffsetHeight = header.offsetHeight;
                                filtersPanel.style.marginTop = `${headerOffsetHeight}px`;
                                searchBar.style.marginTop = `${headerOffsetHeight}px`;
                                searchListings.style.marginTop = `${headerOffsetHeight + searchBarOffsetHeight}px`;
                            } else {
                                filtersPanel.style.marginTop = '0px';
                                searchListings.style.marginTop = '0px';
                            }
                        }

                        if (quickDetails && quickDetails.style.display !== "none") {
                            searchListings.style.marginTop = searchBarOffsetHeight - quickDetailsMargin + 'px';
                        }

                    } else {
                        searchBar.classList.remove('searchbar-fixed');
                        filtersPanel.style.marginTop = '0px';
                        searchListings.style.marginTop = '0px';

                        if (quickDetails && quickDetails.style.display !== "none") {
                            searchListings.style.marginTop = '0px';
                        }
                    }
                }
            });
    }

    toggleFilters() {
        let searchBar = <HTMLElement>document.getElementById("searchBar");
        let filterPanel = <HTMLElement>document.getElementsByClassName("filter-panel").item(0);
        let searchFooter = <HTMLElement>document.getElementsByClassName('search-footer').item(0);
        let filterToolbar = <HTMLElement>document.getElementsByClassName("filters-toolbar").item(0);
        let pageHtml = document.getElementsByTagName("html").item(0);

        this.toggleClass(searchBar, "open");
        this.toggleClass(filterPanel, "open");

        this.toggle(filterToolbar);
        this.toggle(searchFooter);

        if (searchBar.classList.contains("open")) {
            pageHtml.scrollTop = 0;
        } else {
            pageHtml.scrollTop = 0;
        }
    }

    toggleClass(element: HTMLElement, elementClass: string) {
        if (element.classList.contains("open")) {
            element.classList.remove("open");
        } else {
            element.classList.add("open");
        }
    }

    toggle(element: HTMLElement, displayType?: string) {
        if (!displayType)
            displayType = "block";
        if (element) {
            element.style.display = (element.style.display === "none" || element.style.display === "") ? displayType : "none";
        }
    }

    handleSearchResponse(response: any) {
        if (response) {
            let listingResultsContainer = document.getElementById("listing-results-component");
            listingResultsContainer.outerHTML = response.components.listingResultsComponent;

            if (!this.isListingsExpanded) {
                document.getElementById("searchProperties").classList.remove("grid__2col");
            }

            this.setUrlStack();
            SearchPageBroadcast.ListingResultsLoaded();
        }
    }

    handleTagUpdates(tags: any) {
        let filtersForm: HTMLFormElement = <HTMLFormElement>document.getElementById("filters-form");
        let strCrit = SearchService.SerializeSearchForm(filtersForm);

        var $includeSelect = $("[name=include-tags]");
        var $includeMenu = $("[name=include-tags]").siblings('.menu');
        var $excludeSelect = $("[name=exclude-tags]");
        var $excludeMenu = $("[name=exclude-tags]").siblings('.menu');

        var includeSelectHtml = "";
        var includeMenuHtml = "";
        var excludeSelectHtml = "";
        var excludeMenuHtml = "";
        for (var i = 0; i < tags.length; i++) {
            var tag = tags[i];
            var strCritVal = strCrit[tag.inputName];
            var includeSelected = false;
            var excludeSelected = false;
            if (strCritVal !== undefined) {
                excludeSelected = strCritVal === "0";
                includeSelected = !excludeSelected;
            }

            includeSelectHtml += "<option value=\"" + tag.inputName + "\"" + (includeSelected ? " selected" : "") + (excludeSelected ? " disabled" : "") + ">" + tag.displayName + "</option>";
            includeMenuHtml += "<div class=\"item" + (includeSelected ? " active filtered" : "") + (excludeSelected ? " disabled" : "") + "\" data-value=\"" + tag.inputName + "\">" + tag.displayName + "</div>";
            excludeSelectHtml += "<option value=\"" + tag.inputName + "\"" + (excludeSelected ? " selected" : "") + (includeSelected ? " disabled" : "") + ">" + tag.displayName + "</option>";
            excludeMenuHtml += "<div class=\"item" + (excludeSelected ? " active filtered" : "") + (includeSelected ? " disabled" : "") + "\" data-value=\"" + tag.inputName + "\">" + tag.displayName + "</div>";
        }

        $includeSelect.html(includeSelectHtml);
        $includeMenu.html(includeMenuHtml);
        $excludeSelect.html(excludeSelectHtml);
        $excludeMenu.html(excludeMenuHtml);
    }

    setUrlStack() {
        var resultsUrlElements = $('.js-urlstack-values input[type=hidden]');
        if (resultsUrlElements.length <= 0) {
            // if there are no stack elements present, see if the current url is in the existing url stack.
            // if it is, leave everything alone.
            var urlStack = CINC.Core.LocalStorage.Get('UrlStack');
            if (urlStack && urlStack.indexOf(window.location.pathname) >= 0)
                return;

            // otherwise remove the existing stack
            CINC.Core.LocalStorage.Remove('UrlStack');
            return;
        }

        let newStack: string[] = [];
        resultsUrlElements.each(function () {
            newStack.push(<string>($(this).val()));
        });

        CINC.Core.LocalStorage.Set('UrlStack', newStack);
    }

    searchRequestInitiated(): void {
        document.getElementById("listing-results-placeholder").classList.remove("listing-loading-skeleton");
        document.getElementById("searchProperties").style.display = "none";
    }

    searchRequestLoaded(): void {
        document.getElementById("listing-results-placeholder").classList.add("listing-loading-skeleton");
        document.getElementById("searchProperties").style.display = "block";
    }
}

if (document.getElementById("listing-results-component")) {
    var listingResultsComponent = new ListingResultsComponent();
}
