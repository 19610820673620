﻿import { InputValidation } from '../tools/InputValidation';
import { InquiryRequest } from '../models/InquiryRequest';
import { DomainEventBus } from '../events/DomainEventBus';
import { PropertyInfoRequestSubmitEvent } from '../events/PropertyInfoRequestSubmitEvent';
declare var Modals: any;

export class RequestPrequalificationComponentViewModel {

    //Inputs
    descriptionInput: HTMLInputElement;
    submitButton: HTMLButtonElement;
    pdidElement: HTMLInputElement;

    //displays
    requestSentSuccessful: HTMLElement;

    //validation
    fullNameValidation: HTMLElement;
    emailValidation: HTMLElement;
    phoneValidation: HTMLElement;
    descriptionValidation: HTMLElement;
    formValdiation: HTMLElement;

    form: HTMLFormElement;

    constructor(formName: string) {
        this.form = <HTMLFormElement>document.getElementById(formName);
        this.descriptionInput = <HTMLInputElement>this.form.elements.namedItem("description");
        this.submitButton = <HTMLButtonElement>this.form.elements.namedItem("submit-button");
        this.requestSentSuccessful = <HTMLElement>document.getElementById("request-prequalification-info-successful-send");

        this.descriptionValidation = <HTMLElement>this.form.elements.namedItem("description-validation");
        this.formValdiation = <HTMLElement>this.form.elements.namedItem("form-validation");
        this.pdidElement = <HTMLInputElement>this.form.elements.namedItem("request-info-pdid");
        //event listeners
        this.submitButton.addEventListener('click', (e) => this.handleSubmitClick(e));
    }

    handleSubmitClick(e: Event): void {
        e.preventDefault();
        if (this.checkValidation() && !this.submitButton.disabled) {
            this.submitButton.disabled = true;
            var loading = Modals.Loading("Requesting Pre-Qualification Info. This may take a minute or two.");
            var loadingModalTimeout = setTimeout(() => {
                clearTimeout(loadingModalTimeout);
                loading.Open();
            }, 1000);
            this.sendRequest();
            clearTimeout(loadingModalTimeout); // clear timeout in case appointment scheduling finishes before modal displays
            loading.Close();

        }

    }

    sendRequest(): void {
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url = `/api/account/prequalinquiry`;

        let inquiryRequest = new InquiryRequest();
        inquiryRequest.Message = `Description: ${this.descriptionInput.value}`;
        inquiryRequest.PDID = this.pdidElement.value;

        xhttp.addEventListener("load", () => this.handleRequestLoad(xhttp));

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(inquiryRequest));
    }

    handleRequestLoad(request: XMLHttpRequest) {
        if (request.status === 400) {
            this.handleRequestError(request);
        }
        else if (request.status === 200) {
            DomainEventBus.raise(new PropertyInfoRequestSubmitEvent());
            this.requestSentSuccessful.style.visibility = "visible";
            this.requestSentSuccessful.style.height = "auto";
            this.form.style.display = "none";

            setTimeout(() => {
                this.formValdiation.innerText = "";
                this.formValdiation.classList.remove("validation-message");
                this.formValdiation.classList.remove("success");
            }, 4000);
        }
    }

    handleRequestError(request: XMLHttpRequest) {
        let response = JSON.parse(request.response)
        this.formValdiation.innerText = response.message;
        this.formValdiation.classList.add("validation-message");
        this.formValdiation.classList.add("error");

        setTimeout(() => {
            this.formValdiation.innerText = "";
            this.formValdiation.classList.remove("validation-message");
            this.formValdiation.classList.remove("error");
        }, 4000);
    }

    checkValidation(): boolean {
        let retValue: boolean = true;
        retValue = this.validateInput(this.descriptionInput, this.descriptionValidation, "Description is required.", null, "") ? retValue : false;
        return retValue;
    }

    validateInput(input: HTMLInputElement, validation: HTMLElement, requiredMessage: string, validationFunc: Function, validationMessage: string): boolean {
        let retValue: boolean = true;
        if (!InputValidation.ValidateInputTextExist(input)) {
            validation.innerText = requiredMessage;
            retValue = false;
        }
        else {
            if (((validationFunc) && validationFunc(input.value)) || !(validationFunc)) {
                validation.innerText = "";
            }
            else {
                validation.innerText = validationMessage;
                retValue = false;
            }
        }
        return retValue;
    }
}

if (document.getElementById("prequalification-request-info")) {
    let requestInfoComponentVM = new RequestPrequalificationComponentViewModel("request-prequal-info-form");
}