﻿import { DomainEventBus } from '../events/DomainEventBus';
import { PromoBannerClickEvent } from '../events/PromoBannerClickEvent';
import { SearchPageBroadcast }from "../search/SearchPageBroadcast";

export class PromoBannerComponentViewModel {
    readonly LINK_ID = 'promo-banner-link';
    readonly SECTION_ID = 'houses-search-promo-banner';
    readonly BANNER_ENDPOINT = '/api/lenderplatform/bannerclick';

    promoBannerLink: HTMLAnchorElement;
    promoBannerSection: HTMLElement;

    constructor() {
        this.promoBannerLink = <HTMLAnchorElement>document.getElementById(this.LINK_ID);
        this.promoBannerSection = <HTMLAnchorElement>document.getElementById(this.SECTION_ID);

        if (typeof this.promoBannerLink !== 'undefined' && this.promoBannerLink != null) {
            this.promoBannerLink.addEventListener('click', (e: MouseEvent) => this.handleBannerClick(e));
        }

        document.addEventListener(SearchPageBroadcast.displayFiltersEventName, () => {
            this.promoBannerSection.hidden = true;
        });
        document.addEventListener(SearchPageBroadcast.closeFiltersEventName, () => {
            this.promoBannerSection.hidden = false;
        });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => {
            this.promoBannerSection.hidden = false;
        });
        document.addEventListener(SearchPageBroadcast.mapMarkerClickedEventName, () => {
            this.promoBannerSection.hidden = true;
        });
        document.addEventListener(SearchPageBroadcast.selectedPropertyClosedEventName, () => {
            this.promoBannerSection.hidden = false;
        });
    }

    handleBannerClick(e: MouseEvent): void {
        e.preventDefault();
        DomainEventBus.raise(new PromoBannerClickEvent())

        var http = new XMLHttpRequest();
        http.open('POST', this.BANNER_ENDPOINT);
        http.send();

        var link = <HTMLAnchorElement>e.currentTarget;
        var win = window.open(link.href, '_blank');
        win.focus();
    }
}

if (document.getElementById('houses-search-promo-banner')) {
    let promoBannerComponentVM = new PromoBannerComponentViewModel();
}
