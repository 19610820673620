import { DomainEventBus } from "../events/DomainEventBus";
import { FavoriteDetailsPageViewEvent } from "../events/FavoriteDetailsPageViewEvent";
import { FeaturedDetailsPageViewEvent } from "../events/FeaturedDetailsPageViewEvent";
import { ListCastDetailsPageViewEvent } from "../events/ListCastDetailsPageViewEvent";
import { WindowLoadEventHandler } from "../tools/WindowLoadEventHandler";

export class DetailsPageController {
    Location: Location;

    constructor(url: Location){
        this.Location = url;
    }

    public LogPageView(){
        if (!this.IsDetailsPage()){ 
            return;
        }
        
        if (this.IsDetailFavorited()) {
            DomainEventBus.raise(new FavoriteDetailsPageViewEvent());
        }

        if (this.IsDetailFeatured()) {
            DomainEventBus.raise(new FeaturedDetailsPageViewEvent());
        }

        if (this.IsListCastMarketed()){
            DomainEventBus.raise(new ListCastDetailsPageViewEvent());
        }
    }

    private IsDetailsPage() {
        var path = this.Location.pathname;
        return path.indexOf('/homedetails') === 0;
    }

    private IsDetailFavorited() {
        var favoriteElement = document.getElementById("is-details-favorited") as HTMLInputElement;
        return favoriteElement && favoriteElement.value && favoriteElement.value.toLowerCase() === "true";
    }

    private IsDetailFeatured() {
        var featuredElement = document.getElementById("is-details-featured") as HTMLInputElement;
        return featuredElement && featuredElement.value && featuredElement.value.toLowerCase() === "true";
    }

    private IsListCastMarketed() {
        var url = window.location.href;
        return url && (url.toLowerCase().indexOf("src=listcast") >= 0 || url.toLowerCase().indexOf("src%3dlistcast") >= 0);
    }
}

WindowLoadEventHandler.addLoadEvent(function(){
    let detailsPageController = new DetailsPageController(window.location);
    detailsPageController.LogPageView();
});
