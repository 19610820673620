﻿declare var toastr: any;
declare var CINC: any;
declare var Modals: any;
declare var FS: any;
import * as $ from "jquery";
import * as moment from 'moment';
import { InputValidation } from '../tools/InputValidation';
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

interface JQueryStatic {
    cookie: any;
}

export class PropertyScheduleComponentViewModel {

    form: HTMLFormElement;
    requestInPersonBtn: HTMLButtonElement;
    requestVideoChatBtn: HTMLButtonElement;
    videoChatSubmit: HTMLInputElement;
    videoTypeModal: HTMLInputElement;
    pdidElement: HTMLInputElement;
    virtualOpenHouseDate : HTMLElement;
    virtualOpenHouseDesc : HTMLElement;
    virtualOpenHouseLinkText : HTMLElement;
    pdid = new String;
    IsLoggedIn = (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.userDID !== null);  

    constructor() {
        this.form = <HTMLFormElement>document.getElementById("property-schedule-showing-component");
        this.requestInPersonBtn = <HTMLButtonElement>document.getElementById("requestInPersonBtn");
        this.requestVideoChatBtn = <HTMLButtonElement>document.getElementById("requestVideoChatBtn");
        this.videoChatSubmit = <HTMLInputElement>document.getElementById("videoChatSubmit");
        this.videoTypeModal = <HTMLInputElement>document.getElementById("videoTypeModal");
        this.virtualOpenHouseDate = document.getElementById("virtual-open-house-date");
        this.virtualOpenHouseDesc = document.getElementById("virtual-open-house-full-time-desc");
        this.virtualOpenHouseLinkText = document.getElementById("virtual-open-house-link-text");
        this.pdid = this.requestInPersonBtn.dataset.pdid;
        toastr.options = {
            "positionClass": "toast-top-full-width"
        };

        if (this.requestInPersonBtn)
            this.requestInPersonBtn.addEventListener("click", (e) => this.scheduleShowingOrGatherContactInfo(e));
        if (this.requestVideoChatBtn)
            this.requestVideoChatBtn.addEventListener("click", (e) => this.verifyDateAndPhone(e));
        if (this.videoChatSubmit)
            this.videoChatSubmit.addEventListener("click", (e) => this.scheduleShowingOrGatherContactInfoForVideoChat(e));
        if (this.virtualOpenHouseDate && this.virtualOpenHouseDesc && this.virtualOpenHouseLinkText)
            this.formatOpenHouseDates();
    }

    scheduleShowingOrGatherContactInfo(e?: Event) {

        if (e) {
            e.preventDefault();
        }

        var dateRequested = <HTMLInputElement>document.getElementById("datepicker");
        var dateChecker = dateRequested.value;

        if (dateRequested.value === "") {
            toastr.error("Please select a date");
            return;
        }

        if (!this.IsLoggedIn) {
            CINC.Core.PromptForSignup('', false);
            return;
        }

        // if the user doesn't have a valid cell phone
        // we need to capture it before we can schedule the showing
        if (!InputValidation.ValidatePhone(CINC.User.cellPhone)) {
            Modals.CellPhoneModal()
                .Open().Response((response: any) => {
                    var validPhone = InputValidation.ValidatePhone(response.CellPhone);
                    if (!validPhone) {
                        toastr.error("Please enter a valid phone number");
                    }
                    else {
                        CINC.Api.Account('update').post(response)
                            .success(() => { CINC.User.cellPhone = response.CellPhone; this.scheduleShowing(this.pdid, dateChecker); })
                            .fail(() => { toastr.error("Failed to Update Cellphone"); });
                    }
                });
        }
        else {
            //fullstory
            if (typeof FS !== "undefined" && FS !== null) {
                FS.event('In Person Showing Submitted', {
                    pageName_str: 'Property Details',
                });
            }
            this.scheduleShowing(this.pdid, dateChecker);
            GoogleAnalyticsEventLogger.log('Old Property Details', 'inperson_showing_request_sent');   
        }
    }

    verifyDateAndPhone(e?: Event) {
        if (e) {
            e.preventDefault();
        }

        var dateRequested = <HTMLInputElement>document.getElementById("datepicker");
        var videoType = <HTMLInputElement>document.getElementById("videotype");
        var dateChecker = dateRequested.value;

        if (dateRequested.value === "") {
            toastr.error("Please select a date");
            return;
        }

        if (!this.IsLoggedIn) {
            CINC.Core.PromptForSignup('', false);
            return;
        }

        // if the user doesn't have a valid cell phone
        // we need to capture it before we can schedule the showing
        if (!InputValidation.ValidatePhone(CINC.User.cellPhone)) {
            Modals.CellPhoneModal()
                .Open().Response((response: any) => {
                    var validPhone = InputValidation.ValidatePhone(response.CellPhone);
                    if (!validPhone) {
                        toastr.error("Please enter a valid phone number");
                    }
                    else {
                        CINC.Api.Account('update').post(response)
                            .success(() => {                                           
                                CINC.User.cellPhone = response.CellPhone; 
                                this.scheduleShowing(this.pdid, dateChecker); 
                             })
                            .fail(() => { toastr.error("Failed to Update Cellphone"); });
                    }
                });
        }
        else {
            this.videoTypeModal.checked = true;
        }
    }

    scheduleShowingOrGatherContactInfoForVideoChat(e?: Event) {

        if (e) {
            e.preventDefault();
        }

        var dateRequested = <HTMLInputElement>document.getElementById("datepicker");
        var dateChecker = dateRequested.value;

        let inputfields = document.getElementsByName("videotype");

        var appointmentChoices = "";

        for (var i = 0; i < inputfields.length; i++) {
            let listItem: any = inputfields[i];
            if (listItem.checked === true) {
                appointmentChoices += listItem.dataset.name + ", ";
            }
        }
        if (appointmentChoices.length > 0) {
            appointmentChoices = appointmentChoices.substring(0, appointmentChoices.length - 2);
        }
        if (appointmentChoices === "") { appointmentChoices = "None" }
        
        //fullstory
        if (typeof FS !== "undefined" && FS !== null){
            FS.event('Video Chat Showing Submitted', {
                pageName_str: 'Property Details',
            });
        }           
        this.scheduleShowing(this.pdid, dateChecker, appointmentChoices);
        GoogleAnalyticsEventLogger.log('Old Property Details', 'video_chat_showing_request_sent'); 
    }


    scheduleShowing(pdid: any, showingDate: any, appointmentChoices?: string) {
        var startDate = showingDate;
        var endDate = showingDate;
        var timezone = new Date().getTimezoneOffset() / 60;
        var videoChatChoices = appointmentChoices;
        var appointment = {
            PDID: pdid,
            StartDate: startDate,
            EndDate: endDate,
            AppointmentType: 'Showing',
            TimeZone: timezone,
            VideoChatOptions: appointmentChoices
        };

        var loading = Modals.Loading("Finding an agent. This may take a minute or two.");
        var loadingModalTimeout = setTimeout(() => {
            clearTimeout(loadingModalTimeout);
            loading.Open();
        }, 1000);

        CINC.Api.Organizer
            .Schedule('appointment').post(appointment)
            .success(() => {
                //fullstory
                if (typeof FS !== "undefined" && FS !== null){
                    FS.event('Schedule Showing Complete', {
                        pageName_str: 'Property Details',
                    });
                }
                toastr.info("We'll have an agent contact you at " + this.formatPhone(CINC.User.cellPhone) + " soon!", "Appointment is pending acceptance by an agent", { timeOut: 0, extendedTimeOut: 0 });
                $('.js-scheduleshowingwrap').fadeOut('slow');
            })
            .fail((response: any) => {
                var responseMessage = response.responseJSON.message;
                toastr.error("Failed to Schedule: " + responseMessage);
            })
            .always(() => {
                clearTimeout(loadingModalTimeout); // clear timeout in case appointment scheduling finishes before modal displays
                loading.Close();
            });
    };
    formatOpenHouseDates() {
        const virtualOpenHouseStartUtcDateString = this.virtualOpenHouseDate.getAttribute("data-voh-utcdate-start");
        const virtualOpenHouseEndUtcDateString = this.virtualOpenHouseDate.getAttribute("data-voh-utcdate-end");
        if (typeof virtualOpenHouseStartUtcDateString != "undefined" && virtualOpenHouseStartUtcDateString &&
            typeof virtualOpenHouseEndUtcDateString != "undefined" && virtualOpenHouseEndUtcDateString)
        {
            const virtualOpenHouseStartDate = new Date(virtualOpenHouseStartUtcDateString);
            const virtualOpenHouseEndDate = new Date(virtualOpenHouseEndUtcDateString);
            const localStartDt = moment(virtualOpenHouseStartDate);
            const localEndDt = moment(virtualOpenHouseEndDate);
            if (localStartDt.isValid() && localEndDt.isValid())
            {
                const localShowingTime = localStartDt.format("dddd, MMMM Do") + " from " + localStartDt.format("h:mm A") + " to " + localEndDt.format("h:mm A");
                this.virtualOpenHouseDesc.textContent = localShowingTime;
                if (this.virtualOpenHouseLinkText.textContent.indexOf("Click here") > -1) 
                {
                    const joinLinkText = `Click here to join the tour at ${localStartDt.format("h:mm A")}!`;
                    this.virtualOpenHouseLinkText.textContent = joinLinkText;
                }
            }
        }
    };
    formatPhone(phone: any) {
        var formatted = phone;
        if (phone.length === 10)
            formatted = phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6, 4);

        return formatted;
    };
}

if (document.getElementById("property-schedule-showing-component")) {
    let scheduleShowingVM = new PropertyScheduleComponentViewModel();
}
