declare var $:any;
import {SliderObject} from "../Definitions/flexslider";
import { BrowserDetector } from "../tools/BrowserDetector";
declare var CINC: any;
declare var ga: any;

export class PropertyImagesViewModel {

    private readonly activeSlideClass = "flex-active-slide";
    private isSignUpShowing = false;
    imageGallery: HTMLElement;
    virtualTourImage: HTMLElement;
    carousel: SliderObject;
    slider: SliderObject;

    constructor() {
        this.imageGallery = <HTMLElement>document.getElementById("image-gallery");
            let _This = this;
            window.addEventListener('resize', function () {
                _This.initImageGalleryFlexSlider();
            });

        this.virtualTourImage = document.getElementById("virtual-tour-image");
        if (this.virtualTourImage !== null) {
            this.virtualTourImage.addEventListener("click", (e: Event) => this.virtualTourOnKeyUpPress());
        }

        document.addEventListener("DOMContentLoaded", () => {
            if (!this.signUpModalIsShowing() && !BrowserDetector.isBot()) {
                this.initImageGalleryFlexSlider();
                this.initCarouselFlexSlider();
                this.carousel = this.initCarouselFlexSlider();
                this.slider = this.initImageFlexSlider(); 
            }           
        });
         
        
        // escape keyup closes modal when open
        document.addEventListener("keyup", (ev : KeyboardEvent) => {
            if(ev.keyCode === 27) 
            {
                let modal : HTMLInputElement = <HTMLInputElement>document.getElementById("imageGalleryModal");
                if(modal.checked)
                    modal.checked = false;
            }
        });

        // loops through images from image gallery and builds click events to handle the carousel and slider for the individual images
        let imageDivs = document.getElementsByClassName("img");
        let imageDivCount = imageDivs.length;
        for(let ind = 0; ind < imageDivCount; ind++) {
            let imageDiv : HTMLDivElement = <HTMLDivElement>imageDivs.item(ind);
            imageDiv.addEventListener("click", (ev: Event) => {
                let selectedImageNumber : number = parseInt(imageDiv.dataset["image"]);
                //moves both the carousel and the image slider to the corresponding image clicked
                this.setFlexSlider(selectedImageNumber, "carousel-slides", this.carousel);
                this.setFlexSlider(selectedImageNumber, "image-slides", this.slider);
            });
        }    
        
    }

    setFlexSlider(selectedIndex : number, flexsliderClass : string, slider : SliderObject) {
        if(document.getElementsByClassName(flexsliderClass))
        {
            let listItems = document.getElementsByClassName(flexsliderClass).item(0).getElementsByTagName("li");
            let listItemCount = listItems.length;
            for(let ind = 0; ind < listItemCount; ind++) {
                let listItem : HTMLElement = <HTMLElement>listItems.item(ind);
                if(ind === selectedIndex)
                    if(slider) {
                        $(slider).data('flexslider').flexAnimate(selectedIndex, false);
                    }
                else {
                    listItem.classList.remove(this.activeSlideClass);
                }
            }
        }
    }

    getGridSize() : number {
        return (window.innerWidth < 600) ? 1 :
            (window.innerWidth <= 1100 && window.innerWidth > 600) ? 2 :
                (window.innerWidth < 1100) ? 3 : 3;
    }

    private initImageGalleryFlexSlider() {
        if ($.fn.flexslider) {
            $('#imageGallery').flexslider({
                animation: "slide",
                controlNav: false,
                slideshow: false,
                itemWidth: 210,
                itemMargin: 5,
                minItems: this.getGridSize(), // use function to pull in initial value
                maxItems: this.getGridSize() // use function to pull in initial value
            });
        }
    }
    private initCarouselFlexSlider(): SliderObject {
        if ($.fn.flexslider) {
            return $('#carousel').flexslider({
                animation: "slide",
                controlNav: false,
                slideshow: false,
                itemWidth: 100,
                itemMargin: 5,
                asNavFor: '#slider'
            });
        }
    }
    private initImageFlexSlider(): SliderObject {
        if ($.fn.flexslider) {
            return $('#slider').flexslider({
                animation: "slide",
                controlNav: false,
                keyboard: true,
                multipleKeyboard: true,
                slideshow: false,
                sync: "#carousel"
            });
        }
    };
    private signUpModalIsShowing(): boolean {
        if (typeof CINC == "undefined" || CINC == null || typeof CINC.IsNonDismissableModal == "undefined" || CINC.IsNonDismissableModal == null)
            return false;

        return CINC.IsNonDismissableModal;
    }

    private virtualTourOnKeyUpPress(): void {
    }
}

if(document.getElementById("imageGallery")) {
    let propertyImageVM = new PropertyImagesViewModel();
}

