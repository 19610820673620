declare var gtag: any;

export class GoogleAnalyticsEventLogger {
    static log(feature : string, event : string) : void {
        if (typeof gtag !== 'undefined' && gtag !== null){
            gtag("event", event, { "feature": feature });
        } else {
            console.log("unable to fire Google Analytics event")
        }
    }
}