﻿import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
import { DomainEventBus } from "../events/DomainEventBus";
import { MainFiltersComponent } from "./MainFiltersComponent";
import { SearchService } from "../search/SearchService";
import { PropertySearchSortByClickEvent } from "../search/PropertySearchSortByClickEvent";
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

export class SearchHeaderComponent {
    resetFilters: HTMLElement = document.getElementById("resetFilters");
    searchDescription: HTMLElement = document.getElementById("searchDescription");
    searchHeaderTitle: HTMLElement = document.getElementById("searchHeaderTitle");

    constructor() {
        document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, (ev: CustomEvent) => {
            this.handleSearchResponse(ev.detail["response"]);
        });
        this.initSortBy();
        document.addEventListener(SearchPageBroadcast.resetFiltersEventName, () => { this.initSortBy(); });
        if (this.resetFilters) {
            this.resetFilters.addEventListener("click", () => SearchPageBroadcast.ResetFilters());
        }
    }

    handleSearchResponse(response: any) {
        if (response) {
            if (this.searchDescription) {
                this.searchDescription.innerHTML = response.searchDescription;
            }
            if (this.searchHeaderTitle) {
                this.searchHeaderTitle.innerHTML = response.title;
            }
            
        }
    }

    initSortBy() {
        let sortby = document.getElementById("sortby");
        let sorts = sortby.getElementsByClassName("item");

        for (let sortInd = 0; sortInd < sorts.length; sortInd++) {
            sorts.item(sortInd).addEventListener("click", (ev: Event) => {
                let sort = <HTMLElement>ev.target;

                DomainEventBus.raise(new PropertySearchSortByClickEvent());
                MainFiltersComponent.UpdateSortbyInput(sort.dataset["value"]);
                SearchService.SearchRequest(SearchService.StrCrit(), true);
                
                if(sort.dataset["value"] === "newest"){
                    GoogleAnalyticsEventLogger.log('Search Homes', 'sort_year_built');
                }
                else{
                    console.log("sort_" + sort.dataset["value"]);
                    GoogleAnalyticsEventLogger.log('Search Homes', 'sort_' + sort.dataset["value"]);
                }
            });
            if (sorts.item(sortInd).classList.contains("active") && sorts.item(sortInd).classList.contains("selected")) {
                sorts.item(sortInd).classList.remove("active");
                sorts.item(sortInd).classList.remove("selected");
            }
        }
    }
}

if (document.getElementById("searchListingHeader")) {
    var searchHeaderComponent = new SearchHeaderComponent();
} 
