﻿import { ApiComponent } from "./ApiComponent";
import { CDN } from '../models/CDN';

export class WhatsNearbyComponentViewModel extends ApiComponent {
    Map: any;
    InfoWindow: any;
    PlacesArray: any[] = [];
    PlacesMarkerArray: any[] = [];

    PropertyLatLng: any;

    PlacesService: any;

    CategorySelect: HTMLSelectElement;

    PlacesLocation: HTMLElement;

    readonly _homeIcon: string = `${CDN.StaticUrl}/img/consumer/nearby-home-pin.png`;
    readonly _symbolDefault: any = {
        path: "M 30.00,0.36 C 33.73,0.65 37.04,2.08 40.00,4.39 57.81,18.27 48.86,40.22 39.19,56.00 37.16,59.31 28.96,72.75 25.87,73.66 23.36,74.40 21.47,71.67 20.16,69.98 20.16,69.98 10.10,55.00 10.10,55.00 0.76,39.36 -7.41,17.96 10.10,4.39 12.85,2.17 14.63,1.53 18.00,0.36 21.63,-0.07 26.34,-0.27 30.00,0.36 Z",
        fillColor: "#1fac90",
        fillOpacity: 1,
        strokeColor: "#ffffff",
        strokeWeight: 2,
        scale: .4,
        anchor: new google.maps.Point(25,75)
    };
    readonly _symbolHover: any = {
        path: "M 30.00,0.36 C 33.73,0.65 37.04,2.08 40.00,4.39 57.81,18.27 48.86,40.22 39.19,56.00 37.16,59.31 28.96,72.75 25.87,73.66 23.36,74.40 21.47,71.67 20.16,69.98 20.16,69.98 10.10,55.00 10.10,55.00 0.76,39.36 -7.41,17.96 10.10,4.39 12.85,2.17 14.63,1.53 18.00,0.36 21.63,-0.07 26.34,-0.27 30.00,0.36 Z",
        fillColor: "#1c9980",
        fillOpacity: 1,
        strokeColor: "#ffffff",
        strokeWeight: 2,
        scale: .4,
        anchor: new google.maps.Point(25,75)
    };
    readonly _symbolSelected: any = {
        path: "M 30.00,0.36 C 33.73,0.65 37.04,2.08 40.00,4.39 57.81,18.27 48.86,40.22 39.19,56.00 37.16,59.31 28.96,72.75 25.87,73.66 23.36,74.40 21.47,71.67 20.16,69.98 20.16,69.98 10.10,55.00 10.10,55.00 0.76,39.36 -7.41,17.96 10.10,4.39 12.85,2.17 14.63,1.53 18.00,0.36 21.63,-0.07 26.34,-0.27 30.00,0.36 Z",
        fillColor: "#e74c3c",
        fillOpacity: 1,
        strokeColor: "#ffffff",
        strokeWeight: 2,
        scale: .4,
        anchor: new google.maps.Point(25,75)
    };

    constructor() {
        super();
        if(!this.isBotViewing())
        {
            this.InfoWindow = new google.maps.InfoWindow();
            this.PlacesLocation = document.getElementById('places-locations');
            this.CategorySelect = <HTMLSelectElement>document.getElementById('place-categories');

            this.InitGoogleObjects();
            this.InitGoogleEventListeners();
            this.InitLocalEventListeners();
        }
    }

    InitGoogleObjects() {
        let propLat: number = Number((<HTMLInputElement>document.getElementById('property-lat')).value);
        let propLng: number = Number((<HTMLInputElement>document.getElementById('property-lng')).value);

        this.PropertyLatLng = {
            lat: propLat, lng: propLng
        };

        this.Map = new google.maps.Map(document.getElementById("map"), {
            center: this.PropertyLatLng,
            zoom: 12,
            scrollwheel: false,
            draggable: true,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            }
        });

        let homeMarker = new google.maps.Marker({
            position: this.PropertyLatLng,
            map: this.Map,
            icon: this._homeIcon
        });

        this.GetPlaces('store');
    }

    InitGoogleEventListeners() {
        let _This : WhatsNearbyComponentViewModel = this;
        this.Map.addListener('click', () => {
            if (_This.InfoWindow) {
                _This.InfoWindow.close();
                _This.InfoWindow['selectedMarker'] = null;
                _This.PlacesMarkerArray.forEach((marker) => {
                    marker.setIcon(_This._symbolDefault);
                });
            }
        });

        google.maps.event.addListener(this.InfoWindow, 'closeclick', function () {
            _This.InfoWindow['selectedMarker'] = null;
            _This.PlacesMarkerArray.forEach((marker: any) => {
                marker.setIcon(_This._symbolDefault);
            });
        });
    }

    InitLocalEventListeners() {
        this.CategorySelect.addEventListener('change', () => {
            this.ClearList();
            this.ClearMarkers();
            this.GetPlaces(this.CategorySelect.options[this.CategorySelect.selectedIndex].value);
        });
    }

    GetPlaces(placeCategory: any, callback?: Function) {

        let placeRequest: any = {
            latitude: this.PropertyLatLng.lat,
            longitude: this.PropertyLatLng.lng,
            radius: 4000,
            type: placeCategory
        };

        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.open("POST", "/ajax/details/whatsnearby", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.addEventListener("load", () => this.handleGetPlacesResponse(xhttp, callback));
        xhttp.send(JSON.stringify(placeRequest));
    }

    handleGetPlacesResponse(request: XMLHttpRequest, callback?: Function) {
        if (request.status === 200) {
            let results: any = JSON.parse(request.response);
            for (var i = 0; i < results.length; i++) {
                var place = results[i];
                this.PlacesArray.push(place);
            }

            if (callback) {
                callback();
            } else {
                this.PlaceMarkers();
            }
        } else {

        }
    }

    ClearMarkers() {
        this.PlacesArray = [];
        this.PlacesMarkerArray.forEach((marker: any) => {
            marker.setMap(null);
        });
        this.PlacesMarkerArray = [];
    }

    ClearList() {
        this.PlacesLocation.innerHTML = "";
    }

    PlaceMarkers() {
        let _This: WhatsNearbyComponentViewModel  = this;
        this.PlacesArray.forEach((place: any) => {

            var locationMarker = new google.maps.Marker({
                map: this.Map,
                position: place.geometry.location,
                icon: this._symbolDefault,
                title: place.name,
            });
            locationMarker.setValues({ 'place_id': place.place_id });

            google.maps.event.addListener(locationMarker, 'click', ((marker) => {
                return function () {

                    _This.PlacesMarkerArray.forEach((marker: any) => {
                        marker.setIcon(_This._symbolDefault);
                    });

                    let placeId: string = marker.get("place_id");
                    let matchingPlace: any = null;
                    for (let i = 0; i < _This.PlacesArray.length; i++) {
                        if (_This.PlacesArray[i].place_id === placeId) {
                            matchingPlace = _This.PlacesArray[i];
                        }
                    }

                    if (typeof matchingPlace !== "undefined") {
                        var mapPopupHtml = "";

                        mapPopupHtml = "<div class='amenity-snapshot'>" + matchingPlace.name + "<br>";
                        if (typeof matchingPlace.rating !== "undefined") {
                            var rating = Number(matchingPlace.rating);
                            for (var ratingInd = 1; ratingInd <= rating + .5; ratingInd++) {
                                mapPopupHtml += "<i class='icon-rate text-yellow'></i>";
                            }
                            for (var greyInd = rating; greyInd < 4.5; greyInd++) {
                                mapPopupHtml += "<i class='icon-rate text-grey'></i>";
                            }
                        }
                        mapPopupHtml += "</div>";

                        _This.InfoWindow.setContent(mapPopupHtml);
                        _This.InfoWindow.open(_This.Map, marker);
                        locationMarker.setIcon(_This._symbolSelected);
                        _This.InfoWindow['selectedMarker'] = locationMarker.get("place_id");
                    } else {
                        _This.PlacesService.getDetails({ placeId: marker.get("place_id") }
                            , (placeDetail: any, status: any) => {
                                if (status === google.maps.places.PlacesServiceStatus.OK) {

                                    var mapPopupHtml = "";

                                    mapPopupHtml = "<div class='amenity-snapshot'>" + placeDetail.name + "<br>";
                                    if (typeof placeDetail.rating !== "undefined") {
                                        var rating = Number(placeDetail.rating);
                                        for (var ratingInd = 1; ratingInd <= rating + .5; ratingInd++) {
                                            mapPopupHtml += "<i class='icon-rate text-yellow'></i>";
                                        }
                                        for (var greyInd = rating; greyInd < 4.5; greyInd++) {
                                            mapPopupHtml += "<i class='icon-rate text-grey'></i>";
                                        }
                                    }
                                    mapPopupHtml += "</div>";
                                }

                                _This.InfoWindow.setContent(mapPopupHtml);
                                _This.InfoWindow.open(_This.Map, marker);
                                locationMarker.setIcon(_This._symbolSelected);
                                _This.InfoWindow['selectedMarker'] = locationMarker.get("place_id");
                            });
                    }
                }
            })(locationMarker));

            google.maps.event.addListener(locationMarker, 'mouseover', function () {
                _This.HighlightMarker(locationMarker, _This);
            });
            google.maps.event.addListener(locationMarker, 'mouseout', function () {
                _This.UnlightMarker(locationMarker, _This);
            });
            this.PlacesMarkerArray.push(locationMarker);
            this.PlacesLocation.innerHTML += "<li class='place-item' data-place_id='" + place.place_id + "'>" + place.name + "<br><span class='text-grey text-small'>" + place.vicinity + "</span></li>";
        });

        let placesItemList = document.getElementsByClassName("place-item");

        for (let _i = 0; _i < placesItemList.length; _i++) {
            let item: HTMLElement = <HTMLElement>placesItemList.item(_i);
            item.addEventListener("mouseenter", () => {
                this.AlterMarkerById(item.dataset.place_id, this.HighlightMarker);
            });
            item.addEventListener("mouseleave", () => {
                this.AlterMarkerById(item.dataset.place_id, this.UnlightMarker);
            });
        }
    }

    public IsMarkerInfoWindowOpen(marker: any): boolean {
        var map = this.InfoWindow.getMap();
        return (typeof map !== 'undefined' && marker['place_id'] === this.InfoWindow['selectedMarker']);
    }

    public AlterMarkerById(markerId: string, callback: Function) {
        var marker;
        this.PlacesMarkerArray.forEach((x) => {
            if (x.place_id === markerId) {
                marker = x;
            }
        });
        if (marker)
            callback(marker, this);
    }

    public HighlightMarker(marker: any, _This: any) {
        if (!_This.IsMarkerInfoWindowOpen(marker)) {
            marker.setIcon(_This._symbolHover);
        }
    }

    public UnlightMarker(marker: any, _This: any) {
        if (!_This.IsMarkerInfoWindowOpen(marker)) {
            marker.setIcon(_This._symbolDefault);
        }
    }
}

if(document.getElementById("property-whats-nearby")){
    let WhatsNearbyComponentVM = new WhatsNearbyComponentViewModel();
}
