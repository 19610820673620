﻿import { InputValidation } from '../tools/InputValidation';
import { InquiryRequest } from '../models/InquiryRequest';
import { DomainEventBus } from '../events/DomainEventBus';
import { PropertyInfoRequestSubmitEvent } from '../events/PropertyInfoRequestSubmitEvent';
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';
declare var Modals: any;
declare var lh: any;
declare var FS: any;

export class RequestInfoComponentViewModel {

    //Inputs
    fullNameInput: HTMLInputElement;
    emailInput: HTMLInputElement;
    phoneInput: HTMLInputElement;
    descriptionInput: HTMLInputElement;
    submitButton: HTMLButtonElement;
    pdidElement: HTMLInputElement;
    ListingUidInput: HTMLInputElement;

    //displays
    requestSentSuccessful: HTMLElement;

    //validation
    fullNameValidation: HTMLElement;
    emailValidation: HTMLElement;
    phoneValidation: HTMLElement;
    descriptionValidation: HTMLElement;
    formValdiation : HTMLElement;

    form : HTMLFormElement;

    constructor(formName :string) {
        this.form = <HTMLFormElement>document.getElementById(formName);
        this.fullNameInput = <HTMLInputElement>this.form.elements.namedItem("full-name");
        this.emailInput = <HTMLInputElement>this.form.elements.namedItem("email");
        this.phoneInput = <HTMLInputElement>this.form.elements.namedItem("phone");
        this.descriptionInput = <HTMLInputElement>this.form.elements.namedItem("description");
        this.ListingUidInput = <HTMLInputElement>this.form.elements.namedItem("request-info-listinguid");
        this.submitButton = <HTMLButtonElement>this.form.elements.namedItem("submit-button");
        this.requestSentSuccessful = <HTMLElement>document.getElementById("request-info-successful-send");

        this.fullNameValidation = <HTMLElement>this.form.elements.namedItem("full-name-validation");
        this.emailValidation = <HTMLElement>this.form.elements.namedItem("email-validation");
        this.phoneValidation = <HTMLElement>this.form.elements.namedItem("phone-validation");
        this.descriptionValidation = <HTMLElement>this.form.elements.namedItem("description-validation");
        this.formValdiation = <HTMLElement>this.form.elements.namedItem("form-validation");
        this.pdidElement = <HTMLInputElement>this.form.elements.namedItem("request-info-pdid");
        //event listeners
        this.submitButton.addEventListener('click', (e) => this.handleSubmitClick(e));
    }

    handleSubmitClick(e: Event) : void {
        e.preventDefault();
        if(this.checkValidation() && !this.submitButton.disabled)
        {
            this.submitButton.disabled = true;
            var loading = Modals.Loading("Requesting Info. This may take a minute or two.");
            var loadingModalTimeout = setTimeout(() => {
                clearTimeout(loadingModalTimeout);
                loading.Open();
            }, 1000);
            this.sendRequest();
            clearTimeout(loadingModalTimeout); // clear timeout in case appointment scheduling finishes before modal displays
            loading.Close();         
        }
        GoogleAnalyticsEventLogger.log('Old Property Details', 'property_inquiry_sent');    
    }

    sendRequest() : void {
        let xhttp : XMLHttpRequest = new XMLHttpRequest();
        let url = `/api/account/inquiry`;


        let inquiryRequest = new InquiryRequest();
        inquiryRequest.FullName = this.fullNameInput.value;
        inquiryRequest.Email = this.emailInput.value;
        inquiryRequest.Phone = this.phoneInput.value;
        inquiryRequest.Message = this.descriptionInput.value;

        if (this.pdidElement != null) {
            inquiryRequest.PDID = this.pdidElement.value;
        }
        
        xhttp.addEventListener("load", () => this.handleRequestLoad(xhttp));

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(inquiryRequest));
    }

    handleRequestLoad(request : XMLHttpRequest) {
        if(request.status === 400)
        {
            this.handleRequestError(request);
        }
        else if(request.status === 200)
        {
            //fullstory
            if (typeof FS !== "undefined" && FS !== null) {
                FS.event('property inquiry sent', {
                    pageName_str: 'Property Details',
                });
            }
            DomainEventBus.raise(new PropertyInfoRequestSubmitEvent());
            this.requestSentSuccessful.style.visibility = "visible";
            this.requestSentSuccessful.style.height = "auto";
            this.form.style.display = "none";

            setTimeout(() => { 
                this.formValdiation.innerText = "";
                this.formValdiation.classList.remove("validation-message");
                this.formValdiation.classList.remove("success");
            }, 4000);
        }
    }

    handleRequestError(request : XMLHttpRequest) {
        let response = JSON.parse(request.response)
        this.formValdiation.innerText = response.message;
        this.formValdiation.classList.add("validation-message");
        this.formValdiation.classList.add("error");

        setTimeout(() => { 
            this.formValdiation.innerText = "";
            this.formValdiation.classList.remove("validation-message");
            this.formValdiation.classList.remove("error");
        }, 4000);
    }

    checkValidation() : boolean {
        let retValue : boolean = true;

        retValue = this.validateInput(this.fullNameInput, this.fullNameValidation, "Full Name is required.", null,"") ? retValue : false;
        retValue = this.validateInput(this.phoneInput, this.phoneValidation, "Phone Number is required.", InputValidation.ValidatePhone, "Please enter a valid Phone Number") ? retValue : false;
        retValue = this.validateInput(this.emailInput, this.emailValidation, "Email Address is required.", InputValidation.ValidateEmail, "Please enter a valid Email Address") ? retValue : false;
        retValue = this.validateInput(this.descriptionInput, this.descriptionValidation, "Description is required.", null, "") ? retValue : false;
        
        return retValue;
    }

    validateInput(input : HTMLInputElement, validation: HTMLElement, requiredMessage: string, validationFunc : Function, validationMessage: string) : boolean {
        let retValue : boolean = true;
        if(!InputValidation.ValidateInputTextExist(input)) {
            validation.innerText = requiredMessage;
            retValue = false;
        }
        else{
            if(((validationFunc) && validationFunc(input.value)) || !(validationFunc)) {
                validation.innerText = "";
            }
            else{
                validation.innerText = validationMessage;
                retValue = false;
            }
        }
        return retValue;
    }
}

if (document.getElementById("property-request-info")) {
    let requestInfoComponentVM = new RequestInfoComponentViewModel("request-info-form");
}
if (document.getElementById("school-request-info-form")) {
    let schoolRequestInfoComponentVM = new RequestInfoComponentViewModel("school-request-info-form");
}

if(document.getElementById("footer-get-intouch-form")) {
    let getInTouchVM = new RequestInfoComponentViewModel("footer-get-intouch-form");
}
if (document.getElementById("contact-form-assigned-agent-get-intouch-form")) {
    let requestInfoComponentHmEval = new RequestInfoComponentViewModel("contact-form-assigned-agent-get-intouch-form");
}
