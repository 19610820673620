﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
import { BrowserDetector } from '../tools/BrowserDetector';


export class WrappedSearchbarContainer {
    searchBarSection: HTMLElement = document.getElementById("searchBar");

    constructor() {
        this.initMobileEvents();
    }

    initMobileEvents() {
        let mapButton = document.getElementById("MobileMapListingsButton");
        let mapHTML = ' Map';
        let listHTML = ' List';

        let searchFooter = document.getElementsByClassName("search-footer");
        let searchFooterElement = <HTMLDivElement>searchFooter.item(searchFooter.length - 1);

        if (mapButton) {
            mapButton.addEventListener("click", () => {
                if (mapButton.innerHTML === mapHTML) {
                    mapButton.innerHTML = listHTML;
                    this.searchBarSection.classList.add("searchbar-map-view");
                    searchFooterElement.style.display = "none";
                    SearchPageBroadcast.DisplayMobileMap();
                }
                else {
                    mapButton.innerHTML = mapHTML;
                    this.searchBarSection.classList.remove("searchbar-map-view");
                    //TODO: Make this a searchpage broadcast
                    let selectedProperty: HTMLElement = document.getElementById("search-map-selected-prop");
                    searchFooterElement.style.display = "block";
                    let selectedPropertyMobile: HTMLElement = document.getElementById("search-map-selected-prop-mobile");
                    if (selectedProperty.innerHTML !== "" || selectedPropertyMobile.innerHTML !== "") {
                        selectedProperty.innerHTML = "";
                        selectedPropertyMobile.innerHTML = "";
                    }
                    SearchPageBroadcast.DisplayMobileListings();
                }
            });
        }

        window.addEventListener("resize", () => {
            if (!BrowserDetector.isMobileBrowser()) {
                if (BrowserDetector.isMobileWidth()) {
                    mapButton.innerHTML = mapHTML;
                }
            }
        });
    }

    toggleDisplay(element: HTMLElement, display?: string) {
        if (element.style.display === "" || element.style.display === "none") {
            element.style.display = display ? display : "block";
        } else {
            element.style.display = "none";
        }
    }
}

if (document.getElementById("wrappedSearchbarContainer")) {
    let wrappedSearchbarContainer = new WrappedSearchbarContainer();
}