﻿import { CDN } from '../models/CDN';

export class ListingMapMarker extends google.maps.Marker {

    static readonly _path = `${CDN.StaticUrl}/img/consumer`;
    static readonly _markerIconDefault : any = { default : `${ListingMapMarker._path}/unviewed-basic-pin.png`, favorite: `${ListingMapMarker._path}/unviewed-like-pin.png` };
    static readonly _markerIconSelected : any = { default: `${ListingMapMarker._path}/focus-basic-pin.png`, favorite: `${ListingMapMarker._path}/focus-like-pin.png` };
    static readonly _markerIconHighlighted : any = { default: `${ListingMapMarker._path}/hover-basic-pin.png`, favorite: `${ListingMapMarker._path}/hover-like-pin.png` };
    static readonly _markerIconViewed : any = { default: `${ListingMapMarker._path}/seen-basic-pin.png`, favorite: `${ListingMapMarker._path}/seen-like-pin.png` };
    static readonly _markerScaledSize : any = new google.maps.Size(40,24);
    static readonly _labelColor : string = "white";
    static readonly _labelPlacement : any = { x: 20, y: 11 };
    static readonly _labelFontSize : string = "10";
    public id : string;

    iconType : string = "default";

    constructor(map: any, lat: any, lng: any, iconType : any, price: any, pdid : any, address: string, zIndex? : number)  {
        super({
            map: map,
            position: { lat: lat, lng: lng },
            // title: address, // NWMLS whined about this, so we can't show it now.
        });

        this.id = pdid;
        price = ListingMapMarker.getDisplayPrice(price);
        this.setLabel({ color: ListingMapMarker._labelColor, fontSize: ListingMapMarker._labelFontSize, text : price});
        this.setIcon({ url : ListingMapMarker._markerIconDefault[iconType],
            scaledSize: ListingMapMarker._markerScaledSize,
            labelOrigin: new google.maps.Point(ListingMapMarker._labelPlacement["x"], ListingMapMarker._labelPlacement["y"])
        });
        if(!zIndex) { zIndex = 1; }
        this.setZIndex(zIndex);
        this.iconType = iconType;
        
    }

    public static getAlternateIcon(iconUrl : string) : string {
        let returnUrl : string = "";
        if(iconUrl === this._markerIconDefault["default"] || iconUrl === this._markerIconDefault["favorite"]) {
            returnUrl = iconUrl === this._markerIconDefault["default"] ? this._markerIconDefault["favorite"] : this._markerIconDefault["default"];
        }
        else if (iconUrl === this._markerIconHighlighted["default"] || iconUrl === this._markerIconHighlighted["favorite"]) {
            returnUrl = iconUrl === this._markerIconHighlighted["default"] ? this._markerIconHighlighted["favorite"] : this._markerIconHighlighted["default"];
        }
        else if(iconUrl === this._markerIconSelected["default"] || iconUrl === this._markerIconSelected["favorite"]) {
            returnUrl = iconUrl === this._markerIconSelected["default"] ? this._markerIconSelected["favorite"] : this._markerIconSelected["default"];
        }
        else if(iconUrl === this._markerIconViewed["default"] || iconUrl === this._markerIconViewed["favorite"]) {
            returnUrl = iconUrl === this._markerIconViewed["default"] ? this._markerIconViewed["favorite"] : this._markerIconViewed["default"];
        }
        return returnUrl;
    }

    private static getDisplayPrice(price: string) : string {
        let priceValue : number = parseInt(price);
        let priceString : string = price.toString();
        if(priceValue >= 1000000)
        {
            let tempPrice = priceString.substr(0, priceString.length - 5);
            let beforeDecimal = tempPrice.substr(0, tempPrice.length - 1);
            let afterDecimal = tempPrice.substr(tempPrice.length -1, 1);
            if(afterDecimal !== "0") {
                tempPrice = beforeDecimal + "." + afterDecimal;
            } else {
                tempPrice = beforeDecimal;
            }
            priceString = tempPrice + "M";
        }
        else if(priceValue < 1000000 && priceValue > 999) {
            priceString = priceString.substr(0, priceString.length - 3) + "K";
        }
        else {
            priceString = "< 1K";
        }
        return priceString;
    }
}