﻿import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

export class ListingPaginationComponent {

    viewAllListings: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("view-all-listings-btn");

    constructor() {
        this.viewAllListings.addEventListener("click", () => {
            GoogleAnalyticsEventLogger.log('Featured Listings', 'view_all_listings');
        }); 
    }
}

if (document.getElementById("listing-pagination-component")) {
    var listingPaginationComponent = new ListingPaginationComponent();
}
