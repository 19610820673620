﻿import { InputValidation } from '../tools/InputValidation';
import { UpdateGeneralSettingsRequest } from '../Models/UpdateGeneralSettingsRequest';

declare var toastr: any;

export class LeadGeneralSettingsComponent {
    
    fullNameInput: HTMLInputElement;
    emailInput: HTMLInputElement;
    phoneInput: HTMLInputElement;
    passwordInput: HTMLInputElement;

    fullNameValidation: HTMLElement;
    emailValidation: HTMLElement;
    phoneValidation: HTMLElement;
    passwordValidation: HTMLElement;
    formValidation: HTMLElement;
    validationMessage: HTMLElement;

    form: HTMLFormElement;

    constructor(formName: string) {
        this.form = <HTMLFormElement>document.getElementById(formName);
        this.fullNameInput = <HTMLInputElement>document.getElementById("full-name");
        this.emailInput = <HTMLInputElement>document.getElementById("email");
        this.phoneInput = <HTMLInputElement>document.getElementById("phone");
        this.passwordInput = <HTMLInputElement>document.getElementById("password");

        this.fullNameValidation = <HTMLElement>document.getElementById("full-name-validation");
        this.emailValidation = <HTMLElement>document.getElementById("email-validation");
        this.phoneValidation = <HTMLElement>document.getElementById("phone-validation");
        this.passwordValidation = <HTMLElement>document.getElementById("password-validation");
        this.formValidation = <HTMLElement>document.getElementById("form-validation");
    }
}

if (document.getElementById("lead-general-settings-update")) {
    let leadGeneralSettingsUpdate = new LeadGeneralSettingsComponent("general-settings-update-form");
}
