﻿import { CookieManager } from "../tools/CookieManager";
import { URLReader } from "../tools/URLReader";
declare var CINC: any;

export class PropertyDetailsViewModel {
    propertyInput : string;
    constructor() {
        this.propertyInput = URLReader.ParsePath()[6];//the pdid should always be the 6th on this page
        CookieManager.updateViewedPropertiesCookie(this.propertyInput);
    }
}

if(document.getElementById("property-id")) {
    let propertyDetailsVM = new PropertyDetailsViewModel();    
}