﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
import { CINCListing } from '../Models/CINCListing';
import { SearchListingsDataStore } from '../search/SearchListingsDataStore';
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

export class FiltersToolbarComponent {
    viewListingsAnchor: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("viewListings");

    constructor() {
        document.addEventListener(SearchPageBroadcast.searchRequestInitiatedEventName, () => { this.searchRequestInitiated(); });
        document.addEventListener(SearchPageBroadcast.searchRequestLoadedEventName, () => { this.searchRequestLoaded(); });
        document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, (ev: CustomEvent) => {
            this.handleSearchResponse(ev.detail["response"]);
        });
        if (this.viewListingsAnchor) {
            this.viewListingsAnchor.addEventListener("click", () => {
               GoogleAnalyticsEventLogger.log('Search Homes', 'view_listings_filtered');
            });
        }
    }

    handleSearchResponse(response: any) {
        let properties = new Array<CINCListing>();
        if (response.count !== "0") {
            document.getElementById('viewListings').innerHTML = "View " + response.count + " Listings";
            response.results.forEach((property: any) => {
                properties.push(<CINCListing>({
                    Address: property.fullAddress,
                    PDID: property.pdid,
                    Price: Number(property.price),
                    Lat: Number(property.latitude),
                    Lng: Number(property.longitude),
                    CanShowAddress: Boolean(property.canShowAddress),
                    IsFavorite: Boolean(property.isFavorite)
                }));
            });
        } else {
            document.getElementById('viewListings').innerHTML = "No results found";
        }
        SearchListingsDataStore.UpdateListingsFromObject(properties);
    }

    searchRequestInitiated(): void  {
        document.getElementById("viewListingsRegion").hidden = true;
        document.getElementById("listingsLoadingRegion").hidden = false;

        if (!document.getElementById("viewListingsRegion").hidden) {
            document.getElementById("viewListingsLoader").style["maxWidth"] =
                document.getElementById("viewListings").offsetWidth.toString() + "px";
            document.getElementById("viewListingsLoader").style["maxHeight"] =
                document.getElementById("viewListings").offsetHeight.toString() + "px";
        }
    }

    searchRequestLoaded(): void {
        document.getElementById("viewListingsRegion").hidden = false;
        document.getElementById("listingsLoadingRegion").hidden = true;
    }
}

if (document.getElementById("filter-toolbar-component")) {
    var filterToolbarComponent = new FiltersToolbarComponent();
}