import { ValidationContainer } from "../models/ValidationContainer";

export class InputValidation {

    public static ValidateEmail(email: string): boolean {
        /*There is no perfect solution for email address veification but this should cover 99% of them
         * We can update this as more variations come to light that we want to cover to be a good validation
         * this should cover most normal layouts and allows for a plus "+" to be included.
         * https://www.regular-expressions.info/email.html 
         */
        var valid = (/[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(email.toLowerCase()));
        return valid;
    }

    public static ValidatePhone(phone : string) : boolean {
        if ((phone === "") || (phone === null)){return false};

        phone = phone.replace(/-/g, '');

        var reg = /^\$?[0-9.,]+$/;
        var phoneNumberJustDigits = reg.test(phone);
        if (!phoneNumberJustDigits) { return false }

        var phoneLength = phone.length;
        var phoneFirstDigit = phone[0];

        var returningVal = phoneLength === 10 || (phoneLength === 11 && phoneFirstDigit == '1');

        return returningVal;
    }

    public static ValidateInputTextExist(inputElement: HTMLInputElement) : boolean {
        return (!(inputElement.value === "" || inputElement.value === null));
    }

    public static MinMaxInputValidation(minInput: HTMLInputElement, maxInput: HTMLInputElement) : ValidationContainer
    {
        let returnValidationContainer = new ValidationContainer();
        returnValidationContainer.IsValid = true;
        let minValue = parseFloat(minInput.value.replace(/,/g ,"").replace("$",""));
        let maxValue = parseFloat(maxInput.value.replace(/,/g,"").replace("$",""));
        if(typeof minValue === "number" && typeof maxValue === "number") {
            if(minValue > maxValue) {
                returnValidationContainer.IsValid = false;
                returnValidationContainer.Message = "The minimum value is greater than the maximum value";
            }
        }
        return returnValidationContainer;
    }

    public static ValidateNumericalInput(inputElement: HTMLInputElement): ValidationContainer
    {
        let isNumericalValidationContainer = new ValidationContainer();
        isNumericalValidationContainer.IsValid = true;
        var reg = /^\$?[0-9.,]+$/;
        if (!reg.test(inputElement.value.replace(",", "").replace("$", "")) && !(inputElement.value === "")){
            isNumericalValidationContainer.IsValid = false;
            isNumericalValidationContainer.Message = "Please input a numerical value";
        }
        return isNumericalValidationContainer;
    }
}