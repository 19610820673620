﻿export class RegistrationContext {
    private PageViewCount: number;

    public CurrentUserSecurityLevel: number;
    public AllowableFreeViews: number;

    public isDismissable(): boolean {
        // do the logic of all the settings in the backend to determine if registration is dismissable
        return false;
    }

    // Determines if the user will be force to register
    public shouldShow(): boolean {
        // page specification - showing the reg modal on search page 
        // mobilehideviews
        // Delay the registration by 1,2,3,4,5,10,20 page views 
        return true;
    }

    public increasePageViewCount(): void {

    }
} 