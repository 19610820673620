﻿export class TextBoxFormatter {
    static AddDollarSignAndCommas(val: string) {
        val = TextBoxFormatter.AddCommasToMoney(val);
        return TextBoxFormatter.AddDollarSign(val);
    }
    static RemoveDollarSignAndCommas(val: string) {
        val = TextBoxFormatter.RemoveDollarSignFromMoney(val);
        return TextBoxFormatter.RemoveCommasFromMoney(val);
    }
    static AddCommasToMoney(val: string): string {
        if (val.length > 3) {
            let requiredCommaCount = Math.floor(val.length / 3);
            for (let i = 1; i <= requiredCommaCount; i++) {
                let frontOfComma = val.substr(0, val.length - (3 * i) - (i - 1));
                if (frontOfComma !== '') {
                    let backOfComma = val.substr(val.length - (3 * i) - (i - 1));
                    val = `${frontOfComma},${backOfComma}`;
                }
            }
        }
        return val;
    }
    static RemoveCommasFromMoney(val: string): string {
        return val.replace(/,/g, ``);
    }
    static AddDollarSign(val: string): string {
        return `$${val}`;
    }
    static RemoveDollarSignFromMoney(val: string): string {
        return val.replace(`$`, ``);
    }
    static AddPercentSign(val: string): string {
        return `${val}%`
    }
    static RemovePercentSign(val: string) {
        return val.replace(/%/g, ``);
    }
}