﻿import { ListingFavoriteUpdater } from '../listing-favorites/ListingFavoriteUpdater';
import { SearchPageBroadcast } from './SearchPageBroadcast';
declare var CINC: any;
declare var toastr: any;

export class SearchListing {

    listingFavorites : HTMLCollectionOf<HTMLAnchorElement>;
    public ListingCards : HTMLCollectionOf<HTMLDivElement>;

    eventLauncher : CustomEvent;

    constructor() {
        this.listingFavorites = <HTMLCollectionOf<HTMLAnchorElement>>document.getElementsByClassName("btn--fav");
        this.ListingCards = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName("card--property2");
        this.setFavoriteEvents();
        this.setMouseEvents();

        toastr.options = {
                "positionClass": "toast-top-full-width"
        };

        document.addEventListener(SearchPageBroadcast.listingResultsLoadedEventName, () => {
            this.listingFavorites = <HTMLCollectionOf<HTMLAnchorElement>>document.getElementsByClassName("btn--fav");
            this.ListingCards = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName("card--property2");
            this.setMouseEvents();
            this.setFavoriteEvents();
        });

        document.addEventListener(SearchPageBroadcast.favoritedSelectedPropertyEventName, (ev: CustomEvent) => {
            let pdid: string = ev.detail["pdid"];
            for (let ind = 0; ind < this.listingFavorites.length; ind++) {
                if (this.listingFavorites.item(ind).dataset["pdid"] === pdid) {
                    let listingFavorite = this.listingFavorites.item(ind);
                    if (listingFavorite.classList.contains("favd")) {
                        listingFavorite.classList.remove("favd");
                        listingFavorite.dataset["isfavorite"] = "False";
                    }
                    else {
                        listingFavorite.classList.add("favd");
                        listingFavorite.dataset["isfavorite"] = "True";
                    }
                }
            }
        });
    }

    setMouseEvents() {
        let lisingLength : number = this.ListingCards.length;
        for(let ind = 0; ind < lisingLength; ind++) {
            let listingCard : HTMLDivElement = this.ListingCards.item(ind);
            listingCard.addEventListener("mouseenter", (ev) => {
                let element : HTMLDivElement = <HTMLDivElement>ev.srcElement;
                if (element) {
                    let pdid = element.dataset["pdid"];
                    let isFavorite: boolean = false;
                    if (element.dataset["isfavorite"]) {
                        isFavorite = (element.dataset["isfavorite"].toLowerCase() === "true")
                    }
                    SearchPageBroadcast.HoveredListing(pdid, isFavorite);
                }
            });
            listingCard.addEventListener("mouseleave", (ev) => {
                let element: HTMLDivElement = <HTMLDivElement>ev.srcElement;
                if (element) {
                    let pdid = element.dataset["pdid"];
                    SearchPageBroadcast.UnhoveredListing(pdid);
                }
            });
        }
    }

    setFavoriteEvents() {
        let listingLength : number  = this.listingFavorites.length;
        for(let ind = 0; ind < listingLength; ind++) {
            let favoriteLink : HTMLAnchorElement = this.listingFavorites.item(ind);
            favoriteLink.addEventListener("click", (ev) => {
                if(!CINC.User) {
                    CINC.Core.PromptForSignup('', true);
                    return;
                }
                
                let element : HTMLAnchorElement = <HTMLAnchorElement>ev.srcElement;
                let pdid : string = element.dataset["pdid"];
                let isFavorite : boolean = (element.dataset["isfavorite"].toLowerCase() === 'true')

                ListingFavoriteUpdater.UpdateFavorite(pdid, isFavorite, element, this.updateFavoriteIcon);
                SearchPageBroadcast.FavoritedListing(pdid);
            });
        }
    }

    updateFavoriteIcon(xhttp: XMLHttpRequest, isFavorite : boolean, element :HTMLElement) {
        if (xhttp.status === 200) {
            if (!isFavorite) {
                element.classList.add("favd");
                toastr.success("Your favorite listing was saved successfully!");
            } else {
                element.classList.remove("favd");
                toastr.success("Listing was removed from your favorites.");
            }
            element.dataset["isfavorite"] = (!isFavorite).toString();
            element.parentElement.parentElement.parentElement.dataset["isfavorite"] = (!isFavorite).toString();
            
        } else {
            toastr.error("Sorry, we were unable to save your favorite listing.");
        }
    }
}
