﻿export class MortgageCalculator {
    static PrincipalToMortgagePayment(principal: number, terms: number, interestRate: number) : number {
        let annualInterestRate: number = interestRate / 12 / 100;
        let numerator = Math.pow((1 + annualInterestRate), terms) * annualInterestRate;
        let denominator = Math.pow((1 + annualInterestRate), terms) - 1;
        let dec = numerator / denominator;
        let monthlyMortgagePayment = principal * dec;
        return monthlyMortgagePayment;
    }
    static RoundMoney(amount: number) {
        return (Math.round(amount * 100)) / 100;
    }
}




