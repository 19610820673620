﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';

export class MobileSearchActionsComponent {
    mobileFilterButton: HTMLElement = document.getElementById("searchFilterMobileButton");
    mobileMapListingsButton: HTMLElement = document.getElementById("searchMapMobileButton");

    constructor() {
        let mapHTML = '<i class="icon-map"></i> Map';
        let listHTML = '<i class="icon-more"></i> List';

        if (this.mobileFilterButton) {
            this.mobileFilterButton.addEventListener("click", () => SearchPageBroadcast.DisplayFilters());
            document.addEventListener(SearchPageBroadcast.showSelectedMobileListingsViewEventName, () => {
                if (this.mobileMapListingsButton.innerHTML === mapHTML) {
                    SearchPageBroadcast.DisplayMobileListings();
                } else {
                    SearchPageBroadcast.DisplayMobileMap();
                }
            });
        }
        if (this.mobileMapListingsButton) {
            this.mobileMapListingsButton.addEventListener("click", () => {
                if (this.mobileMapListingsButton.innerHTML === mapHTML) {
                    this.mobileMapListingsButton.innerHTML = listHTML;
                    SearchPageBroadcast.DisplayMobileMap();
                } else {
                    this.mobileMapListingsButton.innerHTML = mapHTML;
                    SearchPageBroadcast.DisplayMobileListings();
                }
            });
        }
    }
}

if (document.getElementById("MobileSearchActionsComponent")) {
    var mobileSearchActions = new MobileSearchActionsComponent()
}