import { DomainEventBus } from '../events/DomainEventBus';
import { LenderSignupBannerClickEvent } from '../events/LenderSignupBannerClickEvent';

declare var PromptForSignup: any;
declare var CINC: any;

export class LenderSignupComponentViewModel {
    readonly BANNER_ID = 'houses-signup-banner';
    readonly BUTTON_ID = 'signup-banner-btn';
    readonly BANNER_ENDPOINT = '/api/lenderplatform/bannerclick';

    signupButton: HTMLButtonElement;
    signupBanner: HTMLDivElement;
    user: any;

    constructor() {
        this.signupBanner = <HTMLDivElement>document.getElementById(this.BANNER_ID);
        this.signupButton = <HTMLButtonElement>document.getElementById(this.BUTTON_ID);
        this.user = CINC.User;

        
        if (typeof this.user !== 'undefined' && this.user != null) {
            this.signupBanner.style.display = 'none';
        } else {
            if (typeof this.signupButton !== 'undefined' && this.signupButton != null) {
                this.signupButton.addEventListener('click', (e: MouseEvent) => this.handleButtonClick(e));
            }
        }
    }

    handleButtonClick(e: MouseEvent): void {
        DomainEventBus.raise(new LenderSignupBannerClickEvent());
        PromptForSignup('', false);
    }
}

if (document.getElementById('houses-signup-banner')) {
    let lenderSignupComponentVM = new LenderSignupComponentViewModel();
}
