import { LeadGeneralSettingsComponent } from '../components/LeadGeneralSettingsComponent';
import { LeadCommunicationSettingsComponent } from '../components/LeadCommunicationSettingsComponent';
import { InputValidation } from '../tools/InputValidation';
import { UpdateGeneralSettingsRequest } from '../Models/UpdateGeneralSettingsRequest';
declare var toastr: any;

export class LeadSettingsSaveComponent {
    saveButton: HTMLButtonElement;

    constructor() {
        this.saveButton = <HTMLButtonElement>document.getElementById('save-button');

        this.saveButton.addEventListener('click', (e) => this.handleChanges(e));
        
    }

    handleChanges(e: Event) {
        e.preventDefault();
        let leadGeneral = new LeadGeneralSettingsComponent("lead-general-settings-update");
        let leadComm = new LeadCommunicationSettingsComponent("lead-comm-settings-update");
        let name = leadGeneral.fullNameInput.value.split(" ", 2);
        
        let settingsUpdateRequest = new UpdateGeneralSettingsRequest();
        settingsUpdateRequest.FirstName = name[0];
        settingsUpdateRequest.LastName = name[1];
        settingsUpdateRequest.Email = leadGeneral.emailInput.value;
        if (leadGeneral.passwordInput.value !== null || leadGeneral.passwordInput.value !== "") {
            settingsUpdateRequest.Password = leadGeneral.passwordInput.value;
        }
        settingsUpdateRequest.CellPhone = leadGeneral.phoneInput.value;
        leadComm.emailSubInput.checked ? settingsUpdateRequest.UnsubscribeEmail = false : settingsUpdateRequest.UnsubscribeEmail = true;
        leadComm.textSubInput.checked ? settingsUpdateRequest.UnsubscribeText = false : settingsUpdateRequest.UnsubscribeText = true;
        leadComm.propAlertInput.checked ? settingsUpdateRequest.On_PropertyAlerts = true : settingsUpdateRequest.On_PropertyAlerts = false;
        leadComm.propFavInput.checked ? settingsUpdateRequest.On_PropertyUpdates = true : settingsUpdateRequest.On_PropertyUpdates = false;
        if (this.checkValidation(leadGeneral)) {
            this.sendRequest(settingsUpdateRequest);
        }   
    }

    sendRequest(updateRequest: any) {
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url = `/api/account/update`;
        xhttp.addEventListener('load', () => this.handleRequestLoad(xhttp));
        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(updateRequest));
        toastr.info("Saving...");
    }

    handleRequestLoad(request: XMLHttpRequest) {
        if (request.status === 200) { 
            toastr.success("Settings Saved.");
        }
        else {
            this.handleRequestError(request);
        }
     }
 
     handleRequestError(request: XMLHttpRequest) {
         let response = JSON.parse(request.response);
         toastr.error(response);
     }
 
     checkValidation(leadGeneral: any): boolean {
         let retValue: boolean = true;
 
         retValue = this.validateInput(leadGeneral.fullNameInput, leadGeneral.fullNameValidation, "Name is required.", InputValidation.ValidateInputTextExist, "Please enter a valid Name") ? retValue : false;
         retValue = this.validateInput(leadGeneral.phoneInput, leadGeneral.phoneValidation, "Phone Number is required.", InputValidation.ValidatePhone, "Please enter a valid Phone Number") ? retValue : false;
         retValue = this.validateInput(leadGeneral.emailInput, leadGeneral.emailValidation, "Email Address is required.", InputValidation.ValidateEmail, "Please enter a valid Email Address") ? retValue : false;
         
         return retValue;
     }
 
     validateInput(input: HTMLInputElement, validation: HTMLElement, requiredMessage: string, validationFunc: Function, validationMessage: string): boolean {
         let retValue: boolean = true;
         if (!InputValidation.ValidateInputTextExist(input)) {
             toastr.error(requiredMessage);
             retValue = false;
         }
         else {
             if (((validationFunc) && validationFunc(input.value)) || !(validationFunc)) { 
                 
             }
             else {
                 toastr.error(validationMessage);
                 retValue = false;
             }
         }
         return retValue;
    }
}

if (document.getElementById("lead-general-settings-update") || document.getElementById("lead-comm-settings-update")) {
    let leadSettingsSave = new LeadSettingsSaveComponent();
}
