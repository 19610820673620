﻿import { InputValidation } from "../tools/InputValidation";
import { ValidationContainer } from "../models/ValidationContainer";
import { SearchService } from '../search/SearchService';
import { DomainEventBus } from '../events/DomainEventBus';
import { AdvancedFiltersClickEvent } from '../search/AdvancedFiltersClickEvent';
import { SearchLocationTag } from '../search/SearchLocationTag';
import { SearchLocationTagContainer } from '../search/SearchLocationTagContainer';
import { CookieManager } from "../tools/CookieManager";
import { SliderInput } from '../search/SliderInput';
import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';


export class MainFiltersComponent {
    filterInputs: HTMLCollectionOf<HTMLInputElement> = <HTMLCollectionOf<HTMLInputElement>>document.getElementsByClassName("filter-input");
    filterArray: HTMLInputElement[] = [];
    validationElements: HTMLCollectionOf<HTMLElement> = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("validation-message");
    saveSearchAnchor: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("saveSearch");
    saveSearchDisabled: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("saveSearchDisabled");
    viewListingsAnchor: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("viewListings");
    viewListingsDisabled: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("viewListingsDisabled");
    virtualTourCheckbox: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("virtual-tour");
    listingTypeElements: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.getElementsByName("listingtype");   
    isFilterFormValid: boolean = true;

    readonly _invalidClass: string = "error";

    constructor() {
        this.buildFilterArray();
        this.initInputValidatorEventHandlers();
        this.removeInitialPolygonmapFromFilters();
        this.initSliderEvents();

        //if on search page
        if (document.getElementById("location-input-container-initial").getElementsByClassName("location-input").length > 0) {
            this.createInitialLocationTagsAndInputs();
        }

        let filtersForm: HTMLFormElement = <HTMLFormElement>document.getElementById("filters-form");
        filtersForm.addEventListener("change", (ev) => this.handleFilterFormChange(ev));

        let advancedFilters = document.getElementById("advancedFilters");
        if (advancedFilters) {
            advancedFilters.addEventListener("click", () => {
                var domainEvent = new AdvancedFiltersClickEvent();
                DomainEventBus.raise(domainEvent);
            });
        }

        if (this.virtualTourCheckbox) {
            this.virtualTourCheckbox.addEventListener("click", () => {
                GoogleAnalyticsEventLogger.log('Search Homes', 'virtual_tour_filter');             
            });
        }

        let validationLength = this.listingTypeElements.length;
        for (let index = 0; index < validationLength; index++) {
            this.listingTypeElements.item(index).addEventListener("click", () => {
                GoogleAnalyticsEventLogger.log('Search Homes', 'property_type_filter');
            });
        }

        document.addEventListener(SearchPageBroadcast.resetFiltersEventName, () => { this.resetFilters(); });
        document.addEventListener(SearchPageBroadcast.clearPolygonMapEventName, () => { this.clearPolygonMapInput(); });
        document.addEventListener(SearchPageBroadcast.updatePolygonMapEventName, (ev: CustomEvent) => { this.updatePolygonMapInput(ev.detail); });
    }

    createInitialLocationTagsAndInputs() {
        let locations = document.getElementById("location-input-container-initial").getElementsByTagName("input");
        for (let ind = 0; ind < locations.length; ind++) {
            let location = locations.item(ind);
            let tagValues: string[];
            // CONSUMER-TODO: A better solution needs to be thought out for this if condition
            if (location.name.indexOf("acl", 0) !== -1 && location.name.indexOf("school", location.name.length - "school".length) === -1 && location.name !== 'acl_zip5') {
                // locations have commas seperating the location and state but the values are also comma delimited
                tagValues = location.value.split(",");
                let locationTagValues: string[] = new Array();
                tagValues.forEach((val, ind, arr) => {
                    if (ind % 2 === 0) {
                        var tagVal = arr[ind + 1] || '';
                        locationTagValues.push(`${val.trim()}, ${tagVal.trim()}`);
                    }
                });
                tagValues = locationTagValues;
            }
            else {
                tagValues = location.value.split(",");
            }

            let searchLocationTag: SearchLocationTag = new SearchLocationTag(location.name, location.value);
            //inputs have an instance for each type (just one)
            SearchLocationTagContainer.CreateLocationInput(searchLocationTag);
            //tags have an instance for each value per type (multiple)
            tagValues.forEach((val, ind, arr) => {
                searchLocationTag.Value = val;
                SearchLocationTagContainer.CreateLocationTag(searchLocationTag);
            });

            if (tagValues.length > 0) {
                let searchBarInput = <HTMLInputElement>document.getElementById("search-bar-input");
                SearchLocationTagContainer._originalSearchBarText = searchBarInput.placeholder;
                searchBarInput.placeholder = "";
            }
        }
    }

    initSliderEvents() {
        let bedSlider = new SliderInput("bedslider", 0, 5, "slider-bed-label", "minbeds", "maxbeds");
        let bathSlider = new SliderInput("bathslider", 0, 5, "slider-bath-label", "minbaths", "maxbaths");

        if (bedSlider && bedSlider.slider) {
            bedSlider.slider.on('change', () => {
                SearchService.SearchRequest(SearchService.StrCrit(), false, false);
            });
        }
        if (bathSlider && bathSlider.slider) {
            bathSlider.slider.on('change', () => {
                SearchService.SearchRequest(SearchService.StrCrit(), false, false);
            });
        }
    }

    removeInitialPolygonmapFromFilters() {
        if (document.getElementById("polygonmap")) {
            document.getElementById("polygonmap").parentNode.removeChild(document.getElementById("polygonmap"));
        }
    }

    buildFilterArray() {
        let filterLength = this.filterInputs.length;
        for (let filterInd = 0; filterInd < filterLength; filterInd++) {
            this.filterArray.push(this.filterInputs.item(filterInd));
        }
    }

    initInputValidatorEventHandlers() {
        this.filterArray.forEach((input) => {
            input.addEventListener("blur", () => { this.handleInputValidator(input) });
        });
    }

    handleInputValidator(input: HTMLInputElement) {
        //check to make sure inputs only contain numerical values 0-9
        let validation: ValidationContainer = InputValidation.ValidateNumericalInput(input);
        let validationElement: HTMLElement;

        if (validation.IsValid === false) {
            this.isFilterFormValid = false;
            let inputType = input.dataset["type"];
            let elementID = inputType + "-validation";
            input.classList.add(this._invalidClass);
            validationElement = document.getElementById(elementID);
            validationElement.innerText = validation.Message;
        }
        else {

            //filter out the min/max fields and validate them
            let inputType = input.dataset["type"];
            let minInput: HTMLInputElement = this.filterArray.filter((filter) => {
                return filter.dataset["type"] === inputType && filter.dataset["extreme"] === "min";
            })[0];
            let maxInput: HTMLInputElement = this.filterArray.filter((filter) => {
                return filter.dataset["type"] === inputType && filter.dataset["extreme"] === "max";
            })[0];
            validation = InputValidation.MinMaxInputValidation(minInput, maxInput);
            validationElement = document.getElementById(minInput.dataset["validation"]);

            if (validation.IsValid === false) {
                this.isFilterFormValid = false;
                minInput.classList.add(this._invalidClass);
                maxInput.classList.add(this._invalidClass);
                validationElement.innerText = validation.Message;
            } else {
                minInput.classList.remove(this._invalidClass);
                maxInput.classList.remove(this._invalidClass);
                validationElement.innerText = "";
            }
        }

        this.checkValidation();
    }

    checkValidation() {
        this.isFilterFormValid = true;
        let validationLength = this.validationElements.length;
        for (let validInd = 0; validInd < validationLength; validInd++) {
            if (this.validationElements.item(validInd).innerText !== "") {
                this.isFilterFormValid = false;
                break;
            }
        }

        if (this.isFilterFormValid) {
            this.viewListingsAnchor.style.display = "inline-block";
            this.viewListingsDisabled.style.display = "none";
            if (this.saveSearchAnchor) { this.saveSearchAnchor.style.display = "inline-block"; }
            this.saveSearchDisabled.style.display = "none";
        }
        else {
            this.viewListingsAnchor.style.display = "none";
            this.viewListingsDisabled.style.display = "inline-block";
            if (this.saveSearchAnchor) { this.saveSearchAnchor.style.display = "none"; }
            this.saveSearchDisabled.style.display = "inline-block";
        }
    }

    handleFilterFormChange(e: Event) {
        let input = <HTMLInputElement>e.target;
        let inputName = input.name;
        let inputType = input.type;

        if (SearchService.preventSearchEvents) {
            return;
        }

        if (inputType === 'text'
            && !input.classList.contains("search")) //filters out 3rd party input created by searchable select dropdown. Dont love this fix, but I dont see a quick way to add a special class to the created input
        {
            this.handleInputValidator(input);
        }

        if (this.isFilterFormValid && inputName !== undefined && inputName !== '') {
            let pageInput = <HTMLInputElement>document.getElementById("page");
            pageInput.value = "1"; // reset to page one of results for a filter change
            SearchService.SearchRequest(SearchService.StrCrit(), false, true);
        }
    }

    private clearLocationInputs() {
        document.getElementById("location-inputs").innerHTML = "";
    }

    private clearPolygonMapInput() {
        if (document.getElementById("polygonmapInput")) {
            document.getElementById("polygonmapInput").parentNode.removeChild(document.getElementById("polygonmapInput"));
        }
    }

    private updatePolygonMapInput(polygonmap: any) {
        if (!polygonmap || !polygonmap.input || polygonmap.input === "") {
            return;
        }
        
        this.clearLocationInputs();
        let polygonmapInput: HTMLInputElement = document.createElement("input");
        polygonmapInput.name = "polygonmap";
        polygonmapInput.value = polygonmap.input;
        polygonmapInput.id = "polygonmapInput";
        polygonmapInput.type = "hidden";
        document.getElementById("location-inputs").insertAdjacentElement("beforeend", polygonmapInput);
    }

    public static UpdateSortbyInput(sortby: string) {
        let sortbyInput: HTMLInputElement = <HTMLInputElement>document.getElementById("sortbyInput");
        if (sortbyInput) {
            sortbyInput.value = sortby;
        }
    }

    resetFilters() {
        let filtersForm = <HTMLFormElement>document.getElementById("filters-form");

        SearchService.preventSearchEvents = true;
        filtersForm.reset();
        CookieManager.deleteCookie("laststrcrit");
        SearchLocationTagContainer.RemoveAllLocationTags();
        if (document.getElementById("location-tag-more")) {
            document.getElementById("location-tag-more").parentNode.removeChild(document.getElementById("location-tag-more"));
        }
        let $filterInputs = $("#filters-form").find("input");
        for (let filterIndex = 0; filterIndex < $filterInputs.length; filterIndex++) {
            let filterElement = filtersForm.elements[filterIndex];
            if (filterElement.getAttribute("type") === "checkbox") {
                filterElement.removeAttribute("checked");
            } else {
                filterElement.removeAttribute("value");
            }
        }

        let bathSlider: noUiSlider.Instance = document.getElementById("bathslider") as noUiSlider.Instance;
        let bedSlider: noUiSlider.Instance = document.getElementById("bedslider") as noUiSlider.Instance;
        let mobileBathSlider: noUiSlider.Instance = document.getElementById("quick-filters-bath-slider") as noUiSlider.Instance;
        let mobileBedSlider: noUiSlider.Instance = document.getElementById("quick-filters-bed-slider") as noUiSlider.Instance;

        if (bathSlider) { bathSlider.noUiSlider.set([0, 5]);}
        if (bedSlider) { bedSlider.noUiSlider.set([0, 5]); }
        if (typeof mobileBathSlider !== "undefined" && mobileBathSlider !== null) { mobileBathSlider.noUiSlider.set([0, 5]); }
        if (typeof mobileBedSlider !== "undefined" && mobileBedSlider !== null) { mobileBedSlider.noUiSlider.set([0, 5]); }
        SearchService.preventSearchEvents = false;
        SearchService.SearchRequest({ "resetfilters": 1 }, true, true);
    }
}

if (document.getElementById("filters-panel")) {
    let mainFiltersComponent = new MainFiltersComponent();
}