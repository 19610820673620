﻿import { SearchLocationTag } from "./SearchLocationTag";
import { SearchService } from "./SearchService";
import {BrowserDetector} from "../tools/BrowserDetector";
import { SearchPageBroadcast } from "../search/SearchPageBroadcast";

export class SearchLocationTagContainer {
    static _visualContainer : HTMLDivElement = <HTMLDivElement>document.getElementById("location-tags");
    static _inputContainer: HTMLDivElement = <HTMLDivElement>document.getElementById("location-inputs");
    static _searchBarInput: HTMLInputElement = <HTMLInputElement>document.getElementById("search-bar-input");
    static _originalSearchBarText: string = "";
    private static _tagIndex : number = 0;
    private static _inputIndex: number = 0;
    private static _locationTagRemovalLimit: number = 1;
    private static _locationTagDisplayMax: number = 2;

    private static tags: HTMLElement[] = new Array<HTMLElement>();

    public static Add(tag : SearchLocationTag) {
        if(this._inputContainer) {
            let createNewInput : boolean = true;

            let locationTags = this._visualContainer.getElementsByClassName("location-tag");
            if(this._inputContainer.getElementsByClassName("location-input")) {
                if (locationTags.length === 0) {
                    this._visualContainer.innerHTML = "";
                    this._originalSearchBarText = this._searchBarInput.placeholder;
                    this._searchBarInput.placeholder = "";
                }
                let locationInputs = this._inputContainer.getElementsByClassName("location-input");
                if ((typeof (locationInputs) != 'undefined' && locationInputs != null)) {
                    for (let ind = 0; ind < locationInputs.length; ind++) {
                        let locationInput: HTMLInputElement = <HTMLInputElement>locationInputs[ind];
                        if (locationInput.name === tag.Type) {
                            createNewInput = false;
                            //removes spaces and checks if value is already in specific location input value
                            if (locationInput.value.replace(/ /g, '').indexOf(tag.Value.replace(/ /g, '')) < 0) {
                                locationInput.value += `, ${tag.Value}`;
                                this.CreateLocationTag(tag, locationInput.id);
                                break;
                            }
                        }
                    }
                }
            }
            if(createNewInput) {
                this._inputIndex++;
                this.CreateLocationInput(tag);
                this.CreateLocationTag(tag);
            }
        }
    }

    public static RemoveLast() {
        if (this.tags.length > 0) {
            var removeTag: HTMLElement = this.tags[this.tags.length - 1];
            this.tags.pop();
            SearchLocationTagContainer.removeTag(removeTag);
        }
    }


    public static DisableTagClick() {
        if (!this._visualContainer) return;

        this.SetLocationTagRemovalLimit();

        let locationTags = this._visualContainer.getElementsByClassName("location-tag");
        if ((typeof (locationTags) != 'undefined' && locationTags != null)) {
            for (let ind = 0; ind < locationTags.length; ind++) {
                let locationTag: HTMLElement = <HTMLElement>locationTags[ind];
                locationTag.removeEventListener("click", this.tagClickEvent);
                locationTag.classList.remove("search-tag-close");
                locationTag.classList.add("search-tag");
                if (ind > this._locationTagRemovalLimit) {
                    locationTag.style.display = "none";
                }
            }
            if (locationTags.length > this._locationTagDisplayMax) {
                    this._visualContainer.innerHTML +=
                        `<span id='location-tag-more'>+ ${locationTags.length - this._locationTagDisplayMax} more</span>`;
            }

            if ((typeof (this._searchBarInput) != 'undefined' && this._searchBarInput != null)) {
                this._searchBarInput.style.display = "none";
            }
        }
    }

    public static EnableTagClick() {

        let locationTags = this._visualContainer.getElementsByClassName("location-tag");
        if ((typeof (locationTags) != 'undefined' && locationTags != null)) {
            for (let ind = 0; ind < locationTags.length; ind++) {
                let locationTag: HTMLElement = <HTMLElement>locationTags[ind];
                locationTag.classList.add("search-tag-close");
                locationTag.classList.remove("search-tag");
                locationTag.style.display = "";
            }
        }

        let locationTagMore = document.getElementById("location-tag-more");
        if ((typeof (locationTagMore) != 'undefined' && locationTagMore != null)) {
            locationTagMore.parentNode.removeChild(locationTagMore);
        }

        if ((typeof (this._searchBarInput) != 'undefined' && this._searchBarInput != null)) {
            this._searchBarInput.style.display = "";
            this.setClickEvents();
        }

        SearchPageBroadcast.CloseQuickDetailsView();
        let selectedProperty: HTMLElement = document.getElementById("search-map-selected-prop");
        let selectedPropertyMobile: HTMLElement = document.getElementById("search-map-selected-prop-mobile");
        if (selectedProperty.innerHTML !== "" || selectedPropertyMobile.innerHTML !== "") {
            selectedProperty.innerHTML = "";
            selectedPropertyMobile.innerHTML = "";
        }
    }

    public static CreateLocationInput(tag : SearchLocationTag) {
        let locationInput : HTMLInputElement = <HTMLInputElement>document.createElement("input");
        locationInput.type = "hidden";
        locationInput.id = `location-input-${this._inputIndex}`;
        locationInput.name = tag.Type;
        locationInput.value = tag.Value;
        locationInput.classList.add(`location-input`);
        this._inputContainer.insertAdjacentElement("beforeend", locationInput);
    }

    public static CreateLocationTag(tag : SearchLocationTag, providedInputId? : string) {
        if(this._visualContainer) {
            tag.Id = `location-tag-${this._tagIndex}`;
            if(!providedInputId) {
                tag.InputId = `location-input-${this._inputIndex}`;
            } else {
                tag.InputId = providedInputId;
            }
            this._tagIndex++;
            this._visualContainer.innerHTML += tag.GetHTML();
            this.setClickEvents();
            this.tags.push(document.getElementById(tag.Id));
        }
    }

    private static setClickEvents() {
        let tags : HTMLCollectionOf<Element> = document.getElementsByClassName("search-tag-close");
        for(let ind = 0; ind < tags.length; ind++) {
            tags[ind].addEventListener("click", this.tagClickEvent);
        }
    }

    private static tagClickEvent(ev : Event) {
        let tag: HTMLElement = <HTMLElement>ev.srcElement;
        SearchLocationTagContainer.removeTag(tag);
        if (tag.id == "custom-map-tag") {
            SearchPageBroadcast.ResetFilters();
        }
    }

    private static removeTag(tag : HTMLElement) {
        let removeTagId: string = tag.id;
        let removeInputId: string = tag.dataset["inputid"];
        document.getElementById(removeTagId).parentNode.removeChild(document.getElementById(removeTagId));

        let removeInput: HTMLInputElement = <HTMLInputElement>document.getElementById(removeInputId);
        if (removeInput) {
            if (removeInput.value.indexOf(tag.innerText.trim()) >= 0) {
                removeInput.value = removeInput.value.replace(tag.innerText, "").replace(/,\s*$/, "").trim();

                if (removeInput.value.length > 0) {
                    //addressing extra commas in the input value at the beginning middle and end of value
                    while (removeInput.value.indexOf(",") === 0) {
                        removeInput.value = removeInput.value.substr(1).trim();
                    }
                    while (removeInput.value.indexOf(",") === removeInput.value.length - 1) {
                        removeInput.value = removeInput.value.substr(0, removeInput.value.length - 1).trim();
                    }
                    if (removeInput.value.indexOf(", , ") >= 0) {
                        removeInput.value = removeInput.value.replace(", , ", ", ");
                    }
                }
                else {
                    document.getElementById(removeInputId).parentNode.removeChild(document.getElementById(removeInputId));
                }
            }
            else {
                document.getElementById(removeInputId).parentNode.removeChild(document.getElementById(removeInputId));
            }

            if (document.getElementsByClassName("search-tag-close").length <= 0) {
                let searchBarInput = <HTMLInputElement>document.getElementById("search-bar-input");
                searchBarInput.placeholder = SearchLocationTagContainer._originalSearchBarText;
            }
        }
        SearchService.SearchRequest(SearchService.StrCrit(), true, true);
    }

    public static RemoveAllLocationTags() {
        let tags : HTMLCollectionOf<Element> = document.getElementsByClassName("location-tag");
        for (var i = tags.length - 1; i >= 0; --i) {
            tags[i].remove();
        }
        this._searchBarInput.placeholder = this._originalSearchBarText;
    }
    public static RemoveCustomMapAreaTag() {
        let tag: HTMLElement = document.getElementById("custom-map-tag");
        if (tag) {
            SearchLocationTagContainer.removeTag(tag);
        }
    }

    private static SetLocationTagRemovalLimit() {
        if (BrowserDetector.isMobileWidth()) {
            this._locationTagRemovalLimit = 0;
            this._locationTagDisplayMax = 1;
        }
    }

    public static CreateCustomMapAreaTag() {
        this._visualContainer.innerHTML = "<span class='location-tag' id='custom-map-tag'>Custom Map Area</span>";
    }
}
