﻿import { ListingCardFavoriteInteractions } from '../listing-favorites/ListingCardFavoriteInteractions';

export class SimilarPropertiesComponentViewModel {
    constructor() {
        const listingCardInter = new ListingCardFavoriteInteractions("btn--fav");
        listingCardInter.setFavoriteEvents();
    }
}

if (document.getElementById("similar-properties-component")) {
    let similarPropertiesVM = new SimilarPropertiesComponentViewModel();
}
