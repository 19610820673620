﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';

export class SearchPage {
    constructor() {
        document.addEventListener(SearchPageBroadcast.expandMapEventName, () => { this.expandMap(); });
        document.addEventListener(SearchPageBroadcast.contractMapEventName, () => { this.contractMap(); });
    }

    expandMap(): void {
        document.getElementsByClassName("search-page").item(0).classList.add("expanded");;
    }
    contractMap(): void {
        document.getElementsByClassName("search-page").item(0).classList.remove("expanded");;
    }
}

if (document.body.classList.contains("search-page")) {
    var searchPage = new SearchPage();
}