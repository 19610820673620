// inspired by: https://www.htmlgoodies.com/beyond/javascript/article.php/3724571/Using-Multiple-JavaScript-Onload-Functions.htm

export class WindowLoadEventHandler {
    static addLoadEvent(func: any) {
        var oldonload = window.onload as any;
        if (typeof window.onload !== 'function' && typeof func === 'function') {
          window.onload = func;
        } 
        else {
          window.onload = function() {
            if (oldonload) {
              oldonload();
            }
            if (func && typeof func == 'function') {
                func();
            }
          }
        }
      }
}