﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class LenderDefaultBannerClickEvent implements IGoogleEvent {
    name: string = "LenderDefaultBannerClickEvent";
    category: string = "banner";
    label: string = "Default Banner Click";
    type: EventType = EventType.Click;
}

@DomainEventRegistry.DomainEventRegistry.register
class LenderDefaultBannerClickEventHandler extends DomainEventHandler {

    domainEventName: string = "LenderDefaultBannerClickEvent";

    handleDomainEvent<PromoBannerClickEvent>(ev: CustomEvent, args: PromoBannerClickEvent): void {

    }
}