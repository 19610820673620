import { SavedSearchApiRequest } from "../saved-search/SavedSearchApiRequest";

// Facade for the Saved Search API Endpoints
export class SavedSearchApiCommunicator {
    readonly _baseEnpoint : string = 'api/organizer/searches/';

    getAll(loadCallback : Function, errorCallback : Function) {
        this.performApiHttpRequest('GET', this._baseEnpoint, null, loadCallback, errorCallback);
    }

    create(request : SavedSearchApiRequest, loadCallback : Function, errorCallback : Function) {
        this.performApiHttpRequest('PUT', this._baseEnpoint, request, loadCallback, errorCallback);
    }

    update(request : SavedSearchApiRequest, loadCallback : Function, errorCallback : Function) {
        this.performApiHttpRequest('POST', this._baseEnpoint, request, loadCallback, errorCallback);
    }

    delete(savedSearchId : string, loadCallback : Function, errorCallback : Function) {
        this.performApiHttpRequest('DELETE', this._baseEnpoint + savedSearchId, null, loadCallback, errorCallback);
    }

    private performApiHttpRequest(method : string, url : string, request : object, loadCallback : Function, errorCallback : Function): XMLHttpRequest {
        if (method !== 'GET' && method !== 'PUT' && method !== 'POST' && method !== 'DELETE') {
            return new XMLHttpRequest();
        }

        let httpRequest = new XMLHttpRequest();
        httpRequest.open(method, url, true);
        httpRequest.setRequestHeader("Content-Type", "application/json");
        httpRequest.addEventListener("load", () => loadCallback());
        httpRequest.addEventListener("error", () => errorCallback());
        
        if (method === 'GET') {
            httpRequest.send();
        } else if (request) {
            httpRequest.send(JSON.stringify(request));
        }
    }
}