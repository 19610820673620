﻿import {IDomainEvent} from './DomainEvent';
import {IDomainEventHandler} from './DomainEventHandler';
import { WindowLoadEventHandler } from "../tools/WindowLoadEventHandler";

export namespace DomainEventRegistry {
    
    type Constructor<T> = {
      new(...args: any[]): T;
      readonly prototype: T;
    }
    
    const implementations: Constructor<IDomainEventHandler>[] = [];
    
    export function getImplementations(): Constructor<IDomainEventHandler>[] {
      return implementations;
    }
    
    export function register<T extends Constructor<IDomainEventHandler>>(ctor: T) {
      implementations.push(ctor);
      return ctor;
    }
}

WindowLoadEventHandler.addLoadEvent(function(){
  var domainEventHandlers = DomainEventRegistry.getImplementations();
  for (var x = 0; x < domainEventHandlers.length; x++) {
    const domainEventHandler = new domainEventHandlers[x]();
    domainEventHandler.attachDomainEventListener();
  }
});