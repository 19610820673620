﻿import { PropertyOpinion } from '../models/PropertyOpinion';
import { URLReader } from "../tools/URLReader";

declare var CINC: any;

export class ScorecardViewModel {
    opinionCards : HTMLCollectionOf<HTMLElement>;
    PropertyId : string;
    IsLoggedIn = (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.userDID !== null);
    constructor() {
        this.PropertyId = URLReader.ParsePath()[6];//the pdid should always be the 6th on this page
        this.setOpinionCardEvents();
    }   

    setOpinionCardEvents() {
        this.opinionCards = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("opinion");
        for(let index = 0; index < this.opinionCards.length; index++) {
            let propOpinion : PropertyOpinion = new PropertyOpinion();
            propOpinion.FeatureName = this.opinionCards.item(index).dataset["feature"];
            propOpinion.FeatureOpinion = this.opinionCards.item(index).dataset["opinion"];

            if(this.opinionCards.item(index).dataset["selected"] === "True")
            {
                this.setSelectedStyling(this.opinionCards.item(index));
            }
            propOpinion.PDID = this.PropertyId;
            this.opinionCards.item(index).onclick = (e) => this.sendOpinionRequest(e, propOpinion, this.opinionCards.item(index));
        }
    }
        
    sendOpinionRequest(event: Event, opinion: PropertyOpinion, clickedElement: HTMLElement) {
        if (!this.IsLoggedIn) {
            CINC.Core.PromptForSignup('', true);
            return;
        }
        event.preventDefault();

        if(clickedElement.dataset["selected"] === "True")
            opinion.FeatureOpinion = "None";

        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url: string = `/api/organizer/opinion`;
        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.addEventListener("load", () => this.handleOpinionRequestLoad(xhttp, clickedElement));
        xhttp.send(JSON.stringify(opinion));
    }

    handleOpinionRequestLoad(request: XMLHttpRequest, clickedElement: HTMLElement) {
        if(request.status === 200)
        {
            let opinions = <NodeListOf<HTMLElement>>document.querySelectorAll(`[data-feature='${clickedElement.dataset["feature"]}']`);
            for(let index = 0; index < opinions.length; index++)
            {
                if(opinions.item(index).dataset["opinion"] !== clickedElement.dataset["opinion"]) {
                    opinions.item(index).dataset["selected"] = "False";
                    this.setSelectedStyling(opinions.item(index));
                }
            }
            this.setSelectedValue(clickedElement);
            this.setSelectedStyling(clickedElement);
        }
    }

    setSelectedValue(element: HTMLElement) {
        element.dataset["selected"] = element.dataset["selected"] === "True" ? "False" : "True";
    }

    setSelectedStyling(element : HTMLElement) {
        var iconElement = element.children.item(0);
        var color = element.dataset["opinion"] === "Pro" ? "text-green" : "text-red";
        var icon = element.dataset["opinion"] === "Pro" ? "icon-active" : "icon-delete";
        var emptyIcon = `icon-empty_circle`;
        if(element.dataset["selected"] === "True") {
            iconElement.classList.add(color);
            iconElement.classList.add(icon);
            iconElement.classList.remove(emptyIcon);
        }
        else {
            iconElement.classList.remove(color);
            iconElement.classList.remove(icon);
            iconElement.classList.add(emptyIcon);
        }
    }
}

if(document.getElementById("property-score-card-component")) {
    let scorecardVM : ScorecardViewModel = new ScorecardViewModel();
}