﻿import { MortgageCalculator } from "../tools/mortgagecalculator";
import { TextBoxFormatter } from "../tools/TextBoxFormatter";
import { ValidationContainer } from "../models/ValidationContainer";
import { Chart } from "Chart.js";

export class MortgageCalculatorComponentViewModel {
    //Text Displays
    LoanAmountDisplay: HTMLElement;
    MonthlyMortgagePaymentDisplay: HTMLElement;
    MonthlyTotalPaymentDisplay: HTMLElement;
    MonthlyPaymentChart: HTMLElement;

    //Inputs
    PriceInput: HTMLInputElement;
    DownPaymentInput: HTMLInputElement;
    InterestRateInput: HTMLInputElement;
    HoaInput: HTMLInputElement;
    TermsInput: HTMLInputElement;
    PropertyTaxInput: HTMLInputElement;
    HomeInsuranceInput: HTMLInputElement;

    //Buttons
    CalculateMortgageButton: HTMLButtonElement;

    //Canvas Elements
    Canvas: HTMLCanvasElement;
    Ctx: CanvasRenderingContext2D;
    ChartItem: Chart;

    //Input Values
    PropertyCost: number = 200000;
    DownPayment: number = 40000;
    InterestRate: number = 5.41;
    Hoa: number = 200;
    Terms: number = 360;
    PropertyTax: number = 300;
    HomeInsurance: number = 80;

    //Calculated Values
    LoanAmount: number;
    MonthlyMortgagePayment: number;
    MonthlyTotalPayment: number;
    PMI: number;

    //Validation
    IsFormValid: boolean = true;

    constructor() {
        //Display Elements;
        this.LoanAmountDisplay = document.getElementById("loanAmount");
        this.MonthlyMortgagePaymentDisplay = document.getElementById("monthlyMortgagePayment");
        this.MonthlyTotalPaymentDisplay = document.getElementById("totalMonthlyPayment");
        this.MonthlyPaymentChart = document.getElementById("paymentText");

        //Input Elements
        this.PriceInput = <HTMLInputElement>document.getElementById("mortgagePriceInput");
        this.DownPaymentInput = <HTMLInputElement>document.getElementById("mortgageDPInput");
        this.InterestRateInput = <HTMLInputElement>document.getElementById("mortgageInterestInput");
        this.HoaInput = <HTMLInputElement>document.getElementById("mortgageHoaInput");
        this.TermsInput = <HTMLInputElement>document.getElementById("mortgageTermInput");
        this.HomeInsuranceInput = <HTMLInputElement>document.getElementById("homeInsuranceInput");
        this.PropertyTaxInput = <HTMLInputElement>document.getElementById("taxInput");

        //Button Elements
        this.CalculateMortgageButton = <HTMLButtonElement>document.getElementById("calculateMortgageButton");

        //Canvas Elements
        this.Canvas = <HTMLCanvasElement>document.getElementById("myChart");
        if (this.Canvas) {
            this.Ctx = this.Canvas.getContext("2d");
            this.ChartItem = new Chart(this.Ctx, {});
        }

        //Event Handling
        if (this.CalculateMortgageButton) {
            this.CalculateMortgageButton.addEventListener('click', () => this.CalculateMortgage());
        }

        if (this.PriceInput){
            this.PriceInput.addEventListener('keyup', (e: KeyboardEvent) => this.KeyUpHandle(e));
        }

        if (this.DownPaymentInput){
            this.DownPaymentInput.addEventListener('keyup', (e: KeyboardEvent) => this.KeyUpHandle(e));            
        }

        if (this.InterestRateInput){
            this.InterestRateInput.addEventListener('keyup', (e: KeyboardEvent) => this.KeyUpHandle(e));
        }

        if (this.HoaInput){
            this.HoaInput.addEventListener('keyup', (e: KeyboardEvent) => this.KeyUpHandle(e));
        }

        if (this.HomeInsuranceInput){
            this.HomeInsuranceInput.addEventListener('keyup', (e: KeyboardEvent) => this.KeyUpHandle(e));
        }

        if (this.PriceInput){
            this.PropertyTaxInput.addEventListener('keyup', (e: KeyboardEvent) => this.KeyUpHandle(e));    
        }

        if (this.TermsInput){
            this.TermsInput.addEventListener('change', () => this.CalculateMortgage());    
        }

        if (this.IsAllInputsAvailable()){ 
            this.CalculateMortgage();
        }
    }

    KeyUpHandle(e: KeyboardEvent) {
        if (e.key === 'Enter')
            this.CalculateMortgage();
    }

    ValidateHomePrice(price: number, _This: MortgageCalculatorComponentViewModel): ValidationContainer {
        let retContainer: ValidationContainer = new ValidationContainer();
        if (isNaN(price)) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a valid number.";
        }
        else if (price <= 1000) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a value greater than $1,000.";
        }
        else {
            retContainer.IsValid = true;
            retContainer.Message = "";
        }
        _This.IsFormValid = _This.IsFormValid === false ? false : retContainer.IsValid;
        return retContainer;
    }

    ValidateDownPayment(payment: number, price: number, _This: MortgageCalculatorComponentViewModel): ValidationContainer {
        let retContainer: ValidationContainer = new ValidationContainer();
        if (isNaN(payment)) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a valid number.";
        }
        else if (payment <= 0) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a number greater than 0.";
        }
        else if (payment >= price) {
            retContainer.IsValid = false;
            retContainer.Message = "Your down payment must be less than your home price.";
        }
        else {
            retContainer.IsValid = true;
        }
        _This.IsFormValid = _This.IsFormValid === false ? false : retContainer.IsValid;
        return retContainer;
    }

    ValidateInterest(interestRate: number, _This: MortgageCalculatorComponentViewModel): ValidationContainer {
        let retContainer: ValidationContainer = new ValidationContainer();
        if (isNaN(interestRate)) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a valid number.";
        }
        else if (interestRate > 100 || interestRate <= 0) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a number between 0 and 100.";
        }
        else {
            retContainer.IsValid = true;
        }
        _This.IsFormValid = _This.IsFormValid === false ? false : retContainer.IsValid;
        return retContainer;
    }

    ValidateValGreaterThanZero(val: number, _This: MortgageCalculatorComponentViewModel): ValidationContainer {
        let retContainer: ValidationContainer = new ValidationContainer();
        if (isNaN(val)) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a valid number.";
        }
        else if (val < 0) {
            retContainer.IsValid = false;
            retContainer.Message = "Please enter a number greater than 0."
        }
        else {
            retContainer.IsValid = true;
            retContainer.Message = "";
        }
        _This.IsFormValid = _This.IsFormValid === false ? false : retContainer.IsValid;
        return retContainer;
    }

    DisplayValidation(valueInput: HTMLInputElement, parseFunc: Function, validationFunc: Function, displayFormatFunc: Function, validationTextElementId: string): number {
        let numericValue = parseFunc(TextBoxFormatter.RemoveDollarSignAndCommas(valueInput.value));
        let valueValidation: ValidationContainer = validationFunc(numericValue, this);
        let validationTextElement: HTMLElement = document.getElementById(validationTextElementId);
        if (valueValidation.IsValid) {
            if(displayFormatFunc)
                valueInput.value = displayFormatFunc(numericValue.toString());
            else
                valueInput.value = numericValue;
            validationTextElement.style.display = "none";
        }
        else {
            validationTextElement.style.display = "block";
            validationTextElement.innerText = valueValidation.Message;
        }
        return numericValue;
    }

    DisplayDownPaymentValidation(downPaymentInput: HTMLInputElement, mortgageValueInput: HTMLInputElement, parseFunc: Function, validationFunc: Function, displayFormatFunc: Function, validationTextElementId: string): number {
        let downPaymentValue = parseFunc(TextBoxFormatter.RemoveDollarSignAndCommas(downPaymentInput.value));
        let mortgageValue = parseFunc(TextBoxFormatter.RemoveDollarSignAndCommas(mortgageValueInput.value));

        let valueValidation: ValidationContainer = validationFunc(downPaymentValue, mortgageValue, this);

        let validationTextElement: HTMLElement = document.getElementById(validationTextElementId);

        if (valueValidation.IsValid) {
            downPaymentInput.value = displayFormatFunc(downPaymentValue.toString());
            validationTextElement.style.display = "none";
        }
        else {
            validationTextElement.style.display = "block";
            validationTextElement.innerText = valueValidation.Message;
        }
        return downPaymentValue;
    }

    CalculateMortgage() {
        this.IsFormValid = true;
        //Input and Display Values
        //Property Cost
        this.PropertyCost = this.DisplayValidation(this.PriceInput, parseInt, this.ValidateHomePrice, TextBoxFormatter.AddCommasToMoney, "priceValidation");
        //Down Payment
        this.DownPayment = this.DisplayDownPaymentValidation(this.DownPaymentInput, this.PriceInput, parseInt, this.ValidateDownPayment, TextBoxFormatter.AddCommasToMoney, "downPaymentValidation");
        //Interest Rate
        this.InterestRate = this.DisplayValidation(this.InterestRateInput, parseFloat, this.ValidateInterest, null, "interestValidation");
        //HOA
        this.Hoa = this.DisplayValidation(this.HoaInput, parseInt, this.ValidateValGreaterThanZero, TextBoxFormatter.AddCommasToMoney, "hoaValidation");
        //Property Tax
        this.PropertyTax = this.DisplayValidation(this.PropertyTaxInput, parseInt, this.ValidateValGreaterThanZero, TextBoxFormatter.AddCommasToMoney, "taxValidation");
        //HomeInsurance
        this.HomeInsurance = this.DisplayValidation(this.HomeInsuranceInput, parseInt, this.ValidateValGreaterThanZero, TextBoxFormatter.AddCommasToMoney, "homeInsuranceValidation");

        //Terms
        this.Terms = parseInt(this.TermsInput.value);

        if (this.IsFormValid) {
            //Clear canvas to be redrawn
            this.Canvas.parentElement.removeChild(this.Canvas);
            let newCanvas = document.createElement("canvas");
            newCanvas.id = "myChart";
            document.getElementById("chartContainer").appendChild(newCanvas);
            this.Canvas = <HTMLCanvasElement>document.getElementById("myChart");
            this.Ctx = this.Canvas.getContext("2d");

            //Calculations
            this.LoanAmount = this.PropertyCost - this.DownPayment;
            this.PMI = Math.round(this.DownPayment / this.PropertyCost * 100) < 20 ? Math.ceil((this.LoanAmount - this.DownPayment) * .0059 / 12) : 0;
            this.MonthlyMortgagePayment = MortgageCalculator.PrincipalToMortgagePayment(this.LoanAmount, this.Terms, this.InterestRate);
            this.MonthlyTotalPayment = this.MonthlyMortgagePayment + this.Hoa + this.PropertyTax + this.HomeInsurance;

            //Displays
            let monthlyMortgagePaymentText = TextBoxFormatter.AddDollarSignAndCommas(Math.round(this.MonthlyMortgagePayment).toString());
            let monthlyTotalPaymentText = TextBoxFormatter.AddDollarSignAndCommas(Math.round(this.MonthlyTotalPayment).toString());
            this.MonthlyMortgagePaymentDisplay.innerText = monthlyMortgagePaymentText
            this.MonthlyTotalPaymentDisplay.innerText = monthlyTotalPaymentText;
            this.LoanAmountDisplay.innerText = TextBoxFormatter.AddDollarSignAndCommas(this.LoanAmount.toString());
            this.MonthlyPaymentChart.innerText = monthlyTotalPaymentText;

            this.ChartItem = new Chart(this.Ctx, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        backgroundColor: ['rgb(63, 193, 224)', 'rgb(48,161,188)', 'rgb(55,221,125)', 'rgb(239, 239, 239)', 'rgb(247, 234, 153)'],
                        data: [Math.round(this.MonthlyMortgagePayment), this.PropertyTax, this.HomeInsurance, this.Hoa, this.PMI],
                    }],

                    labels: [
                        'P&I',
                        'Taxes',
                        'Insurance',
                        'HOA',
                        'PMI'
                    ]
                },
                options: {
                    cutoutPercentage: 80,
                    responsive: true,
                    maintainAspectRatio: false
                }
            });
            this.ChartItem.update();
        }
    }

    IsAllInputsAvailable(){
        return this.Canvas && this.CalculateMortgageButton && this.PriceInput && this.DownPaymentInput && 
        this.InterestRateInput && this.HoaInput && this.HomeInsuranceInput && this.PriceInput && this.TermsInput;
    }
}

if(document.getElementById("mortgage-calculator")){
    let mortgageCalculatorComponentViewModel = new MortgageCalculatorComponentViewModel();
}