﻿import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
import { BrowserDetector } from '../tools/BrowserDetector';

export class SearchBarContainer {
    filtersButton: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("searchbar-wrapper-display-filters");
    openFiltersHtml = `<i class="icon-filter"></i><span class="no-padding">Filters</span>`;
    closedFiltersHtml = `<i class="icon-close"></i><span>Close</span>`;

    constructor() {
        this.filtersButton.addEventListener("click", () => { this.toggleButtons(true); });

        document.addEventListener(SearchPageBroadcast.displayFiltersEventName, () => { this.toggleButtons(false); });
        document.addEventListener(SearchPageBroadcast.closeFiltersEventName, () => { this.toggleButtons(false); });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => { this.toggleButtons(false); });
        document.addEventListener(SearchPageBroadcast.searchRequestInitiatedEventName, () => { this.searchRequestInitiated() });
        document.addEventListener(SearchPageBroadcast.searchRequestLoadedEventName, () => { this.searchRequestLoaded(); });
        document.addEventListener(SearchPageBroadcast.listingResultsLoadedEventName,() => { this.listingResultsLoaded(); });
    }

    toggleButtons(selfInitiated : boolean) : void  {
        if (this.filtersButton.innerHTML === this.openFiltersHtml) {
            if (selfInitiated) {
                SearchPageBroadcast.DisplayFilters();
            }
            else {
                this.filtersButton.innerHTML = this.closedFiltersHtml;
            }
        }
        else
        {
            if (selfInitiated) {
                BrowserDetector.isMobileBrowser ? SearchPageBroadcast.CloseFiltersWhenOnMobile() : SearchPageBroadcast.CloseFilters();
            }
            else {
                this.filtersButton.innerHTML = this.openFiltersHtml;
            }
        }
    }

    searchRequestInitiated(): void {
        document.getElementById("searchbar-wrapper-display-filters").classList.add("disabled");
    }

    searchRequestLoaded(): void {
        document.getElementById("searchbar-wrapper-display-filters").classList.remove("disabled");
    }

    listingResultsLoaded(): void {
        var listingResults = document.getElementById("listing-results")
        if (listingResults) {
            listingResults.hidden = false;
        }
    }
}
        
if(document.getElementById("searchBarContainer")) {
    let searchBarContainer = new SearchBarContainer();
}