﻿declare var CINC: any;

export class PropertyNextResultComponent {
    propertyInput: string;
    constructor() {
        var urlStack = CINC.Core.LocalStorage.Get("UrlStack");
        if (!urlStack || urlStack.length == 0)
            return;

        var stackIndex = urlStack.indexOf(window.location.pathname);
        if (stackIndex == -1)
            return;

        var nextUrl = urlStack[stackIndex + 1] || "";

        let nextElement = <HTMLAnchorElement>document.getElementsByClassName("js-urlstack-next")[0];
        if (nextElement && nextUrl != "") {
            nextElement.href = nextUrl;
            nextElement.classList.remove("hidden");
        }
    }
}

if (document.getElementById("property-next-result")) {
    let propertyNextResultComponent = new PropertyNextResultComponent();
}