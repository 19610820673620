﻿import { ListingFavoriteUpdater } from '../listing-favorites/ListingFavoriteUpdater';
import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
declare var CINC : any;

export class ListingCardFavoriteInteractions {
    listingFavorites: HTMLCollectionOf<HTMLAnchorElement>;
    eventLauncher: CustomEvent;
    IsLoggedIn = (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.userDID !== null);

    constructor(listingCardFavClassName: string) {
        this.listingFavorites = <HTMLCollectionOf<HTMLAnchorElement>>document.getElementsByClassName(listingCardFavClassName);
        this.setListingFavorites();
    }
    setListingFavorites() {
        document.addEventListener("selected-favorite", (ev: CustomEvent) => {
            let pdid: string = ev.detail["pdid"];
            for (let ind = 0; ind < this.listingFavorites.length; ind++) {
                if (this.listingFavorites.item(ind).dataset["pdid"] === pdid) {
                    let listingFavorite = this.listingFavorites.item(ind);
                    if (listingFavorite.classList.contains("favd")) {
                        listingFavorite.classList.remove("favd");
                        listingFavorite.dataset["isfavorite"] = "False";
                    }
                    else {
                        listingFavorite.classList.add("favd");
                        listingFavorite.dataset["isfavorite"] = "True";
                    }
                }
            }
        });
    }

    setFavoriteEvents() {
        let listingLength: number = this.listingFavorites.length;
        for (let ind = 0; ind < listingLength; ind++) {
            let favoriteLink: HTMLAnchorElement = this.listingFavorites.item(ind);
            favoriteLink.addEventListener("click", (ev) => {
                if (!this.IsLoggedIn) {
                    CINC.Core.PromptForSignup('', true, '1');
                    return;
                }
                let element: HTMLAnchorElement = <HTMLAnchorElement>ev.srcElement;
                let pdid: string = element.dataset["pdid"];
                let isFavorite: boolean = (element.dataset["isfavorite"].toLowerCase() === 'true');

                ListingFavoriteUpdater.UpdateFavorite(pdid, isFavorite, element, this.updateFavoriteIcon);
                SearchPageBroadcast.FavoritedListing(pdid);
            });
        }
    }

    updateFavoriteIcon(xhttp: XMLHttpRequest, isFavorite: boolean, element: HTMLElement) {
        if (xhttp.status === 200) {
            if (!isFavorite) {
                element.classList.add("favd");
            }
            else {
                element.classList.remove("favd");
            }
            element.dataset["isfavorite"] = (!isFavorite).toString();
            element.parentElement.parentElement.parentElement.dataset["isfavorite"] = (!isFavorite).toString();
        }
    }
}
