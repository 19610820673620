﻿import { LongformWizardInitClickEvent } from '../events/LongformWizardInitClick';
import { DomainEventBus } from '../events/DomainEventBus';

declare var CINC: any;

export class PropertyDetailsLongformButtonComponentViewModel {
    static componentId = 'ProperyDetailsLongformButtonComponent';
    readonly popoverId = 'longform-wizard-popover';
    readonly WIZARD_BTN_ID = 'prequal-activate';

    wizardButton: HTMLButtonElement;
    popover: HTMLDivElement;
    body: HTMLElement;
    

    constructor() {
        this.body = <HTMLDivElement>document.body;
        this.popover = <HTMLDivElement>document.getElementById(this.popoverId);
        this.wizardButton = <HTMLButtonElement>document.getElementById(this.WIZARD_BTN_ID);
        this.wizardButton.addEventListener('click', (e: Event) => this.openLongForm());
    }

    openLongForm(): void {
        if (typeof CINC.User !== 'undefined' && CINC.User != null) {
            this.openLongFormModal();
            //DomainEventBus.raise(new LongformWizardInitClickEvent())
        }
    }

    openLongFormModal(e: Event = null): void {
        this.popover.style.display = 'block';
        this.body.style.overflowY = 'hidden';
    }
}

if (document.getElementById(PropertyDetailsLongformButtonComponentViewModel.componentId)) {
    let propertyDetailsLongformButtonVM = new PropertyDetailsLongformButtonComponentViewModel();
}