(function () {
    var flexslider = { vars: {} };

    // tiny helper function to add breakpoints
    function getGridSize() {
        return (window.innerWidth < 600) ? 1 :
            (window.innerWidth <= 768) ? 2 :
            (window.innerWidth < 900) ? 3 : 3;
    }


    window.addEventListener('load', function () {
        try {
            var flex = document.getElementsByClassName('.featured-props')[0];
            if (flex) {
                flex.flexslider({
                    animation: "slide",
                    slideshow: false,
                    itemWidth: 210,
                    itemMargin: 5,
                    minItems: getGridSize(), // use function to pull in initial value
                    maxItems: getGridSize() // use function to pull in initial value
                });

                document.getElementsByClassName('.neighborhood-gallery')[0].flexslider({
                    animation: "slide",
                    controlNav: false
                });
            }
        }
        catch (err) {
            console.log(err);
        }

        try {
            var slideshow = document.getElementsByClassName('.slideshow')[0];
            if (slideshow) {
                slideshow.flexslider({
                    animation: "slide",
                    controlNav: false
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    });

    // check grid size on resize event
    window.addEventListener('resize', function () {
        var gridSize = getGridSize();

        flexslider.vars.minItems = gridSize;
        flexslider.vars.maxItems = gridSize;
    });
}());