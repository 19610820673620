﻿import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
import { SliderInput } from '../search/SliderInput';
import { SearchService } from '../search/SearchService';
import { EqualityHelper } from "../tools/EqualityHelper";
import { BrowserDetector } from "../tools/BrowserDetector";
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

export class SearchQuickFiltersBarComponent {

    readonly buttonClass: string = "button-selected";
    readonly buttonRedClass: string = "button-red";
    readonly buttonChangedClass: string = "criteria-added";

    bedsButton = <HTMLElement>document.getElementById("beds-button");
    bathsButton = <HTMLElement>document.getElementById("baths-button");
    priceButton = <HTMLElement>document.getElementById("price-button");

    saveSearchButton = <HTMLElement>document.getElementById("searchbar-wrapper-components").getElementsByClassName("saved-search-element")[0];

    moreButton = <HTMLElement>document.getElementById("searchQuickFilters");
    moreButtonText : string = "More";

    bedsDropDownCheckbox = <HTMLInputElement>document.getElementById("beds-dropdown");
    bathsDropDownCheckbox = <HTMLInputElement>document.getElementById("baths-dropdown");
    priceDropDownCheckbox = <HTMLInputElement>document.getElementById("price-dropdown");

    bedsClose = document.getElementById("beds-close");
    priceClose = document.getElementById("price-close");
    bathsClose = document.getElementById("baths-close");

    bathsContent = document.getElementById("baths-content");
    bedsContent = document.getElementById("beds-content");
    priceContent = document.getElementById("price-content");

    minPriceInput = <HTMLInputElement>document.getElementById("minprice-popup");
    maxPriceInput = <HTMLInputElement>document.getElementById("maxprice-popup");

    constructor() {
        this.updateBodyClass();
        this.initMainFiltersButton();
        this.initQuickFilters();
        this.initBedBathSliders();
        this.initPriceInputs();
        this.initSaveSearchButton();
        document.addEventListener(SearchPageBroadcast.resetFiltersEventName, () => { this.resetMainFilters();});
    }

    updateBodyClass(): void {
        document.body.classList.add("camelot-search-page");
    }

    initQuickFilters(): void {
        this.addButtonClassHandling(this.bedsButton, this.buttonClass);
        this.addButtonClassHandling(this.bathsButton, this.buttonClass);
        this.addButtonClassHandling(this.priceButton, this.buttonClass);

        this.priceClose.addEventListener("click", () => {
            this.priceDropDownCheckbox.checked = false;
            this.priceButton.classList.remove(this.buttonClass);
        });

        this.bedsClose.addEventListener("click", () => {
            this.bedsDropDownCheckbox.checked = false;
            this.bedsButton.classList.remove(this.buttonClass);
        });

        this.bathsClose.addEventListener("click", () => {
            this.bathsDropDownCheckbox.checked = false;
            this.bathsButton.classList.remove(this.buttonClass);
        });

        document.body.addEventListener("click", (e) => {
            if (this.bathsDropDownCheckbox.checked && !((<HTMLElement>e.target).id === 'baths-dropdown' || (<HTMLElement>e.target).id === 'baths-button')) {
                if (!this.bathsContent.contains(<HTMLElement>e.target)) {
                    this.bathsDropDownCheckbox.checked = false;
                    this.bathsButton.classList.remove(this.buttonClass);
                }
            }

            if (this.priceDropDownCheckbox.checked && !((<HTMLElement>e.target).id === 'price-dropdown' || (<HTMLElement>e.target).id === 'price-button')) {
                if (!this.priceContent.contains(<HTMLElement>e.target)) {
                    this.priceDropDownCheckbox.checked = false;
                    this.priceButton.classList.remove(this.buttonClass);
                }
            }

            if (this.bedsDropDownCheckbox.checked && !((<HTMLElement>e.target).id === 'beds-dropdown' || (<HTMLElement>e.target).id === 'beds-button')) {
                if (!this.bedsContent.contains(<HTMLElement>e.target)) {
                    this.bedsDropDownCheckbox.checked = false;
                    this.bedsButton.classList.remove(this.buttonClass);
                }
            }
        });
    }

    initPriceInputs(): void {
        this.updatePriceButton();
        this.minPriceInput.addEventListener("change", () => {
            var minprice = <HTMLInputElement>document.getElementById("minprice");
            if (!isNaN(parseInt(this.minPriceInput.value.replace(/,/g, "").replace("$", "")))) {
                minprice.value = this.minPriceInput.value;
                this.updatePriceButton();
                SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            }
            else if (this.minPriceInput.value === "") {
                minprice.value = "0";
                this.updatePriceButton();
                SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            }
            if (!this.priceButton.classList.contains(this.buttonChangedClass)) {
                this.priceButton.classList.add(this.buttonChangedClass);
            }
        });
        this.maxPriceInput.addEventListener("change", () => {
            var maxprice = <HTMLInputElement>document.getElementById("maxprice");
            if (!isNaN(parseInt(this.maxPriceInput.value.replace(/,/g, "").replace("$", "")))) {
                maxprice.value = this.maxPriceInput.value;
                this.updatePriceButton();
                SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            }
            else if (this.maxPriceInput.value === "") {
                maxprice.value = "0";
                this.updatePriceButton();
                SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            }
            if (!this.priceButton.classList.contains(this.buttonChangedClass) && this.priceButton.innerText !== "Price") {
                this.priceButton.classList.add(this.buttonChangedClass);
            }
        });
    }

    updatePriceButton() {
        var max: number = parseInt(this.maxPriceInput.value.replace(/,/g, "").replace("$", ""));
        var min: number = parseInt(this.minPriceInput.value.replace(/,/g, "").replace("$", ""));
        var displayText = "Price";
        var minUnitSymbol = "k";
        var maxUnitSymbol = "k";

        if (min < 1000 || isNaN(min)) {
            min = 0;
        }
        if (max < 1000 || isNaN(max)) {
            max = 0;
        }
        if (min >= 1000000) {
            minUnitSymbol = "M";
            min = Math.floor((min / 1000)/1000);
        }
        else {
            min = Math.floor(min / 1000);
        }
        if (max >= 1000000) {
            maxUnitSymbol = "M";
            max = Math.floor((max / 1000)/1000);
        }
        else {
            max = Math.floor(max / 1000);
        }

        if (max === 0 && min === 0) {
            displayText = "Price";
            if (this.priceButton.classList.contains(this.buttonChangedClass)) {
                this.priceButton.classList.remove("criteria-added");
            }
        } else if (max === 0 && min !== 0) {
            displayText = `$${min}${minUnitSymbol}+`;
        } else if (max !== 0) {
            displayText = `$${min}${minUnitSymbol}-$${max}${maxUnitSymbol}`;
        }

        this.priceButton.innerText = displayText;
    }

    addButtonClassHandling(button: HTMLElement, buttonClass: string) {
        button.addEventListener("click", () => {
            if (button.classList.contains(buttonClass)) {
                button.classList.remove(buttonClass);
                 GoogleAnalyticsEventLogger.log('Search Homes', button.id+'_range_closed');
            }
            else {
                button.classList.add(buttonClass);
                GoogleAnalyticsEventLogger.log('Search Homes', button.id+'_range_open');
            }
        });
    }

    initBedBathSliders(): void {
        let bedSlider = new SliderInput("quick-filters-bed-slider", 0, 5, "quick-filters-bed-label", "minbeds", "maxbeds");
        let bathSlider = new SliderInput("quick-filters-bath-slider", 0, 5, "quick-filters-bath-label", "minbaths", "maxbaths");

        this.bedsButton.innerText = this.createButtonText(bedSlider, 'bd');
        this.bathsButton.innerText = this.createButtonText(bathSlider, 'ba');

        if (bedSlider && bedSlider.slider) {
            bedSlider.slider.pips({
                mode: 'steps',
                density: 20
            });

            bedSlider.slider.on('change', () => {
                this.bedsButton.innerText = this.createButtonText(bedSlider, 'bd');
                if (!this.bedsButton.classList.contains(this.buttonChangedClass) && this.bedsButton.innerText !== "Beds") {
                    this.bedsButton.classList.add(this.buttonChangedClass);
                }
                SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            });
        }
        if (bathSlider && bathSlider.slider) {
            bathSlider.slider.pips({
                mode: 'steps',
                density: 20
            });

            bathSlider.slider.on('change', () => {
                this.bathsButton.innerText = this.createButtonText(bathSlider, 'ba');
                if (!this.bathsButton.classList.contains(this.buttonChangedClass) && this.bathsButton.innerText !== "Baths") {
                    this.bathsButton.classList.add(this.buttonChangedClass);
                }
                SearchService.SearchRequest(SearchService.StrCrit(), true, true);
            });
        }
    }

    initSaveSearchButton(): void {
        this.saveSearchButton.addEventListener("click", () => {
            GoogleAnalyticsEventLogger.log('Search Homes', 'save_search_saved');
        });

        document.addEventListener(SearchPageBroadcast.saveSearchCompletedEventName, () => {
            this.saveSearchButton.classList.add("saved");
            this.saveSearchButton.title = "Current Search Already Saved";
            this.saveSearchButton.classList.add(this.buttonRedClass);
        });

        document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, (ev: CustomEvent) => {
            if (this.saveSearchButton.classList.contains(this.buttonRedClass)) {
                this.saveSearchButton.classList.remove(this.buttonRedClass);
                this.saveSearchButton.classList.remove("saved");
                this.saveSearchButton.title = '';
            }

            this.moreButtonText = "More";
            var response = ev.detail["response"];
            var additionalFilters = parseInt(response.additionalFilters);
            if (!isNaN(additionalFilters) && additionalFilters > 1) {
                this.moreButtonText = `${response.additionalFilters -1} More`;
            }
            if (this.moreButton.dataset["filters"] !== "1") {
                this.moreButton.innerText = this.moreButtonText;
            }
        });
    }

    private createButtonText(slider: SliderInput, abbrev: string): string {
        var values = slider.slider.get();
        var min = parseInt(values[0]);
        var max = parseInt(values[1]);
        var displayText = "";
        if (min === max && max < 5) {
            displayText = `${min} ${abbrev}`;
        }
        else if (max === 5) {
            displayText = `${min}+ ${abbrev}`;
        } else {
            displayText = `${min}-${max} ${abbrev}`;
        }
        if (min === 0 && max === 5) {
            if (typeof (slider) != "undefined" && slider != null) {
                if (slider.sliderDivID === "quick-filters-bath-slider") {
                    displayText = "Baths";
                    if (this.bathsButton.classList.contains(this.buttonChangedClass)) {
                        this.bathsButton.classList.remove(this.buttonChangedClass);
                    }
                }
                if (slider.sliderDivID === "quick-filters-bed-slider") {
                    displayText = "Beds";
                    if (this.bedsButton.classList.contains(this.buttonChangedClass)) {
                        this.bedsButton.classList.remove(this.buttonChangedClass);
                    }
                }
            }
        }
        return displayText;
    }

    initMainFiltersButton(): void {
        document.addEventListener(SearchPageBroadcast.closeFiltersEventName, () => {
            if (typeof (this.moreButton) !== "undefined" && this.moreButton !== null && this.moreButton.classList.contains(this.buttonClass)) {
                this.updateButtonOnFiltersClose(this.moreButton);
            }
        });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => {
            if (typeof (this.moreButton) !== "undefined" && this.moreButton !== null && this.moreButton.classList.contains(this.buttonClass)) {
                this.updateButtonOnFiltersClose(this.moreButton);
            }
        });

        this.moreButton.addEventListener("click", () => {
            if (this.moreButton.dataset["filters"] === undefined || this.moreButton.dataset["filters"] === "0") {
                this.moreButton.dataset["filters"] = "1";
                this.moreButton.innerText = "Close";
                this.moreButton.classList.add(this.buttonClass);
                SearchPageBroadcast.DisplayFilters();
                GoogleAnalyticsEventLogger.log('Search Homes', 'open_advanced_filters');
            }
            else {
                this.updateButtonOnFiltersClose(this.moreButton);
                BrowserDetector.isMobileBrowser ? SearchPageBroadcast.CloseFiltersWhenOnMobile() : SearchPageBroadcast.CloseFilters();
                GoogleAnalyticsEventLogger.log('Search Homes', 'close_advanced_filters');
            }
        });
    }

    private updateButtonOnFiltersClose(moreButton : HTMLElement) {
        moreButton.dataset["filters"] = "0";
        moreButton.innerText = this.moreButtonText;
        moreButton.classList.remove(this.buttonClass);
    }

    private resetMainFilters() {
        if (EqualityHelper.isNil(this.priceButton)) {
            this.priceButton.innerText = "Price";
            if (this.priceButton.classList.contains(this.buttonChangedClass)) {
                this.priceButton.classList.remove(this.buttonChangedClass);
            }
        }
        if (EqualityHelper.isNil(this.minPriceInput)) {
            this.minPriceInput.value = "";
        }
        if (EqualityHelper.isNil(this.maxPriceInput)) {
            this.maxPriceInput.value = "";
        }
        if (EqualityHelper.isNil(this.bedsButton)) {
            this.bedsButton.innerText = "Beds";
            if (this.bedsButton.classList.contains(this.buttonChangedClass)) {
                this.bedsButton.classList.remove(this.buttonChangedClass);
            }
        }
        if (EqualityHelper.isNil(this.bathsButton)) {
            this.bathsButton.innerText = "Baths";
            if (this.bathsButton.classList.contains(this.buttonChangedClass)) {
                this.bathsButton.classList.remove(this.buttonChangedClass);
            }
        }
    }
}

if (document.getElementById("SearchQuickFiltersBarComponent")) {
    var searchQuickFiltersBarComponent = new SearchQuickFiltersBarComponent();
}
