﻿declare var toastr: any;

export class LeadCommunicationSettingsComponent {
    emailSubInput: HTMLInputElement;
    textSubInput: HTMLInputElement;
    propAlertInput: HTMLInputElement;
    propFavInput: HTMLInputElement;
    formValidation: HTMLElement;

    requestSentSuccessful: HTMLElement;

    form: HTMLFormElement;

    constructor(formName: string) {
        this.form = <HTMLFormElement>document.getElementById(formName);
        this.emailSubInput = <HTMLInputElement>document.getElementById("email-sub");
        this.textSubInput = <HTMLInputElement>document.getElementById("textmessage");
        this.propAlertInput = <HTMLInputElement>document.getElementById("propalerts");
        this.propFavInput = <HTMLInputElement>document.getElementById("propfavorite");

        toastr.options = {
            "closeButton": false,
            "debug": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "showDuration": "300",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
    }
}

if (document.getElementById("lead-comm-settings-update")) {
    let leadCommSettingsUpdate = new LeadCommunicationSettingsComponent("comm-settings-update-form");
}
