﻿import { ListingFavoriteUpdater } from '../listing-favorites/ListingFavoriteUpdater';
import { SearchPageBroadcast } from './SearchPageBroadcast';

declare var CINC: any;

export class QuickDetailsListingCardViewModel {
    closeElement : HTMLElement;
    favoriteElementDesktop: HTMLElement;
    favoriteElementMobile: HTMLElement;

    constructor() {
        this.closeElement = <HTMLElement>document.getElementById("close-selected-listing");
        this.favoriteElementDesktop = <HTMLElement>document.getElementById("search-map-selected-prop").querySelector("#selected-favorite");
        this.favoriteElementMobile = <HTMLElement>document.getElementById("search-map-selected-prop-mobile").querySelector("#selected-favorite");

        this.closeElement.addEventListener("click", () => {
            SearchPageBroadcast.CloseQuickDetailsView();
        });

        document.addEventListener(SearchPageBroadcast.displayFiltersEventName, () => {
            let selectedProperty = <HTMLElement>document.getElementById("search-map-selected-prop");
            let selectedPropertyMobile = <HTMLElement>document.getElementById("search-map-selected-prop-mobile");
            selectedProperty.style.display = 'none';
            selectedPropertyMobile.style.display = 'none';
        });

        document.addEventListener(SearchPageBroadcast.closeFiltersEventName, () => {
            let selectedProperty = <HTMLElement>document.getElementById("search-map-selected-prop");
            selectedProperty.style.display = 'block';
        });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => {
            let selectedPropertyMobile = <HTMLElement>document.getElementById("search-map-selected-prop-mobile");
            selectedPropertyMobile.style.display = 'block';
        });

        document.addEventListener(SearchPageBroadcast.closeQuickDetailsViewEventName,() => {
            let selectedProperty: HTMLElement = document.getElementById("search-map-selected-prop");
            let selectedPropertyMobile: HTMLElement = document.getElementById("search-map-selected-prop-mobile");
            if (selectedProperty.innerHTML !== "" || selectedPropertyMobile.innerHTML !== "") {
                selectedProperty.innerHTML = "";
                selectedPropertyMobile.innerHTML = "";
            }

            if(this.favoriteElementDesktop){
                SearchPageBroadcast.SelectedPropertyClosed(this.favoriteElementDesktop.dataset["pdid"]);
            }
            if(this.favoriteElementMobile){
                SearchPageBroadcast.SelectedPropertyClosed(this.favoriteElementMobile.dataset["pdid"]);
            }
        });

        if(this.favoriteElementDesktop){
            this.favoriteElementDesktop.addEventListener("click", () => {
                if(!CINC.User){
                    CINC.Core.PromptForSignup();
                    return;
                }
                ListingFavoriteUpdater.UpdateFavorite(
                    this.favoriteElementDesktop.dataset["pdid"],
                    this.favoriteElementDesktop.classList.contains("favd"),
                    this.favoriteElementDesktop);
                SearchPageBroadcast.FavoritedSelectedProperty(this.favoriteElementDesktop.dataset["pdid"]);
            });
        }

        if(this.favoriteElementMobile){
            this.favoriteElementMobile.addEventListener("click", () => {
                if(!CINC.User){
                    CINC.Core.PromptForSignup();
                    return;
                }
                ListingFavoriteUpdater.UpdateFavorite(
                    this.favoriteElementMobile.dataset["pdid"],
                    this.favoriteElementMobile.classList.contains("favd"),
                    this.favoriteElementDesktop);
                SearchPageBroadcast.FavoritedSelectedProperty(this.favoriteElementMobile.dataset["pdid"]);
            });
        }
    }
}
