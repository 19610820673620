import { WindowLoadEventHandler } from "../tools/WindowLoadEventHandler";
import { BrowserDetector } from "../tools/BrowserDetector";
import { SearchPageBroadcast } from "../search/SearchPageBroadcast";
declare var CINC: any; 

WindowLoadEventHandler.addLoadEvent(function () {
    //support for older browsers to load images when page is first loaded
    if (!("IntersectionObserver" in window)) {
        let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
        lazyImages.forEach(function (lazyImage: any) {
            if (lazyImage.getBoundingClientRect().top <= window.innerHeight) {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.remove("lazy");
            }
        });
    }
});

document.addEventListener("DOMContentLoaded", () => FireLazyLoad());
document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, () => FireLazyLoad());

function FireLazyLoad() {
    //lazy image loading for modern browsers
    const config = {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
    };

    if ("IntersectionObserver" in window) {
        if (!signUpModalIsShowing() && !BrowserDetector.isBot()) {
            let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
            let lazyImageObserver = new IntersectionObserver((entries: any, self) => {
                    entries.forEach((entry: any) => {
                        if (entry.isIntersecting) {
                            let lazyImage = entry.target;
                            lazyImage.src = lazyImage.dataset.src;
                            lazyImage.classList.remove("lazy");
                            lazyImageObserver.unobserve(lazyImage);
                        }
                    });
                },
                config);

            lazyImages.forEach((lazyImage: any) => {
                lazyImageObserver.observe(lazyImage);
            });
        }
    } else {
        //lazy image loading for ancient browsers (IE, Safari)
        let active = false;
        let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
        const lazyLoad = function () {
            if (active === false) {
                active = true;

                setTimeout(function () {
                    lazyImages.forEach(function (lazyImage: any) {
                        if ((lazyImage.getBoundingClientRect().top <= window.innerHeight &&
                            lazyImage.getBoundingClientRect().bottom >= 0) &&
                            window.getComputedStyle(lazyImage).display !== "none") {
                            lazyImage.src = lazyImage.dataset.src;
                            lazyImage.classList.remove("lazy");

                            lazyImages = lazyImages.filter(function (image: any) {
                                return image !== lazyImage;
                            });

                            if (lazyImages.length === 0) {
                                document.removeEventListener("scroll", lazyLoad);
                                window.removeEventListener("resize", lazyLoad);
                                window.removeEventListener("orientationchange", lazyLoad);
                            }
                        }
                    });

                    active = false;
                },
                    200);
            }
        };
        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
    }
};

function signUpModalIsShowing(): boolean {
    if (typeof CINC == "undefined" || CINC == null || typeof CINC.IsNonDismissableModal == "undefined" || CINC.IsNonDismissableModal == null)
        return false;

    return CINC.IsNonDismissableModal;
}

