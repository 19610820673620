﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class PropertySearchSortByClickEvent implements IGoogleEvent {
    category: string = "castle-framework";
    label: string = "sort-by";
    name: string = "PropertySearchSortByClickEvent";
    type: EventType = EventType.Search;
}

@DomainEventRegistry.DomainEventRegistry.register
class PropertySearchSortByClickEventHandler extends DomainEventHandler {
    domainEventName: string = `PropertySearchSortByClickEvent`;
    handleDomainEvent<PropertySearchSortByClickEvent>(ev: CustomEvent, args: PropertySearchSortByClickEvent): void {

    }
}