﻿declare var noUiSlider : any;

export class SliderInput {

    slider: any;
    sliderDivID: string;

    constructor(divId: string, minValue: number, maxValue: number, labelId : string, minElementId : string, maxElementId : string) {
        let sliderDiv: HTMLElement = document.getElementById(divId);
        this.sliderDivID = divId;
        if (sliderDiv) {
            let initMin = parseInt((<HTMLInputElement>document.getElementById(minElementId)).value);
            let initMax = parseInt((<HTMLInputElement>document.getElementById(maxElementId)).value);
            // if we don't have a previously saved max value, set it to the slider maximum to avoid having max = 0
            if (initMax === 0) {
                initMax = maxValue;
            }
            if (typeof noUiSlider !== "undefined") {
                this.slider = noUiSlider.create(sliderDiv,
                    {
                        start: [initMin, initMax],
                        connect: true,
                        range: {
                            'min': minValue,
                            'max': maxValue
                        },
                        step: 1
                    });


                this.slider.on('update',
                    (values: any, handle: any) => {
                        this.handleSliderUpdate(values,
                            [minValue, maxValue],
                            document.getElementById(labelId),
                            [document.getElementById(minElementId), document.getElementById(maxElementId)]);
                    });

                this.slider.on('change',
                    (values: any, handle: any) => {
                        this.handleSliderUpdate(values,
                            [minValue, maxValue],
                            document.getElementById(labelId),
                            [document.getElementById(minElementId), document.getElementById(maxElementId)]);
                    });
            }
        }
    }

    handleSliderUpdate(sliderValues : any[], sliderMinMaxValues : number[], labelElement : HTMLElement, valueElements : any[]) {
        for (let i : number = 0; i < sliderValues.length; i++) {
            let value : number  = parseInt(sliderValues[i]);

            // if the left slider is at min or right slider is at max value, set to null value
            if(value === sliderMinMaxValues[1] && i === 1) {
                valueElements[i].value = null;
            }
            else {
                valueElements[i].value = value;
            }
            // if the 'max' value is null, display "[min]+" instead of "min to max"
            if (valueElements[1].value === "") {
                labelElement.innerText = valueElements[0].value.toString() + "+";
            } else if (valueElements[0].value === valueElements[1].value) {
                labelElement.innerText = valueElements[0].value.toString();
            } else {
                labelElement.innerText = valueElements[0].value.toString() + " to " + valueElements[1].value.toString();
            }
        }
    }
}