declare var _: any;
declare var moment: any;
declare var $: any;
declare var CINC: any;

export class DateTimeUtil {
    static serverToBrowserTime(serverDate: any){
        console.log(serverDate);
        if (serverDate === null || typeof serverDate === 'undefined' || serverDate === '1/1/1900 12:00:00 AM' || serverDate === '1900-01-01T00:00:00') return null;

        //if the date comes from the server in Zulu / UTC, let the browser JS implementation handle timezone calculaion
        if (_.isString(serverDate) && serverDate.indexOf('Z') > -1) {
            return serverDate.toDate();
        }

        var serverUtcOffset = CINC && CINC.ServerUTCOffset || -6;
        var browserUtcOffset = ((new Date()).getTimezoneOffset() / -60);
        var srvDate = null;

        if (_.isString(serverDate) && serverDate.indexOf('T') > -1) {
            //server lies about dates being returned in UTC - they're server date without the timezone offset
            var dtArr = $.map(serverDate.split(/[^0-9]/), function (s: any) { return parseInt(s, 10) });
            srvDate = new Date(dtArr[0], dtArr[1] - 1 || 0, dtArr[2] || 1, dtArr[3] || 0, dtArr[4] || 0, dtArr[5] || 0, dtArr[6] || 0);
        }

        var serverMoment = moment(srvDate || new Date(serverDate));

        var serverToClientOffset = Math.abs(serverUtcOffset) - Math.abs(browserUtcOffset); //0 to -11 UTC
        if (1 <= browserUtcOffset && browserUtcOffset <= 6) {
            serverToClientOffset = Math.abs(serverUtcOffset) + Math.abs(browserUtcOffset); //1 to 6 UTC
        }
        if (7 <= browserUtcOffset && browserUtcOffset <= 12) {
            serverToClientOffset = serverUtcOffset - (12 - Math.abs(browserUtcOffset)); //7 to 12 UTC
        }

        var dateString = serverMoment.add(serverToClientOffset, 'hours').toISOString();//.format('YYYY/MM/DD HH:mm:ss Z');
        //console.log('serverDate [' + serverDate + '] serverOffset [' + serverUtcOffset + '] browserOffset [' + browserUtcOffset + '] serverToClientOffset [' + serverToClientOffset + '] dateString [' + dateString + '] date [' + new Date(dateString) + ']');
        return new Date(dateString);
    }
}