﻿import { ListingCardFavoriteInteractions } from '../listing-favorites/ListingCardFavoriteInteractions';

export class HomeViewModel {

    constructor() {
        const listingCardInter = new ListingCardFavoriteInteractions("btn--fav");
        listingCardInter.setFavoriteEvents();
    }
}

if(document.getElementById("castle-home")) {
    let HomeVM = new HomeViewModel();
}
