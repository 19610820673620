﻿import {DomainEventHandler} from '../events/DomainEventHandler';
import {IGoogleEvent} from '../events/DomainEvent';
import {EventType} from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class AdvancedFiltersClickEvent implements IGoogleEvent {
    category: string = "castle-framework";
    label: string = "advanced-filters-click";
    name: string = "AdvancedFiltersClickEvent";
    type: EventType = EventType.Search;
}

@DomainEventRegistry.DomainEventRegistry.register
class AdvancedFiltersClickEventHandler extends DomainEventHandler {
    domainEventName: string = 'AdvancedFiltersClickEvent';

    handleDomainEvent<AdvancedFiltersClickEvent>(ev: CustomEvent, args: AdvancedFiltersClickEvent): void {

    }
}
