declare var _: any;
declare var moment: any;
declare var $: any;
declare var CINC: any;

export class Util {
    static getURLParamExists(name: string) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? false : true;
    };
}