﻿import { CINCListing } from "../Models/CINCListing";

export class SearchListingsDataStore {
    /*
        The main reason for this class if to maintain the mobile search listings whenever they change
        If the map or the listings page isn't loaded whenever the filters are changed, they cannot listen for these changes,
        therefore, we will maintain that here so anything can get it via these methods.
    */

    private static localStorageKey = 'mapSearchListings';

    public static UpdateListingsFromDom(domListings: HTMLElement) : CINCListing[] {
        // conver to dom to array
        let listings = new Array<CINCListing>();

        let properties = domListings.children;

        if(properties.length > 0) {
            for(let propIndex = 0; propIndex < properties.length; propIndex++){
                let property : HTMLInputElement = <HTMLInputElement>properties.item(propIndex);
                listings.push(<CINCListing>({
                    Address: property.dataset["address"],
                    PDID: property.dataset["pdid"],
                    Lat: Number(property.dataset["lat"]),
                    Lng: Number(property.dataset["long"]),
                    Price: Number(property.dataset["price"]),
                    IsFavorite: property.dataset["isfavorite"].toLowerCase() === "true",
                    CanShowAddress: property.dataset["canShowAddress"].toLowerCase() === "true",
                }));

            }
        }

        return this.UpdateListings(listings);
    }

    public static UpdateListingsFromObject(listings: CINCListing[]) : CINCListing[] {
        return this.UpdateListings(listings);
    }

    private static UpdateListings(listings: CINCListing[]) : CINCListing[] {
        this.ClearListings();
        let serializedListings = JSON.stringify(listings);
        window.localStorage.setItem(this.localStorageKey, serializedListings);
        return listings;
    }

    public static ClearListings() {
        let currentListings = window.localStorage.getItem(this.localStorageKey);
        if (currentListings != null) {
            window.localStorage.removeItem(this.localStorageKey);
        }
    }

    public static GetListings() : CINCListing[] {
        let data = window.localStorage.getItem(this.localStorageKey);
        if(data == null || data.length == 0){
            return null;
        }
        let parsedListings = JSON.parse(data);
        if(parsedListings == null || parsedListings.length == 0){
            return null;
        }
        let listings = new Array<CINCListing>();
        parsedListings.forEach((listing : any) => {
            listings.push(Object.assign(new CINCListing, listing));
        });
        return listings;
    }
}