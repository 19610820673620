﻿export class PropetyBackToSearchComponent {
    constructor() {
        this.setBackToSearchLink();
    }

    private setBackToSearchLink() {
        let searchUrl : string = `${document.domain}/search`;
        if (document.referrer.indexOf(searchUrl) > -1) {
            let backToSearchLink : HTMLAnchorElement = <HTMLAnchorElement>document.getElementById("back-to-search-link");
            backToSearchLink.href = document.referrer;
            backToSearchLink.addEventListener('click', (e: Event) => { e.preventDefault(); window.history.back(); });
        }
    }
}

if (document.getElementById("back-to-search-link")) {
    let propertyBackToSearch : PropetyBackToSearchComponent = new PropetyBackToSearchComponent();
}