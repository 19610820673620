$(document).ready(function () {
    initToggleNavItemsVisibility();
    closeNavSidebarWhenModalOpenMobile();
});
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

var debounceToggleNavItemVisibility = debounce(toggleNavItemsVisibility, 50);
function initToggleNavItemsVisibility() {
    debounceToggleNavItemVisibility();
    $(window).off('resize', debounceToggleNavItemVisibility).on('resize', debounceToggleNavItemVisibility);
}

function toggleNavItemsVisibility() {
    var nav = $('nav');
    if (nav.length === 0 || !nav.is(':visible')) {
        return;
    }

    var menu = nav.find('.header-menu-left');
    if (menu.length === 0 || !menu.is(':visible')) {
        return;
    }

    var navItems = menu.find('.header-item').not('.js-nav-more');
    var moreItems = $('.js-nav-more').find('.menu .item');
    toggleMoreDropdownVisbility(navItems, moreItems);
}

function toggleMoreDropdownVisbility(navItems, moreItems) {
    var showMoreDropdown = false;

    navItems.each(function () {
        var navItem = $(this);
        var navItemText = $(this).find('a').text();

        moreItems.each(function () {
            var moreItem = $(this);
            var moreItemText = $(this).find('a').text();

            if (navItemText === moreItemText) {
                if (navItem.is(':hidden')) {
                    moreItem.show();
                    showMoreDropdown = true;    // if any item is shown, show the dropdown
                } else {
                    moreItem.hide();
                }
                return;
            }
        });

        var windowWidth = $(window).innerWidth();

        if (windowWidth <= 768) {
            navItem.removeClass('hidden-xs hidden-s');
            showMoreDropdown = false;
        }

    });

    if (showMoreDropdown) {
        $('.js-nav-more').css('display', 'inline-block');
    } else {
        $('.js-nav-more').hide();
    }
}

function closeNavSidebarWhenModalOpenMobile() {
    var windowWidth = $(window).innerWidth();

    if ( windowWidth <= 768 ) {
        $('.js-promptforlogin, .js-promptforsignup').on('click', function() {
            $('body').removeClass('with-sidebar');
        });
    }
}

