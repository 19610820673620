$(document).ready(function () {
    listingCardAspectRatioForIE();
});

function listingCardAspectRatioForIE() {
    var propCardWrapper = $('.mls-photo-aspect-ratio'),
        propCardImgWrapper = $('.mls-photo-aspect-ratio .property-image-wrapper');

    try {
        if (propCardWrapper && Modernizr && !Modernizr.objectfit) {
            propCardImgWrapper.each(function () {
                var $container = $(this),
                    imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                    $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('compact-object-fit');
                }
            });
        }
    } catch (ex) {
        
    }
}
