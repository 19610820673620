﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class FavoriteDetailsPageViewEvent implements IGoogleEvent {
    
    name: string = "FavoriteDetailsPageViewEvent";
    
    category: string = "details-interactions";
    label: string = "Favorite Details Page View";
    type: EventType = EventType.PageView;
}

@DomainEventRegistry.DomainEventRegistry.register
class FavoriteDetailsPageViewEventHandler extends DomainEventHandler {
    
    domainEventName: string = "FavoriteDetailsPageViewEvent";

    handleDomainEvent<FavoriteDetailsPageViewEvent>(ev: CustomEvent, args: FavoriteDetailsPageViewEvent) : void {
    }
}