﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class FeaturedDetailsPageViewEvent implements IGoogleEvent {
    
    name: string = "FeaturedDetailsPageViewEvent";
    
    category: string = "details-interactions";
    label: string = "Featured Details Page View";
    type: EventType = EventType.PageView;
}

@DomainEventRegistry.DomainEventRegistry.register
class FeaturedDetailsPageViewEventHandler extends DomainEventHandler {
    
    domainEventName: string = "FeaturedDetailsPageViewEvent";

    handleDomainEvent<FeaturedDetailsPageViewEvent>(ev: CustomEvent, args: FeaturedDetailsPageViewEvent) : void {

    }
}