﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class LongformWizardInitClickEvent implements IGoogleEvent {

    name: string = "LongformWizardInitClick";

    category: string = "longform";
    label: string = "Longform Wizard";
    type: EventType = EventType.PageView;
}

@DomainEventRegistry.DomainEventRegistry.register
class LongformWizardInitClickEventHanlder extends DomainEventHandler {

    domainEventName: string = "LongformWizardInitClick";

    handleDomainEvent<LongformWizardInitClickEvent>(ev: CustomEvent, args: LongformWizardInitClickEvent): void {

    }
}