﻿import { OrganizerNote } from '../models/OrganizerNote';
import { URLReader } from "../tools/URLReader";

declare var CINC: any;

export class PropertyNotesViewModel {
    PropertyNotesWindow : HTMLElement;
    AddNoteLink : HTMLLinkElement;
    AddNoteValidation : HTMLElement;
    NoteText : HTMLInputElement;
    NoteCount : HTMLElement;
    NoNotes : HTMLElement;
    PropertyId : string;
    DeleteLinks: HTMLCollectionOf<HTMLElement>;
    IsLoggedIn = (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.userDID !== null);

    constructor() {
        this.PropertyNotesWindow = document.getElementById("property-notes-window");
        this.AddNoteLink = <HTMLLinkElement>document.getElementById("add-note-link");
        this.AddNoteValidation = document.getElementById("add-note-validation");
        this.NoteText = <HTMLInputElement>document.getElementById("note-text");
        this.NoteCount = document.getElementById("note-count");
        this.PropertyId = URLReader.ParsePath()[6];//the pdid should always be the 6th on this page
        this.NoNotes = <HTMLElement>document.getElementById("no-notes");

        this.AddNoteLink.addEventListener('click', (e) => this.handleAddNoteLinkClick(this.NoteText.value, e));
        this.addDeleteEventListeners();
    }

    addDeleteEventListeners() {
        this.DeleteLinks = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("delete-note");
        for(let index = 0; index < this.DeleteLinks.length; index++) {
            this.DeleteLinks.item(index).onclick = (e) => this.handleDeleteNoteLinkClick(this.DeleteLinks.item(index),e);
        }
    }

    handleDeleteNoteLinkClick(link : HTMLElement, ev : Event) : void {
        ev.preventDefault();
        if(link) {
            var noteId = link.dataset["noteid"];
            this.sendDeleteNoteRequest(noteId);
        }
    }

    sendDeleteNoteRequest(noteId: string) {
        let xhttp : XMLHttpRequest = new XMLHttpRequest();
        xhttp.open("DELETE", `/api/organizer/note/${noteId}`);
        xhttp.addEventListener("load", () => this.handleDeleteNoteLoad(xhttp, noteId));
        xhttp.send();
    }

    handleDeleteNoteLoad(request: XMLHttpRequest, noteId: string) {
        if(request.status === 200) {
            let noteHtml = document.getElementById(noteId);
            if(noteHtml)
            {
                noteHtml.remove();
                if(this.NoteCount)
                {
                    let noteCount = parseInt(this.NoteCount.innerText);
                    noteCount--;
                    this.NoteCount.innerText = noteCount.toString();
                    if(noteCount === 0) {
                        this.NoNotes = <HTMLElement>document.getElementById("no-notes");
                        this.NoNotes.style.display = "block";
                        this.NoteCount.style.display = "none";
                    }
                }
                this.addDeleteEventListeners();
            }
        }
        else {
            this.handleNoteError(request);
        }
    }

    handleAddNoteLinkClick(noteText: string, ev: Event) {
        ev.preventDefault();
        if(noteText && noteText != "") {
            this.AddNoteValidation.style.display = "none";
            let note: OrganizerNote = new OrganizerNote();
            note.NoteText = noteText;
            note.NoteTitle = "Property Details Note";
            note.ObjectDID = this.PropertyId;
            note.ObjectType = "Property";
            this.sendAddNoteRequest(note);
            this.NoNotes.style.display = "none";
        }
        else {
            this.setErrorMessage("A note is required");
        }
    }

    sendAddNoteRequest(note: OrganizerNote) {
        if (!this.IsLoggedIn) {
            CINC.Core.PromptForSignup('', true);
            return;
        }
        let xhttp : XMLHttpRequest = new XMLHttpRequest();
        xhttp.open("POST", "/api/organizer/note", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.addEventListener("load", () => this.handleAddNoteLoad(xhttp));
        xhttp.send(JSON.stringify(note));
    }

    handleAddNoteLoad(request: XMLHttpRequest) : void {
        if(request.status === 200)
        {
            let response = JSON.parse(request.response);
            let createDT : Date = new Date(response.createDT);
            let createDTHour : number = createDT.getHours();
    
            let createDTAmPm : string = "AM";
            if(createDTHour > 12) {
                createDTHour = createDTHour - 12;
            }
    
            this.PropertyNotesWindow.innerHTML += 
            `
                <div class="note-bubble" id="${response.noteDID}">
                    <div class="note-user-photo">
                        <div class="thumbnail-round small"></div>
                    </div>
                    <div class="note-message">
                        ${response.noteText}
                        <div class="note-timestamp">${createDT.getMonth()}/${createDT.getDate()}/${createDT.getFullYear()} ${createDTHour}:${this.formatTimeNumbers(createDT.getMinutes())}:${this.formatTimeNumbers(createDT.getSeconds())} ${createDTAmPm} </div>
                        <div class="float-right text-small">
                        <a class="delete-note" data-noteid="${response.noteDID}" href="">Delete</a>
                        </div>
                    </div>
                </div>
            `;
            this.NoteText.value = "";
            
            if(this.NoteCount) {
                var noteCount = parseInt(this.NoteCount.innerText);
                noteCount++;
                this.NoteCount.innerText = noteCount.toString();
                this.NoteCount.style.display = "inline";
                
            }
            this.addDeleteEventListeners();
            this.AddNoteValidation.style.display = "none";
        }
        else {
            this.handleNoteError(request);
        }
    }

    handleNoteError(request:XMLHttpRequest) : void {
        let response = JSON.parse(request.response);
        this.setErrorMessage(response.message);
    }

    setErrorMessage(message: string) {
        this.AddNoteValidation.style.display = "block";
        this.AddNoteValidation.innerText = message;
    }

    formatTimeNumbers(time : number) : string {
        if(time <= 9) {
            return "0" + time.toString();
        }
        return time.toString();
    }
}

if (document.getElementById("property-notes-component")) {
    let propertyNotesVM = new PropertyNotesViewModel();
}
