﻿import { DomainEventHandler } from '../events/DomainEventHandler';
import { IGoogleEvent } from '../events/DomainEvent';
import { EventType } from '../events/DomainEvent';
import * as DomainEventRegistry from '../events/DomainEventRegistry';

export class MortgageBannerButtonClickEvent implements IGoogleEvent {

    name: string = "MAB-Click-LFW";

    category: string = "click";
    label: string = "MAB-Click-LFW";
    type: EventType = EventType.Click;
}

@DomainEventRegistry.DomainEventRegistry.register
class MortgageBannerButtonClickEventHanlder extends DomainEventHandler {

    domainEventName: string = "MAB-Click-LFW";

    handleDomainEvent<MortgageBannerButtonClickEvent>(ev: CustomEvent, args: MortgageBannerButtonClickEvent): void {

    }
}