﻿import {IDomainEvent} from './DomainEvent';
import { IGoogleEvent } from './DomainEvent';
import { IMixPanelEvent } from './DomainEvent';
import {EventType} from './DomainEvent';
import { isGoogleEvent } from './DomainEvent';
import { isMixPanelEvent } from './DomainEvent';
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';
import { MixPanelEventLogger } from '../tools/MixPanelEventLogger';

export interface IDomainEventHandler {
    // this MUST match the domain event name it is handling.
    // until the name of the type can be determined at runtime this will be required.
    domainEventName : string;
    
    attachDomainEventListener(): void;
    handleDomainEvent<T extends IDomainEvent>(ev : CustomEvent, args: T): void;
}

export abstract class DomainEventHandler implements IDomainEventHandler {
    abstract domainEventName: string;

    attachDomainEventListener() : void {
        document.addEventListener(this.domainEventName, (ev : CustomEvent) => {
            this.handleDomainEvent(ev, ev.detail);
        });
    }

    protected logMixPanelEvent(label: string): void {
        if (isMixPanelEvent(label)) {
            MixPanelEventLogger.log(label);
        }
    }
    abstract handleDomainEvent<T extends IDomainEvent>(ev: CustomEvent, args: T) : void;
}