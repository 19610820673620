﻿export class SearchLocationTag {
    public Id : string;
    public InputId : string;
    public Type : string;
    public Value : string;
    private _html : string;

    constructor(type: string, value : string, isClosable? : boolean) {
        this.Type = type;
        this.Value = value;
        this._html = `<span class='location-tag'>${this.Value}</span>`;
    }

    GetHTML() : string {
        this._html = `<span class="location-tag search-tag-close" id="${this.Id}" data-inputid="${this.InputId}" >${this.Value}</span>`
        return this._html;
    }
}