﻿import { MortgageInquiryRequest } from '../models/MortgageInquiryRequest';
declare var Modals: any;

export class MortgageRateViewModel {

    pdid = new String;
    submitButton: HTMLButtonElement;
   
    //displays
    requestBox: HTMLElement;
    requestSentError: HTMLElement;
    requestErrorText: HTMLElement;
    requestSentSuccessful: HTMLElement;

    constructor() {
        this.submitButton = <HTMLButtonElement>document.getElementById("mortgage-rate-inquiry-button");
        this.requestBox = document.getElementById("mortgage-rate-inquiry-box");
        this.pdid = this.requestBox.dataset.pdid;
        this.requestSentSuccessful = document.getElementById("mortgage-rate-inquiry-successful-send");
        this.requestSentError = document.getElementById("mortgage-rate-inquiry-error-send");
        this.requestErrorText = document.getElementById("error-message-text");
        //event listeners
        if (this.submitButton)
            this.submitButton.addEventListener('click', (e) => this.handleSubmitClick(e));
    }

    handleSubmitClick(e: Event): void {
        e.preventDefault();
        if (!this.submitButton.disabled) {
            var loading = Modals.Loading("Requesting Info. This may take a minute or two.");
            var loadingModalTimeout = setTimeout(() => {
                clearTimeout(loadingModalTimeout);
                loading.Open();
            }, 1000);
            this.sendRequest();
            clearTimeout(loadingModalTimeout); // clear timeout in case appointment scheduling finishes before modal displays
            loading.Close();

        }

    }

    sendRequest(): void {
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        let url = `/api/mortgage/inquiry`;

        let mortgageInquiryRequest = new MortgageInquiryRequest();
        mortgageInquiryRequest.PDID = this.pdid.toString();

        xhttp.addEventListener("load", () => this.handleRequestLoad(xhttp));

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(mortgageInquiryRequest));
    }

    handleRequestLoad(request: XMLHttpRequest) {
        if (request.status === 400) {
            this.handleRequestError(request);
        }
        else if (request.status === 200) {
            this.submitButton.disabled = true;
            if (this.requestSentError &&
                this.requestSentError.style.visibility === "visible") {
                this.requestSentError.style.visibility = "hidden";
                this.requestSentError.style.display = "none";
            }
            this.requestSentSuccessful.style.visibility = "visible";
            this.requestSentSuccessful.style.height = "auto";
            this.requestBox.style.display = "none";
        }
    }

    handleRequestError(request: XMLHttpRequest) {
        let response = JSON.parse(request.response);
        this.requestSentError.style.visibility = "visible";
        this.requestSentError.style.height = "auto";
        this.requestErrorText.innerText = response.message;
    }
}

if (document.getElementById("mortgage-rate-inquiry-box")) {
    let mortgageRatehandler = new MortgageRateViewModel();
}
