﻿
export class SearchPageBroadcast {
    
    public static displayFiltersEventName = `search-page-broadcast-display-filters`;
    private static displayFilters: CustomEvent = new CustomEvent(SearchPageBroadcast.displayFiltersEventName, {});
    public static DisplayFilters(): void {
        document.dispatchEvent(this.displayFilters);
    }

    public static closeFiltersEventName = `search-page-broadcast-close-filters`;
    private static closeFilters: CustomEvent = new CustomEvent(SearchPageBroadcast.closeFiltersEventName, {});
    public static CloseFilters(): void {
        document.dispatchEvent(this.closeFilters);
    }

    public static resetFiltersEventName = "search-page-broadcast-reset-filters";
    private static resetFilters: CustomEvent = new CustomEvent(SearchPageBroadcast.resetFiltersEventName, {});
    public static ResetFilters(): void {
        document.dispatchEvent(this.resetFilters);
    }

    public static clearPolygonMapEventName = "search-page-broadcast-clear-polygon-map";
    private static clearPolygonMap: CustomEvent = new CustomEvent(SearchPageBroadcast.clearPolygonMapEventName, {});
    public static ClearPolygonMap(): void {
        document.dispatchEvent(this.clearPolygonMap);
    }

    public static updatePolygonMapEventName = "search-page-broadcast-update-polygon-map";
    private static updatePolygonMap: CustomEvent;
    public static UpdatePolygonMap(input: any) {
        this.updatePolygonMap = new CustomEvent(SearchPageBroadcast.updatePolygonMapEventName, { detail: { input }});
        document.dispatchEvent(this.updatePolygonMap);
    }

    public static clearLocationFiltersEventName = "search-page-broadcast-clear-location-filters";
    private static clearLocationFilters: CustomEvent = new CustomEvent(SearchPageBroadcast.clearLocationFiltersEventName, {});
    public static ClearLocationFilters() : void {
        document.dispatchEvent(this.clearLocationFilters);
    }

    public static searchRequestInitiatedEventName = "search-page-broadcast-search-request-initiated";
    private static searchRequestInitiated: CustomEvent = new CustomEvent(SearchPageBroadcast.searchRequestInitiatedEventName, {});
    public static SearchRequestInitiated(): void {
        document.dispatchEvent(this.searchRequestInitiated);
    }

    public static searchRequestLoadedEventName = "search-page-broadcast-search-request-loaded";
    private static searchRequestLoaded: CustomEvent = new CustomEvent(SearchPageBroadcast.searchRequestLoadedEventName, {});
    public static SearchRequestLoaded(): void {
        document.dispatchEvent(this.searchRequestLoaded);
    }

    public static expandMapEventName = "search-page-broadcast-expand-map";
    private static expandMap: CustomEvent = new CustomEvent(SearchPageBroadcast.expandMapEventName, {});
    public static ExpandMap(): void {
        document.dispatchEvent(this.expandMap);
    }

    public static contractMapEventName = "search-page-broadcast-contract-map";
    private static contractMap: CustomEvent = new CustomEvent(SearchPageBroadcast.contractMapEventName, {});
    public static ContractMap(): void {
        document.dispatchEvent(this.contractMap);
    }

    public static searchResultsUpdateEventName = "search-page-broadcast-search-results-update";
    private static searchResultsUpdate: CustomEvent;
    public static SearchResultsUpdate(response: string, resetMap: boolean): void {
        this.searchResultsUpdate = new CustomEvent(SearchPageBroadcast.searchResultsUpdateEventName, { detail: { response: response, resetmap: resetMap } });
        document.dispatchEvent(this.searchResultsUpdate);
    }

    public static listingResultsLoadedEventName = "search-page-broadcast-listing-results-loaded";
    private static listingResultsLoaded: CustomEvent = new CustomEvent(SearchPageBroadcast.listingResultsLoadedEventName, {});
    public static ListingResultsLoaded() {
        document.dispatchEvent(this.listingResultsLoaded);
    }

    public static autocompleteLinkClickedEventName = "search-page-broadcast-autocomplete-link-clicked";
    private static autocompletLinkClicked: CustomEvent = new CustomEvent(SearchPageBroadcast.autocompleteLinkClickedEventName, {});
    public static AutoCompleteLinkClicked(): void {
        document.dispatchEvent(this.autocompletLinkClicked);
    }

    public static mapMarkerClickedEventName = "search-page-broadcast-map-marker-clicked";
    private static mapMarkerClicked: CustomEvent = new CustomEvent(SearchPageBroadcast.mapMarkerClickedEventName, {});
    public static MapMarkerClicked(): void {
        document.dispatchEvent(this.mapMarkerClicked);
    }

    public static selectedPropertyClosedEventName = "search-page-broadcast-selected-property-closed";
    private static selectedPropertyClosed: CustomEvent;
    public static SelectedPropertyClosed(pdid: string): void {
        this.selectedPropertyClosed = new CustomEvent(SearchPageBroadcast.selectedPropertyClosedEventName, { detail: { pdid: pdid } });
        document.dispatchEvent(this.selectedPropertyClosed);
    }

    public static favoritedSelectedPropertyEventName = "search-page-broadcast-favorited-selected-property";
    private static favoritedSelectedProperty: CustomEvent;
    public static FavoritedSelectedProperty(pdid: string): void {
        this.favoritedSelectedProperty = new CustomEvent(SearchPageBroadcast.favoritedSelectedPropertyEventName, { detail: { pdid: pdid } });
        document.dispatchEvent(this.favoritedSelectedProperty);
    }

    public static hoveredListingEventName = "search-page-broadcast-hovered-listing";
    private static hoveredListing: CustomEvent;
    public static HoveredListing(pdid: string, isFavorite: boolean): void {
        this.hoveredListing = new CustomEvent(SearchPageBroadcast.hoveredListingEventName, { detail : { pdid: pdid, isfavorite: isFavorite } });
        document.dispatchEvent(this.hoveredListing);
    }

    public static unhoveredListingEventName = "search-page-broadcast-unhovered-listing";
    private static unhoveredListing: CustomEvent;
    public static UnhoveredListing(pdid: string): void {
        this.unhoveredListing = new CustomEvent(SearchPageBroadcast.unhoveredListingEventName, { detail: { pdid: pdid } });
        document.dispatchEvent(this.unhoveredListing);
    }

    public static favoritedListingEventName = "search-page-broadcast-favorited-listing";
    private static favoritedListing: CustomEvent;
    public static FavoritedListing(pdid: string): void {
        this.favoritedListing = new CustomEvent(SearchPageBroadcast.favoritedListingEventName, { detail: { pdid: pdid } });
        document.dispatchEvent(this.favoritedListing);
    }

    public static updateMinPriceEventName = "search-page-broadcast-update-min-price";
    private static updateMinPrice: CustomEvent;
    public static UpdateMinPrice(price: string): void {
        this.updateMinPrice = new CustomEvent(SearchPageBroadcast.updateMinPriceEventName, { detail: { price: price } });
        document.dispatchEvent(this.updateMinPrice);
    }

    public static updateMaxPriceEventName = "search-page-broadcast-update-max-price";
    private static updateMaxPrice: CustomEvent;
    public static UpdateMaxPrice(price: string): void {
        this.updateMaxPrice = new CustomEvent(SearchPageBroadcast.updateMaxPriceEventName, { detail: { price: price } });
        document.dispatchEvent(this.updateMaxPrice);
    }

    public static saveSearchCompletedEventName = "search-page-broadcast-save-search-initiated";
    private static saveSearchCompleted: CustomEvent = new CustomEvent(SearchPageBroadcast.saveSearchCompletedEventName, {});
    public static SaveSearchCompleted() {
        document.dispatchEvent(this.saveSearchCompleted);
    }

    public static displayMobileMapEventName = "search-page-broadcast-display-mobile-map";
    private static displayMobileMap: CustomEvent = new CustomEvent(SearchPageBroadcast.displayMobileMapEventName, {});
    public static DisplayMobileMap() {
        document.dispatchEvent(this.displayMobileMap);
    }

    public static hideMobileMapEventName = "search-page-broadcast-hide-mobile-map";
    private static hideMobileMap: CustomEvent = new CustomEvent(SearchPageBroadcast.hideMobileMapEventName, {});
    public static HideMobileMap() {
        document.dispatchEvent(this.hideMobileMap);
    }

    public static displayMobileListingsEventName = "search-page-broadcast-display-mobile-listings";
    private static displayMobileListings : CustomEvent = new CustomEvent(SearchPageBroadcast.displayMobileListingsEventName, {});
    public static DisplayMobileListings() {
        document.dispatchEvent(this.displayMobileListings);
    }

    public static showSelectedMobileListingsViewEventName = `search-page-broadcast-show-selected-mobile-listings-view`;
    private static showSelectedMobileListingsView: CustomEvent = new CustomEvent(SearchPageBroadcast.showSelectedMobileListingsViewEventName, {});
    public static ShowSelectedMobileListingsView(): void {
        document.dispatchEvent(this.showSelectedMobileListingsView);
    }

    public static closeQuickDetailsViewEventName = "search-page-broadcast-close-quick-details-view";
    private static closeQuickDetailsView: CustomEvent = new CustomEvent(SearchPageBroadcast.closeQuickDetailsViewEventName, {});
    public static CloseQuickDetailsView(): void {
        document.dispatchEvent(this.closeQuickDetailsView);
    }
    
    public static closeFiltersWhenOnMobileEventName = "search-page-broadcast-hide-mobile-map-when-filters-are-open";
    private static closeFiltersWhenOnMobile: CustomEvent = new CustomEvent(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, {});
    public static CloseFiltersWhenOnMobile(): void {
        document.dispatchEvent(this.closeFiltersWhenOnMobile);
    }
}